import React from "react";
import { Config } from "../../../Utils/Config";
import NotFound from "../../../assets/images/Image_not_available.jpg";
import "./index.scss";
import { Link } from "react-router-dom";

function GridViewList({ listItem, key }) {
  return (
    <div className="col-lg-3 col-md-4 mb-4 " key={key}>
      <div className="card p-0 ">
        <div className="card-body">
          <Link
            // className="theme_btn"
            to={`/speaker-public-profile`}
            state={{ speakerId: listItem?.speakerId }}
            style={{ textDecoration: "none" }}
          >
            <div className="grid-view-img-set">
              <img
                src={
                  listItem?.speakerImage
                    ? `${Config.API_HOST_URL_live}${listItem?.speakerImage}`
                    : NotFound
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = NotFound;
                }}
                className="img-fluid"
                alt=""
              />
              {listItem?.ribbon ? (
                <div className="img_text ">
                  <span>{listItem?.ribbon} </span>
                  {/* <span>{listItem?.SpeakerTotalNumberOfVideo} video</span> */}
                </div>
              ) : (
                ""
              )}
            </div>
          </Link>

          <div className="grid-view-infos">
            <Link
              // className="theme_btn"
              to={`/speaker-public-profile`}
              state={{ speakerId: listItem?.speakerId }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h5>{listItem?.speakerName ?? "speaker name"}</h5>

              {listItem?.speechesAndTopics ? (
                <p
                  className="description cr  mb-2"
                  // style={{ color: "black" }}
                >
                  {listItem?.speechesAndTopics?.slice(0, 85)}
                  {listItem?.speechesAndTopics
                    ? listItem?.speechesAndTopics?.length > 85 && (
                        <span style={{ cursor: "pointer" }}>...</span>
                      )
                    : ""}
                </p>
              ) : (
                ""
              )}
            </Link>

            <div className="d-flex align-items-center mb-3">
              {listItem?.Action}
            </div>
          </div>
          {/* View More */}
        </div>
      </div>
    </div>
  );
}

export default React.memo(GridViewList);
