import React, { useEffect, useRef, useState } from "react";
import logo from "../../../../assets/images/fig_events.png";
import "./index.scss";
import jsPDF from "jspdf";
import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../../Action/Action";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  padding: "5px",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f6f6f6",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));
function GroupInvoiceView() {
  const { id, name } = useParams();
  const [open, setOpen] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const [viewData, setViewData] = useState([]);

  let nav = useNavigate();
  const tableRef = useRef(null);

  let Time = new Date();

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  useEffect(() => {
    if (id) {
      getInvoiceView(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const handleDownloadPdf = async () => {
    setOpen(true);

    const element = tableRef.current;
    let newPdfDate =
      convertDate(
        Time.toLocaleString("en-us", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }).replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2")
      ) +
      " " +
      Time.toLocaleTimeString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(async () => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`invoice.pdf`);
        setOpen(false);
        setDownloadpdf(false);
        nav(-1, { replace: true });
        // handleReset();
      });
  };

  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);

  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "200mm"}`,
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "0",
  };

  const getInvoiceView = async (id) => {
    if (id) {
      setOpen(true);
      let sData = {
        url: `/api/MemberManagement/ViewOrResendMemberManagementInvoiceDetails?memberManagementInvoiceId=${id}&isResend=false&isZeroAmount=${
          name === "true" ? true : false
        }`,
        headers: headers,
      };
      const res = await GetCallApi(sData);
      setOpen(false);
      if (res?.status === 200) {
        if (res.data.success) {
          const comList = res.data?.payload;
          let inDate = comList?.invoiceDate
            ? new Date(comList?.invoiceDate)
            : "";
          if (inDate) {
            inDate = inDate.toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
          }
          let newtime = comList?.invoiceDate
            ? new Date(comList?.invoiceDate)
            : "";
          if (newtime) {
            newtime?.setDate(newtime?.getDate() + 30);
            newtime = newtime.toLocaleString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            });
          }
          setViewData({ ...comList, dueDate: newtime, invoiceDate: inDate });
        }
      }
    }
  };

  return (
    <>
      <div className="invoice_view_page">
        <div className="card">
          <div className="card-body">
            <Button
              className="back_button mb-3 me-3"
              onClick={() => nav(-1, { replace: true })}
            >
              Back
            </Button>
            <Button
              className="detail_button mb-3"
              onClick={() => setDownloadpdf(true)}
            >
              Download
            </Button>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <section className="preview-invoice" ref={tableRef} style={back}>
              <div className="invoice-section">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="logo">
                      <img
                        src={logo}
                        style={{
                          height: "60px",
                        }}
                        alt="Confrence Planner"
                        title="Confrence Planner"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="d-flex align-items-center gap-3 justify-content-end">
                      <h2>Invoice</h2>
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-6  mb-4">
                    <h5 className="text-uppercase">
                      {viewData?.conferenceName}
                    </h5>
                    <div className="Billto-wrap">
                      <p style={{ whiteSpace: "pre-line" }}>
                        {viewData?.paymentAddress}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-6 text-end">
                    <div className="invoice-details">
                      <ul>
                        <li>
                          <span className="primarylight-color">PO Number</span>
                          <span className="invoice-text">
                            {viewData?.purchaseOrderNumber}
                          </span>
                        </li>

                        <li>
                          <span className="primarylight-color">
                            Invoice Date
                          </span>
                          <span className="invoice-text">
                            {viewData?.invoiceDate}
                          </span>
                        </li>
                        <li>
                          <span className="primarylight-color">Due Date</span>
                          <span className="invoice-text">
                            {viewData?.dueDate}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6  mb-4">
                    <h5 className="text-uppercase">Billed To</h5>
                    <div className="Billto-wrap">
                      {viewData?.userType !== "User" ? (
                        <>
                          <h5 className="text-uppercase">
                            {viewData?.companyName}
                          </h5>
                          <p>{viewData?.companyPhone}</p>
                        </>
                      ) : (
                        ""
                      )}
                      <p>{viewData?.name}</p>
                      <p>{viewData?.addressLine1}</p>
                      <p>
                        {viewData?.city +
                          " " +
                          viewData?.state +
                          " " +
                          viewData?.zipCode}
                      </p>
                      <p>{viewData?.email}</p>
                    </div>
                  </div>
                </div>
                <div className="table-wrap mt-5">
                  <Table className="table-bordered Conference-table">
                    <TableHead className="thead-primary">
                      <StyledTableRow>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          Item
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          Qty
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          Line Total
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell>
                          {viewData?.conferenceName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {viewData?.conferenceQuantity}
                        </StyledTableCell>
                        <StyledTableCell>
                          {parseFloat(
                            viewData?.conferenceMembersPrice
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </StyledTableCell>
                      </StyledTableRow>
                      {viewData?.mmevtList?.length > 0
                        ? viewData?.mmevtList?.map((ev, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell>{ev?.eventName}</StyledTableCell>
                              <StyledTableCell>{ev?.quantity}</StyledTableCell>
                              <StyledTableCell>
                                {parseFloat(ev?.price)?.toLocaleString(
                                  "en-US",
                                  {
                                    style: "currency",
                                    currency: "USD",
                                  }
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : ""}{" "}
                      {viewData?.applayPromoCodeLists?.length > 0
                        ? viewData?.applayPromoCodeLists?.map((ev, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  textAlign: "right",
                                  borderBottom: "1px solid #808080",
                                }}
                                colSpan={2}
                              >
                                {ev?.promoCode}
                              </StyledTableCell>

                              <StyledTableCell
                                style={{
                                  backgroundColor: "white",
                                  borderBottom: "1px solid #808080",
                                }}
                              >
                                -
                                {ev?.discountAmount?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : ""}
                      {viewData?.discountValue ? (
                        <StyledTableRow>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              textAlign: "right",
                              borderBottom: "1px solid #808080",
                            }}
                            colSpan={2}
                          >
                            Total Discount
                          </StyledTableCell>
                          <StyledTableCell
                            style={{
                              backgroundColor: "white",
                              borderBottom: "1px solid #808080",
                            }}
                          >
                            -
                            {viewData?.discountValue?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </StyledTableCell>
                        </StyledTableRow>
                      ) : (
                        ""
                      )}
                      <StyledTableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            textAlign: "right",
                            borderBottom: "1px solid #808080",
                          }}
                          colSpan={2}
                        >
                          <b>Total</b>
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            borderBottom: "1px solid #808080",
                          }}
                        >
                          <b>
                            {parseFloat(viewData?.totalAmount)?.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                              }
                            )}
                          </b>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </div>
                <div className="table-wrap mt-3">
                  <h5>User Details</h5>
                  <Table className="table-bordered Conference-table">
                    <TableHead className="thead-primary">
                      <StyledTableRow>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          #
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          User Name
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          Email
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          Phone
                        </StyledTableCell>
                        <StyledTableCell
                          className="txt_st"
                          style={{
                            borderTop: "1px solid #808080",
                          }}
                        >
                          Price
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {viewData?.mmGroupUserDetailsList?.length > 0
                        ? viewData?.mmGroupUserDetailsList?.map((ev, i) => (
                            <StyledTableRow key={i}>
                              <StyledTableCell>{i + 1}</StyledTableCell>
                              <StyledTableCell>{ev?.userName}</StyledTableCell>
                              <StyledTableCell>{ev?.userEmail}</StyledTableCell>
                              <StyledTableCell>{ev?.userPhone}</StyledTableCell>
                              <StyledTableCell>
                                {parseFloat(
                                  ev?.userPaymentPrice
                                )?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        : ""}
                    </TableBody>
                  </Table>
                </div>

                {/* <div className="row mt-5">
                  <div className="col-lg-12 text-lg-end mt-3">
                    <h5 className="mt-lg-7">Becki Swan</h5>
                    <p>Co-founder, Fig Events</p>
                  </div>
                </div> */}
                <div className="row term-text mt-3">
                  <div className="col-md-12">
                    <h5>Invoice Instructions</h5>
                    <ReactQuill
                      value={viewData?.invoiceInstructions}
                      readOnly={true}
                      theme={"bubble"}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupInvoiceView;
