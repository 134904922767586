import React, { lazy, Suspense, useEffect, useState } from "react";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { SwalMessage, ToastError } from "../../Components/Toast";
import QRCode from "react-qr-code";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsCompanyReg = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "QRIMG",
    label: "QR Code",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "conferenceName",
    label: "Confernce Name",
  },
  {
    id: "userType",
    label: "User Type",
  },
  {
    id: "companyList",
    label: "Company Name",
    widthFix: 600,
  },
];
function ConferenceQRBadge() {
  const [conferenceList, setConferenceList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [userType, setUserType] = useState("User");
  const [confId, setConfId] = useState("");
  const [filterPage, setFilterPage] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectCompany, setSelectCompany] = useState();

  useEffect(() => {
    document.title = "FIG Events | Conference QR Badge Report";
    GetAllEventsList();
    GetCompanyList();
    // eslint-disable-next-line
  }, []);

  const logindetail = useSelector((state) => state.login.LoginDetails);

  const GetAllEventsList = async () => {
    let res = await GetCallApi({
      url: `/api/Conference/GetApproveConferenceList?userId=${logindetail?.userId}`,
    });
    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((x) => {
        return {
          ...x,
          label: x?.conferenceName,
          value: x?.conferenceId,
        };
      });
      setConferenceList(comList);
    }
  };
  const GetCompanyList = async () => {
    setCompanyList([]);
    let seData = {
      url:
        logindetail?.roleId === 1
          ? `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${logindetail?.userId}`
          : `/api/CompanyRegistration/GetCompanyUserAllocationListByUserId?userId=${logindetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          value: c?.companyId,
          label: c?.companyName,
        };
      });
      setCompanyList(comList);
    }
  };
  const GetQRDisplay = ({ qr }) => {
    return (
      <div className="qr_box">
        <QRCode size={50} value={qr} viewBox="0 0 50 50" />
      </div>
    );
  };
  const GetRegistrationList = async (type, conId) => {
    setDataOfTable1([]);

    if (type && conId) {
      setOpenCircle(true);
      let SedData = {
        url: `/api/Reports/QRReport`,
        body: {
          userId: logindetail?.userId,
          conferenceId: conId,
          companyId: selectCompany ? [selectCompany] : [],
          registerType: type,
        },
      };
      const res = await PostCallApi(SedData);
      setOpenCircle(false);
      setFilterPage(!filterPage);
      let conf = conferenceList?.find((x) => x?.value === conId);

      if (res?.status === 200 && res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          let compUser = c?.userCompanyDetails
            ?.map((x) => x?.companyName)
            ?.join(", ");
          let comp = c?.userCompanyDetails
            ?.filter((x) => x?.isCompanyRegisterInBooth === true)
            ?.map((x) => x?.companyName)
            ?.join(", ");
          return {
            ...c,
            id: i + 1,
            QRIMG: c?.userQRCode ? <GetQRDisplay qr={c?.userQRCode} /> : "",
            conferenceName: conf?.label,
            userType: type,
            companyList: type === "User" ? compUser : comp,
          };
        });
        setDataOfTable1(comList);
      } else {
        ToastError(res.data.message);
      }
    } else {
      let ms =
        !type && !conId
          ? "Please Select Conference & User Type"
          : type
          ? conId
            ? ""
            : "Please Select Conference"
          : "Please Select User Type";
      SwalMessage(ms);
    }
  };

  const handleChangeValue = (e, conId) => {
    setUserType(e);
    setConfId(conId);
  };

  const handleChangeCompany = (e) => {
    setSelectCompany(e);
  };

  const handleClearFilter = () => {
    setUserType();
    setConfId();
    setDataOfTable1([]);
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form>
                    <div className="row mb-3" style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>

                          <Select
                            isMulti={false}
                            name="colors"
                            options={conferenceList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conferenceList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) =>
                              handleChangeValue(userType, e?.value)
                            }
                          />
                          {/* <select
                          className="form-select "
                          value={confId}
                          onChange={(e) =>
                            handleChangeValue(userType, e.target.value)
                          }
                          style={{ minWidth: "250px" }}
                        >
                          {conferenceList &&
                            conferenceList?.map((con, i) => (
                              <option value={con?.conferenceId} key={i}>
                                {con?.conferenceName}
                              </option>
                            ))}
                        </select> */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Register Type</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={[
                              { label: "Attendee", value: "User" },
                              { label: "Exhibitor", value: "Exhibitor" },
                            ]}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select User Type"
                            filterOption={customFilter}
                            value={[
                              { label: "Attendee", value: "User" },
                              { label: "Exhibitor", value: "Exhibitor" },
                            ]?.filter((obj) => obj.value === userType)}
                            onChange={(e) =>
                              handleChangeValue(e?.value, confId)
                            }
                          />
                          {/* <select
                          className="form-select"
                          value={userType}
                          onChange={(e) =>
                            handleChangeValue(e.target.value, confId)
                          }
                          style={{ minWidth: "250px" }}
                        >
                          <option value="Exhibitor">Exhibitor</option>
                          <option value="User">User</option>
                        </select> */}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Company</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={companyList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Company"
                            filterOption={customFilter}
                            value={companyList?.filter(
                              (obj) => selectCompany === obj.value
                            )}
                            onChange={(e) => handleChangeCompany(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            onClick={() =>
                              GetRegistrationList(userType, confId)
                            }
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card mb-4">
                <NewLIstTable
                  headCells={headCellsCompanyReg}
                  title="QR Report"
                  ListData={dataOfTable1}
                  fileName={"QR Report"}
                  exportDataList={true}
                  filterPage={filterPage}
                  BadgePrint={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceQRBadge;
