import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { GetCallApi } from "../../../Action/Action.jsx";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
import TableHeader from "../../../TableHeader.json";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
import Select from "react-select";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function MyRegisterList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [name, setName] = useState("Individual");
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetails = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Register History";
    GetUserList("Individual");
    // eslint-disable-next-line
  }, []);

  const GetUserList = async (type) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/UserMst/UserRegisteredHistory?userId=${LogDetails?.userId}&registerType=${type}`,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            userName: c?.userName,
            phoneNumber: c?.phoneNumber,
            email: c?.email,
            phone: c?.phoneNumber,
            conferenceName: c?.conferenceName,
            userType: c?.userType,
            paymentAmount: c?.paymentAmount,
            paymentStatus: c?.paymentStatus,
            date: c?.date
              ? new Date(c.date)?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
          };
        });
        setDataOfTable(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const RegisterTypeOption = [
    { value: "Group", label: "Group" },
    { value: "Individual", label: "Individual" },
  ];

  const handleFilter = (e) => {
    setName(e);
    GetUserList(e);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="admin_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 new_card p-0 mb-4">
                <div className="col-md-3">
                  <div className="form-group ms-4 mt-3">
                    <label className="form-label">Register Type</label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={RegisterTypeOption}
                      isClearable={false}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Payment Status"
                      filterOption={customFilter}
                      value={RegisterTypeOption?.filter(
                        (obj) => obj.value === name
                      )}
                      onChange={(e) => handleFilter(e?.value)}
                    />
                    <select
                      className="form-select d-none"
                      value={name}
                      onChange={(e) => handleFilter(e.target.value)}
                      style={{ minWidth: "250px" }}
                    >
                      <option value="Group">Group</option>
                      <option value="Individual">Individual</option>
                    </select>
                  </div>
                </div>
                <NewLIstTable
                  title="Register History"
                  action={false}
                  headCells={
                    name === "Individual"
                      ? TableHeader?.RegisterHistoryIndividualHeader
                      : TableHeader?.RegisterHistoryGroupHeader
                  }
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default MyRegisterList;
