import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Modal, Button, Box, Tab, CircularProgress } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./index.scss";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import { MdEdit, MdDelete } from "react-icons/md";
// import door from "../../../../assets/icons/door.svg";
import { FaDoorOpen, FaPersonBooth } from "react-icons/fa";
import { GiBrickWall } from "react-icons/gi";
import { toast } from "react-toastify";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import { PostCallApi } from "../../../../Action/Action";
// import DraggableContainer from "./NewDargComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "50%",
  width: "450px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: 0,
};
function DragableBox(props) {
  const { eventId, canEdit, evD } = props;

  const [deltaPositionSave, setDeltaPositionSave] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [derrors, setDerrors] = useState({});
  const [val, setVal] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    setVal(newValue);
  };
  let nav = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }
  const onSubmit = (data) => {
    if (parseInt(data?.noBooth) === 1) {
      const checkData = deltaPositionSave?.filter(
        (x) => x.label.toString() === data?.no?.toString()
      );
      if (checkData.length > 0) {
        setDerrors({ no_err: "This number is already exist." });
      } else {
        setDerrors({ no_err: "" });

        let add = [
          ...deltaPositionSave,
          {
            sequenceNumber: deltaPositionSave[deltaPositionSave.length - 1]
              ?.sequenceNumber
              ? deltaPositionSave[deltaPositionSave.length - 1]?.sequenceNumber
              : 1,
            value: generateId(5),
            x: deltaPositionSave[deltaPositionSave.length - 1]?.x
              ? deltaPositionSave[deltaPositionSave.length - 1]?.x
              : //  +
                //   parseInt(data?.width)
                1,
            y: deltaPositionSave[deltaPositionSave.length - 1]?.y
              ? deltaPositionSave[deltaPositionSave.length - 1]?.y +
                parseInt(data?.height) +
                10
              : 1,
            label: data?.no,
            width: data?.width,
            height: data?.height,
            price: data?.price,
          },
        ];
        setDeltaPositionSave(add);
        handleClose();
      }
    } else {
      let saveData = [...deltaPositionSave];
      let newbt = [...Array(parseInt(data?.noBooth)).keys()]?.map((x, i) => {
        let nob = parseInt(data?.no) + i;
        let vl = deltaPositionSave?.find((b) => parseInt(b?.label) === nob);
        if (vl?.length > 0) {
          return undefined;
        }
        let sv = {
          sequenceNumber: saveData[saveData.length - 1]?.sequenceNumber
            ? saveData[saveData.length - 1]?.sequenceNumber + i + 1
            : i + 1,
          value: generateId(5),

          x: saveData[saveData.length - 1]?.x
            ? saveData[saveData.length - 1]?.x
            : // + parseInt(data?.width)
              1,
          y:
            saveData[saveData.length - 1]?.y >= 0
              ? saveData[saveData.length - 1]?.y + parseInt(data?.height) + 10
              : 1,
          label: nob,
          width: data?.width,
          height: data?.height,
          price: data?.price,
        };
        return saveData.push(sv);
      });
      newbt = newbt?.filter((x) => x !== undefined);
      let add = saveData;
      setDeltaPositionSave(add);
      handleClose();
      //remove warning
      saveData = newbt;
    }
  };

  const handleDrag = (e, data, id) => {
    setDeltaPositionSave((prevItems) =>
      prevItems.map((item) =>
        item.value === id?.value ? { ...item, x: data.x, y: data.y } : item
      )
    );
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDerrors({ no_err: "" });

    reset();
  };
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [dragMulti, setDragMulti] = useState([]);
  const handleOpenEdit = (d) => {
    setOpenEdit(true);
    setEditData(d);
    setValue("no", d?.label);
    setValue("width", d?.width);
    setValue("height", d?.height);
    setValue("price", d?.price);
    setDragMulti(deltaPositionSave?.map((x) => x?.value));
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEditData({});
    setDragMulti([]);
    setDerrors({ no_err: "" });
    setVal("1");
    reset();
  };
  const onEdit = (data) => {
    const checkData = deltaPositionSave?.filter(
      (x) => x.label?.toString() === data?.no?.toString()
    );
    if (editData?.label.toString() === data?.no.toString()) {
      let add = {
        // sequenceNumber: editData?.sequenceNumber,
        // value: editData?.value,
        // x: editData?.x,
        // y: editData?.y,
        ...editData,
        label: data?.no,
        width: data?.width,
        height: data?.height,
        price: data?.price,
      };
      const s = deltaPositionSave?.filter(
        (se) => se?.sequenceNumber !== editData?.sequenceNumber
      );
      let arr = s;

      arr?.splice(editData?.sequenceNumber, 0, add);
      setDeltaPositionSave(arr);

      handleCloseEdit();
    } else {
      if (checkData.length > 0) {
        setDerrors({ no_err: "This number is already exist." });
      } else {
        setDerrors({ no_err: "" });
        let add = {
          // sequenceNumber: editData?.sequenceNumber,
          // value: editData?.value,
          // x: editData?.x,
          // y: editData?.y,
          ...editData,
          label: data?.no,
          width: data?.width,
          height: data?.height,
          price: data?.price,
        };
        const s = deltaPositionSave?.filter(
          (se) => se?.sequenceNumber !== editData?.sequenceNumber
        );
        let arr = s;

        arr?.splice(editData?.sequenceNumber, 0, add);
        setDeltaPositionSave(arr);

        handleCloseEdit();
      }
    }
  };
  const onRemove = () => {
    let checkData = deltaPositionSave.filter(
      (x) => !dragMulti.includes(x.value)
    );
    setDeltaPositionSave(checkData);
    handleCloseEdit();
  };
  const handleChangedragMulti = (e) => {
    setDragMulti(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  let location = useLocation();
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const loginDetail = useSelector((state) => state.login.LoginDetails);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const { id } = useParams();
  useEffect(() => {
    document.title = "FIG Events | Floor Plan";
    if (id) {
      getseatNumberList(id);
    } else if (eventId) {
      getseatNumberList(eventId);
    }
    // eslint-disable-next-line
  }, [id, eventId]);

  const getseatNumberList = (eid) => {
    let url =
      location?.pathname?.split("/")[2] === "view"
        ? `/api/ConferenceBooths/GetConferenecBoothsStatusByConferenceId`
        : `/api/ConferenceBooths/GetConferenceoothsByConferenceId`;
    Axios.get(`${url}?conferenceId=${eid}`, {
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            if (location?.pathname?.split("/")[2] === "view") {
              let pn = res.data.payload?.map((p) => {
                return {
                  ...p,
                  ...p.exhibitor,
                  sequenceNumber: p?.sequenceNumber,
                  value: p?.eventBoothsId,
                  label: p?.seatEventNumber,
                  x: p?.positionX,
                  y: p?.positionY,
                  width: p?.width,
                  height: p?.hight,
                  status: p?.status,
                };
              });
              setDeltaPositionSave(pn);
            } else {
              let pn = res.data.payload?.map((p) => {
                return {
                  ...p,
                  ...p.exhibitor,
                  sequenceNumber: p?.sequenceNumber,
                  value: p?.eventBoothsId,
                  label: p?.seatEventNumber,
                  x: p?.positionX,
                  y: p?.positionY,
                  width: p?.width,
                  height: p?.hight,
                };
              });
              setDeltaPositionSave(pn);
            }
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const onSaveFloor = async () => {
    setOpenCircle(true);
    let seatData = {
      ConferenceId: id ? id : eventId,
      createdBy: loginDetail?.userId,
      seatNumbers: deltaPositionSave?.map((sn) => {
        // let n = typeof sn.value;
        if (
          sn?.value?.length !== "9d005a69-9705-42e3-9465-1f4fc5530049"?.length
        ) {
          return {
            sequenceNumber: sn.sequenceNumber,
            seatEventNumber: sn?.label?.toString(),
            positionX: sn?.x,
            positionY: sn?.y,
            width: parseFloat(sn?.width),
            hight: parseFloat(sn?.height),
            type: sn?.type ? sn?.type : "booth",
            price: evD?.priceType === "SinglePrice" ? 0 : parseFloat(sn?.price),
          };
        } else {
          return {
            eventBoothsId: sn?.value,
            sequenceNumber: sn.sequenceNumber,
            seatEventNumber: sn?.label?.toString(),
            positionX: sn?.x,
            positionY: sn?.y,
            width: parseFloat(sn?.width),
            hight: parseFloat(sn?.height),
            type: sn?.type ? sn?.type : "booth",
            price: evD?.priceType === "SinglePrice" ? 0 : parseFloat(sn?.price),
          };
        }
      }),
    };
    let res = await PostCallApi({
      url: "/api/ConferenceBooths/CreateConferenceBooths",
      body: seatData,
    });

    if (res.status === 200 && res.data.success) {
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      nav("/conference-list", { replace: true });
      getseatNumberList(eventId ? eventId : id);
      setOpenCircle(false);
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setOpenCircle(false);
    }
  };
  const onResize = (event, { size }, drag) => {
    let ad = deltaPositionSave?.map((x) => {
      if (x.sequenceNumber === drag.sequenceNumber) {
        return { ...x, width: size.width, height: size.height };
      }
      return x;
    });
    setDeltaPositionSave(ad);
  };
  const addWall = (val) => {
    let add = [
      ...deltaPositionSave,
      {
        sequenceNumber: deltaPositionSave.length,
        value: generateId(5),
        x:
          // deltaPositionSave[deltaPositionSave.length - 1]?.x
          //   ? deltaPositionSave[deltaPositionSave.length - 1]?.x
          //   :
          0,
        y:
          // deltaPositionSave[deltaPositionSave.length - 1]?.y
          //   ? deltaPositionSave[deltaPositionSave.length - 1]?.y
          // :
          0,
        label: val,
        type: val,
        width: val === "door" ? 100 : 20,
        height: 100,
      },
    ];
    setDeltaPositionSave(add);
  };

  const removeItem = (id) => {
    setDeltaPositionSave((prevItems) =>
      prevItems.filter((item) => item.value !== id)
    );
  };

  return (
    <>
      {/* <DraggableContainer /> */}
      <div className="editdrop w-100">
        {canEdit ? (
          <div className="control_btn">
            <Button
              className="add_btn White_button save"
              disabled={openCircle}
              onClick={onSaveFloor}
            >
              {openCircle ? (
                <CircularProgress
                  color="inherit"
                  sx={{
                    color: "#80c242",
                    marginLeft: "0.5rem",
                    height: "23px !important",
                    width: "23px !important",
                  }}
                />
              ) : (
                "Save"
              )}
            </Button>
            <Button className="add_btn White_button ms-2" onClick={handleOpen}>
              <FaPersonBooth />
            </Button>
            <Button
              className="add_btn White_button ms-2"
              onClick={() => addWall("wall")}
            >
              <GiBrickWall />
            </Button>{" "}
            <Button
              className="add_btn White_button ms-2"
              onClick={() => addWall("door")}
            >
              <FaDoorOpen />
            </Button>{" "}
          </div>
        ) : (
          ""
        )}
        <div
          className="box"
          style={{
            position: "relative",
            width: "100%",
            height: evD?.height ? evD?.height - 290 : "680px",
            // overflow: "hidden",
            overflow: "auto",
          }}
        >
          {/* <div
          className="box"
          style={{
            height: evD?.height ? evD?.height - 290 : "680px",
            // height: "680px",
            width: "100%",
            position: "relative",
            overflow: "auto",
            padding: "0",
          }}
        > */}
          <div
            style={{
              height: "100%",
              width: evD?.width ? evD?.width - 150 : "1770px",
              // width: "1770px",
              padding: "10px",
              zIndex: "999",
              position: "relative",
              // display: "flex",
              // flexWrap: "wrap",
            }}
          >
            {canEdit ? (
              <>
                {deltaPositionSave &&
                  deltaPositionSave?.map((item) => (
                    <Draggable
                      key={item.value}
                      bounds="parent"
                      defaultPosition={{ x: item.x, y: item.y }}
                      onStop={(e, data) => handleDrag(e, data, item)}
                    >
                      {item?.type === "wall" ? (
                        <ResizableBox
                          minConstraints={[50, 50]}
                          maxConstraints={[300, 300]}
                          width={parseInt(item?.width)}
                          height={parseInt(item.height)}
                          style={{
                            position: "absolute",
                            backgroundColor:
                              item?.type === "wall" ? "black" : "",
                          }}
                          onResizeStop={(e, data) => onResize(e, data, item)}
                        >
                          <div>
                            <span style={{ color: "white" }}>W</span>
                            <button
                              className="delete"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={() => removeItem(item.value)}
                            >
                              <MdDelete color="red" fontSize={24} />
                            </button>
                          </div>
                        </ResizableBox>
                      ) : item?.type === "door" ? (
                        <ResizableBox
                          minConstraints={[50, 50]}
                          maxConstraints={[300, 300]}
                          width={parseInt(item?.width)}
                          height={parseInt(item.height)}
                          style={{
                            position: "absolute",
                            backgroundColor:
                              item?.type === "wall" ? "black" : "",
                          }}
                          onResizeStop={(e, data) => onResize(e, data, item)}
                        >
                          <div>
                            <img
                              src={"/assets/icons/door.svg"}
                              className="child"
                              alt=""
                              style={{
                                width: `100%`,
                                height: `100%`,
                              }}
                            />
                            <button
                              className="delete"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={() => removeItem(item.value)}
                            >
                              <MdDelete color="red" fontSize={24} />
                            </button>
                          </div>
                        </ResizableBox>
                      ) : (
                        <ResizableBox
                          width={item.width}
                          height={item.height}
                          minConstraints={[50, 50]}
                          maxConstraints={[300, 300]}
                          resizeHandles={[]}
                          style={{
                            position: "absolute",
                            backgroundColor: "#d3e0ed",

                            padding: "10px",
                          }}
                        >
                          <div>
                            {item.label}
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={() => handleOpenEdit(item)}
                            >
                              <MdEdit color="#80c242" fontSize={24} />
                            </button>
                            <button
                              className="delete"
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={() => removeItem(item.value)}
                            >
                              <MdDelete color="red" fontSize={24} />
                            </button>
                          </div>
                        </ResizableBox>
                      )}
                    </Draggable>
                  ))}
              </>
            ) : (
              <>
                {deltaPositionSave &&
                  deltaPositionSave?.map((drag, k) => (
                    <Draggable
                      key={k}
                      bounds="parent"
                      handle=".child"
                      position={{
                        x: drag.x,
                        y: drag.y,
                      }}
                      onStart={() => true}
                      onDrag={(e, ui) => handleDrag(e, ui, drag)}
                    >
                      <div
                        className=" child box_small_edit"
                        style={{
                          width: `${drag?.width ? drag.width : "28"}px`,
                          height: `${drag?.height ? drag.height : "28"}px`,
                        }}
                      >
                        <div>{drag?.label}</div>
                        {/* <Tooltip comDl={drag} /> */}
                      </div>
                    </Draggable>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_box_add_drag"
        >
          <Box sx={style} className={`main_box `}>
            <div className="col-md-12 title popup mt-0">Add Drag Box</div>
            <form className="form_details" onSubmit={handleSubmit(onSubmit)}>
              <div
                className="row"
                style={{
                  borderBottom: "1px solid #e7e7e7",
                  marginBottom: "20px",
                }}
              >
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      No of Booth&apos;s Want To Create
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter No of Booth's Want To Create"
                      min={1}
                      {...register("noBooth", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid number",
                        },
                      })}
                    />
                    {errors.noBooth && (
                      <span role="alert" className="error_text">
                        {errors.noBooth.message}
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={`${
                    evD?.priceType === "SinglePrice" ? "col-md-12" : "col-md-6"
                  }`}
                >
                  <div className="form-group">
                    <label className="form-label">
                      Booth No
                      <br />
                      (Don&apos;t enter same booth no that will not create multi
                      booth)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Booth No"
                      min={0}
                      maxLength={4}
                      {...register("no", {
                        required: "This field is required",
                        maxLength: {
                          value: 4,
                          message: "You can enter max 4 numbers",
                        },
                      })}
                    />
                    {errors.no && (
                      <span role="alert" className="error_text">
                        {errors.no.message}
                      </span>
                    )}
                    {derrors?.no_err && (
                      <span role="alert" className="error_text">
                        {derrors?.no_err}
                      </span>
                    )}
                  </div>
                </div>
                {evD?.priceType === "SinglePrice" ? (
                  ""
                ) : (
                  <div className="col-md-6 mt-auto">
                    <div className="form-group">
                      <label className="form-label">Price</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter price"
                        {...register("price", {
                          required: "This field is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message: "Enter valid price",
                          },
                        })}
                      />
                      {errors.price && (
                        <span role="alert" className="error_text">
                          {errors.price.message}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Width</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter width"
                      min={30}
                      max={evD?.width ? evD?.width - 170 : 1720}
                      {...register("width", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid width",
                        },
                      })}
                    />
                    {errors.width && (
                      <span role="alert" className="error_text">
                        {errors.width.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Height</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter height"
                      min={30}
                      max={evD?.height ? evD?.height - 290 : 660}
                      {...register("height", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9+-]+$/,
                          message: "Enter valid height",
                        },
                      })}
                    />
                    {errors.height && (
                      <span role="alert" className="error_text">
                        {errors.height.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="d-flex justify-content-end">
                  <Button
                    className="back_button me-3"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <input
                    type="submit"
                    value="Submit"
                    className="detail_button"
                  />
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      )}
      {openEdit && (
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="popup_box_add_drag"
        >
          <Box sx={style} className={`main_box `}>
            <TabContext value={val}>
              <Box className="tab_group mb-3">
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                  className="tab_label"
                >
                  <Tab label="Edit" value="1" className="tab_1" />
                  <Tab label="Remove" value="2" className="tab_3" />
                </TabList>
              </Box>
              <TabPanel value="1" className="tab_panel" style={{ padding: 0 }}>
                {/* <div className="title">Edit Drag Box</div> */}
                <form className="form_details" onSubmit={handleSubmit(onEdit)}>
                  <div className="row">
                    <div
                      className={`${
                        evD?.priceType === "SinglePrice"
                          ? "col-md-12"
                          : "col-md-6"
                      }`}
                    >
                      <div className="form-group">
                        <label className="form-label">Booth No</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter Booth no"
                          min={0}
                          maxLength={4}
                          {...register("no", {
                            required: "This field is required",
                            maxLength: {
                              value: 4,
                              message: "You can enter max 4 numbers",
                            },
                            valueAsNumber: true,
                          })}
                        />
                        {errors.no && (
                          <span role="alert" className="error_text">
                            {errors.no.message}
                          </span>
                        )}
                        {derrors?.no_err && (
                          <span role="alert" className="error_text">
                            {derrors?.no_err}
                          </span>
                        )}
                      </div>
                    </div>

                    {evD?.priceType === "SinglePrice" ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Price</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter price"
                            {...register("price", {
                              required: "This field is required",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message: "Enter valid price",
                              },
                            })}
                          />
                          {errors.price && (
                            <span role="alert" className="error_text">
                              {errors.price.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Width</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter width"
                          min={30}
                          max={evD?.width ? evD?.width - 170 : 1720}
                          {...register("width", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9+-]+$/,
                              message: "Enter valid Width",
                            },
                          })}
                        />
                        {errors.width && (
                          <span role="alert" className="error_text">
                            {errors.width.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">Height</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter height"
                          min={30}
                          max={evD?.height ? evD?.height - 290 : 660}
                          {...register("height", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9+-]+$/,
                              message: "Enter valid height",
                            },
                          })}
                        />
                        {errors.height && (
                          <span role="alert" className="error_text">
                            {errors.height.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-flex">
                      <input
                        type="submit"
                        value="Submit"
                        className="detail_button mx-auto"
                      />
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel value="2" className="tab_panel" style={{ padding: 0 }}>
                <form className="form_details">
                  <div className="form-group">
                    <label className="form-label">No</label>
                    <Select
                      isMulti
                      name="dragval"
                      options={deltaPositionSave
                        ?.filter((x) => x?.type !== "wall")
                        .filter((x) => x?.type !== "door")}
                      className="drag-value-multi-select"
                      classNamePrefix="select"
                      filterOption={customFilter}
                      value={deltaPositionSave?.filter((obj) =>
                        dragMulti?.includes(obj.value)
                      )}
                      // value={othSchSelect ? othSchSelect : null}
                      onChange={handleChangedragMulti}
                      formatOptionLabel={(drag) => (
                        <div className="country-option">
                          <span>{drag.label}</span>
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex">
                      <input
                        type="button"
                        onClick={onRemove}
                        value="Submit"
                        className="detail_button mx-auto"
                      />
                    </div>
                  </div>
                </form>
              </TabPanel>
            </TabContext>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default DragableBox;

//example for drag

// <Draggable
//   bounds="parent"
//   // defaultPosition={{ x: 0, y: 0 }}
//   // onStart={() => onStart()}
//   // onStop={(e, ui) => onStop(e, ui, 0)}
//   handle=".handle"
//   position={{ x: deltaPositionSave[0].x, y: deltaPositionSave[0].y }}
//   onDrag={(e, ui) => handleDrag(e, ui, 0)}
// >
//   <div className=" handle box_small">
//     112
//     {/* <div className="d-none">
//               x: {deltaPositionSave[0].x.toFixed(0)}, y:{" "}
//               {deltaPositionSave[0].y.toFixed(0)}
//             </div> */}
//   </div>
// </Draggable>;
