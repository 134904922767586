import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  subTotal: 0,
  total: 0,
};

export const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const isItemExist = state?.cartItems?.find(
        (i) => i.associationProductId === item.associationProductId
      );
      if (isItemExist) {
        state.cartItems.forEach((i) => {
          if (i.associationProductId === item.associationProductId) {
            if (item?.isMulltiQuantity) {
              let qnt = item?.quentity ? item?.quentity : 1;
              (i.finalAmount = i.mainPrice * (i.quentity + qnt)),
                (i.quentity += qnt),
                (i.boothWiseCompanyProductQTT =
                  item.boothWiseCompanyProductQTT);
            } else {
              (i.finalAmount = i.mainPrice * (i.quentity + 1)),
                (i.quentity += 1);
            }
          }
        });
      } else {
        let prd = { ...item, finalAmount: item?.mainPrice * item?.quentity };
        // item.finalAmount = item?.mainPrice * item?.quentity;
        state.cartItems.push(prd);
      }
    },
    addAllCartData: (state, action) => {
      const item = action.payload;
      state.cartItems = item;
    },

    decrementProduct: (state, action) => {
      const item = state.cartItems.find(
        (i) => i.associationProductId === action.payload
      );
      if (item.quentity > 1) {
        state.cartItems.forEach((i) => {
          if (i.associationProductId === item.associationProductId)
            (i.finalAmount = i.mainPrice * (i.quentity - 1)), (i.quentity -= 1);
        });
      }
    },

    deleteFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (i) => i.associationProductId !== action.payload
      );
    },

    calculatePrice: (state) => {
      let sum = 0;
      state.cartItems.forEach((i) => (sum += i.finalAmount));
      state.subTotal = sum;
      state.total = state.subTotal;
    },
    resetCart: () => initialState,
  },
});

export const {
  addToCart,
  addAllCartData,
  decrementProduct,
  deleteFromCart,
  calculatePrice,
  resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
