import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./useCountdown";
import { Button } from "@mui/material";

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <Button className="event-btn">
        <span className="text">Conference Started</span>
      </Button>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show_counter">
      <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />

      <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />

      <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />

      <DateTimeDisplay value={seconds} type={window.innerWidth < 768 ? "Sec.." : "Seconds"} isDanger={false} />
    </div>
  );
};

const CountdownTimer = ({ targetDate, expire }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (!expire) {
    return (
      <div className="expired-notice">
        <Button className="event-btn">
          <span className="text">Conference Completed</span>
        </Button>
      </div>
    );
  } else if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
