import React, { Suspense, lazy, useEffect, useState } from "react";
import "./index.scss";
// import Axios from "../../../Utils/AxiosConfig.jsx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
// import { VscEdit } from "react-icons/vsc";
import NewLIstTable from "../../Components/NewLIstTable/index.jsx";
import { GetCallApi } from "../../../Action/Action.jsx";
import ImgPopUp from "../../Components/ImgPopUp/index.jsx";
import { Config } from "../../../Utils/Config.jsx";
import { VscEdit } from "react-icons/vsc";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
import { SwalMessage } from "../../Components/Toast";

const DeletePopup = lazy(() => import("../../Components/DeletePopup"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsVendor = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "image",
    label: "Profile Image",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "companyName",
    label: "Company Name",
  },
];
function SalesRepresentative() {
  const [venderList, setVenderList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState();

  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Sales Representative";
    GetCompanyRegistrationList();
    // eslint-disable-next-line
  }, []);

  const { handleSubmit, reset, setValue } = useForm();
  const GetCompanyRegistrationList = async () => {
    let seData = {
      url:
        LogDetail?.roleId === 1
          ? `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${LogDetail?.userId}`
          : `/api/CompanyRegistration/GetCompanyUserAllocationListByUserId?userId=${LogDetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          value: c?.companyId,
          label: c?.companyName,
        };
      });
      setCompanyList(comList);
    }
  };

  const GeVendorList = async (data) => {
    if (data?.CompanyId) {
      let seData = {
        url: data?.CompanyId
          ? `/api/ExhibitorVendor/GetAllExhibitorVendorList?userId=${LogDetail?.userId}&companyId=${data?.CompanyId}`
          : `/api/ExhibitorVendor/GetAllExhibitorVendorList?userId=${LogDetail?.userId}`,
      };
      setOpenCircle(true);
      let res = await GetCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200 && res.data.success) {
        let sv = res.data.payload?.map((x, i) => {
          return {
            id: i + 1,
            ...x,
            ...x?.ev,
            image: x?.ev?.image ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${x?.ev.image}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            Action: (
              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to={`/vendor-edit/${x?.ev?.salesRepresentativesId}`}
                  className="btn_edit me-2 d-flex align-items-center"
                >
                  <VscEdit />
                </Link>
                <DeletePopup
                  title={"Delete Team Member"}
                  text={x?.ev?.firstName + " " + x?.ev?.lastName}
                  url={`/api/ExhibitorVendor/DeleteExhibitorVendor?exhibitorVendorId=${x?.ev?.salesRepresentativesId}`}
                  callBack={true}
                  callBackFunction={() => GeVendorList(data)}
                />
              </div>
            ),
          };
        });
        setVenderList(sv);
      }
    } else {
      SwalMessage("Please select company");
    }
  };

  const handleCompany = (e) => {
    setValue("CompanyId", e);
    setCompanyId(e);
  };

  const handleClearFilter = () => {
    setCompanyId();
    setVenderList([]);
    reset();
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="admin_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GeVendorList)}>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Company</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={companyList}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Company"
                            filterOption={customFilter}
                            value={companyList?.filter(
                              (obj) => companyId === obj.value
                            )}
                            onChange={(e) => handleCompany(e?.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card mb-4">
                <NewLIstTable
                  addressLink={`/vendor-create`}
                  exportBtn={false}
                  title="Team Members List"
                  headCells={headCellsVendor}
                  action={true}
                  ListData={venderList}
                  addBtn={true}
                />
                {/* <div className="card">
                <div className="card-body">
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SalesRepresentative;
