import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import "./index.scss";
import {
  Accordion,
  AccordionSummary,
  Button,
  Tooltip,
  Typography,
  AccordionDetails,
  Backdrop,
  CircularProgress,
  styled,
  Paper,
  TableSortLabel,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  tableCellClasses,
  Box,
} from "@mui/material";
import NewPaginations from "../../../Components/NewPaginations/Pagination";
import { IoMdRefreshCircle } from "react-icons/io";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
// import filterIcon from "../../../../assets/icons/filterIcon.svg";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import Swal from "sweetalert2";
import MultiImgPopUp from "./ImgPopup/index";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";
import {
  SwalMessage,
  ToastError,
  ToastSuccess,
} from "../../../Components/Toast";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  ".Mui-active": {
    color: "#fff !important",
    ".MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function OneWorldSyncTable(prop) {
  const {
    headCells,
    title,
    action,
    actionFirst,
    as,
    apiCall,
    ApiCallFuntion,
    // DeleteStudents,
  } = prop;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(as ? as : "EventName");

  let tableRef = useRef();

  const [ListData, setSyncList] = useState([]);
  const [syncCheck, setSyncCheck] = useState([]);
  const [companyList, setDataOfTable1] = useState([]);
  // const [data, setData] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [open, setOpen] = useState(false);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logDetail = useSelector((state) => state.login.LoginDetails);

  const { register, handleSubmit, setValue, getValues } = useForm();

  const showReadMore = (t) => {
    Swal.fire({
      position: "center",
      width: window.innerWidth > 768 ? "50%" : "90%",
      title: "Ingredients",
      text: t,
      confirmButtonColor: "#fff",
      // confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    GetCompanyRegistrationList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (syncCheck?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [syncCheck]);

  const apply = (d) => {
    getAllData(d);
  };

  const clear = (clear) => {
    if (clear) {
      setSyncCheck([]);
      setValue("companyId", "");
      setValue("productName", "");
      setValue("gtinNumber", "");
      setValue("brandName", "");
      setValue("categoryName", "");
      setValue("subCategoryName", "");
      setValue("manufacturer", "");
      setSyncList([]);
    }
  };

  const GetCompanyRegistrationList = async () => {
    setOpen(true);
    let seData = {
      url: `/api/CompanyRegistration/GetAllCompanyRegistration?userId=${logDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpen(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload
          ?.filter((c) => c?.glnNumber !== null)
          ?.map((c) => {
            return {
              ...c,
              value: c?.companyId,
              label: c?.companyName,
            };
          });
        setDataOfTable1(comList);
        // handleCmp(comList[0]?.companyId);
        // setCmId(comList[0]?.companyId);
      }
    }
  };

  const handleSelectSync = (val, userId) => {
    if (val) {
      setSyncCheck((old) => [...old, userId]);
    } else {
      setSyncCheck((old) => old?.filter((data) => data !== userId));
    }
  };

  const selectingAll = (val, list) => {
    if (val) {
      setSyncCheck(list?.map((x) => x?.productId));
    } else {
      setSyncCheck([]);
    }
  };

  const changetbData = () => {
    let newtb = ListData?.map((pd) => {
      return {
        ...pd,
        Action: (
          <label className=" personal_detail_label">
            <input
              type="checkbox"
              className="login_check"
              name="Remember Me"
              checked={
                syncCheck?.filter((x) => x === pd?.productId)?.length > 0
                  ? true
                  : false
              }
              onChange={(e) =>
                handleSelectSync(e?.target?.checked, pd?.productId)
              }
            />
            <span className="checkmark"></span>
          </label>
        ),
      };
    });
    setSyncList(newtb);
  };

  const refreshList = (cp, ps) => {
    const sddata = {
      companyId: getValues("companyId"),
      productName: getValues("productName"),
      gtinNumber: getValues("gtinNumber"),
      brandName: getValues("brandName"),
      categoryName: getValues("categoryName"),
      subCategoryName: getValues("subCategoryName"),
      manufacturer: getValues("manufacturer"),
      pageNumber: cp,
      pageSize: ps,
    };
    getAllData(sddata);
    setCurrentPage(cp);
    setPageSize(ps);
  };

  const getAllData = async (data) => {
    setSyncList([]);
    setOpen(true);

    let sedData = {
      pageNumber: data?.pageNumber ? data?.pageNumber : 1,
      pageSize: data?.pageSize ? data?.pageSize : 10,
      gtinNumber: data?.gtinNumber ? data?.gtinNumber : "",
      companyId: data?.companyId ? data?.companyId : null,
      brandName: data?.brandName ? data?.brandName : "",
      categoryName: data?.categoryName ? data?.categoryName : "",
      manufacturer: data?.manufacturer ? data?.manufacturer : "",
      productName: data?.productName ? data?.productName : "",
      subCategoryName: data?.subCategoryName ? data?.subCategoryName : "",
    };

    let seData = {
      url: `/api/GlobalProduct/GetGlobalProductByGTINNo`,
      body: sedData,
      headers: headers,
    };

    const res = await PostCallApi(seData);
    setOpen(false);
    if (res?.status === 200) {
      if (res?.data?.data?.success) {
        let getAllData = res.data.data?.payload?.map((c, i) => {
          let pn = sedData?.pageNumber - 1;
          return {
            ...c,
            id: pn * sedData?.pageSize + i + 1,
            image: (
              <MultiImgPopUp
                img={c?.image}
                imgAr={c?.image ? c?.image?.split(";") : ""}
              />
            ),
            Action: (
              <>
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleSelectSync(e?.target?.checked, c?.productId)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </>
            ),
            ingredients: (
              <div>
                {c?.ingredients?.length > 40 ? (
                  <span
                    className="ingredient"
                    onClick={() => showReadMore(c?.ingredients)}
                  >
                    {c?.ingredients?.slice(0, 50)}...
                  </span>
                ) : (
                  <span className="ingredient">{c?.ingredients}</span>
                )}
              </div>
            ),
          };
        });
        setTotalRecord(
          res?.data?.data?.totalRecord ? res?.data?.data?.totalRecord : 0
        );
        setSyncList(getAllData);
        setSyncCheck([]);
      }
    }
  };

  const AddStudents = async () => {
    if (getValues("companyId")) {
      setOpen(true);
      let addData = syncCheck ? syncCheck : 0;

      const passData = {
        url: `/api/GlobalProduct/ApproveOneWorldSyncProduct?userId=${
          logDetail?.userId
        }&companyId=${
          getValues("companyId")
            ? getValues("companyId")
            : "00000000-0000-0000-0000-000000000000"
        }`,
        body: addData,
        headers: headers,
      };

      let respo = await PostCallApi(passData);
      setOpen(false);
      if (respo?.status === 200 && respo?.data?.success) {
        if (apiCall) {
          ApiCallFuntion();
        }
        const sdData = {
          page: 1,
          pageSize: 10,
        };
        getAllData(sdData);
        setSyncCheck([]);
        ToastSuccess(respo?.data?.message);
      } else {
        ToastError(respo?.data?.message);
      }
    } else {
      SwalMessage("Please select company ");
    }
  };

  const getApiData = (e) => {
    const sddata = {
      companyId: getValues("companyId"),
      productName: getValues("productName"),
      gtinNumber: getValues("gtinNumber"),
      brandName: getValues("brandName"),
      categoryName: getValues("categoryName"),
      subCategoryName: getValues("subCategoryName"),
      manufacturer: getValues("manufacturer"),
      pageNumber: e.page,
      pageSize: e.no,
    };
    apply(sddata);
  };

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "no" || orderBy === "id") {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    } else if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {actionFirst ? (
            <StyledTableCell align="center">
              <TableSortLabel>Actions</TableSortLabel>
            </StyledTableCell>
          ) : (
            ""
          )}
          {headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
          {actionFirst ? (
            ""
          ) : (
            <>
              {action ? (
                <StyledTableCell align="center">
                  <TableSortLabel>Actions</TableSortLabel>
                </StyledTableCell>
              ) : (
                ""
              )}
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // const [searchField, setSearchField] = useState("");
  // const formattedSearchResults = ListData?.filter((user) => {
  //     if (searchField) {
  //         let state = false;
  //         headCells?.forEach((property) => {
  //             let value = String(user[property?.id]);
  //             if (typeof value === "string") {
  //                 if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
  //                     state = true;
  //                 }
  //             }
  //         });
  //         return state; ListData
  //     } else return user;
  // });

  let filteredCmp = companyList?.filter((c) => {
    return c?.glnNumber !== null;
  });

  const handlePageChange = (page, no) => {
    let pno = { page: page, no: no };
    getApiData(pno);
    setCurrentPage(page);
  };
  const handleManage = (data) => {
    setCurrentPage(1);
    setPageSize(10);
    apply(data);
  };
  const [companyId, setCompanyId] = useState();

  const handleChangeCompany = (val) => {
    setValue("companyId", val);
    setCompanyId(val);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "transparent",
        }}
        open={open}
      >
        <CircularProgress sx={{ color: "#80c242" }} />
      </Backdrop>
      <div className="col-md-12 oneword_sync">
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography style={{ marginLeft: "20px" }}>
              <img src={"assets/icons/filterIcon.svg"} alt="filter" />
              <span className="filter_by">Filter By</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Tooltip title="Refresh" placement="top" arrow>
              <Button
                className="ms-2 detail_button"
                onClick={() => {
                  refreshList(1, 10);
                }}
                style={{ textDecoration: "none", height: "45px" }}
              >
                <IoMdRefreshCircle fontSize={30} />
              </Button>
            </Tooltip>

            {ListData?.length > 0 ? (
              <>
                <Button
                  className="ms-2 detail_button"
                  onClick={() => selectingAll(true, ListData)}
                >
                  Select All
                </Button>
                <Button
                  className="ms-2 back_button"
                  onClick={() => selectingAll(false, ListData)}
                >
                  Deselect All
                </Button>
                {syncCheck?.length > 0 ? (
                  <>
                    <Button
                      className="ms-2 detail_button"
                      onClick={() => {
                        AddStudents();
                        setCurrentPage(1);
                      }}
                    >
                      Approve
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <form onSubmit={handleSubmit(handleManage)}>
              <div className="row mt-3">
                <div className="col-md-3">
                  <div className="form-group">
                    <Select
                      isMulti={false}
                      name="colors"
                      options={filteredCmp}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Company"
                      filterOption={customFilter}
                      value={filteredCmp?.filter(
                        (obj) => obj.value === companyId
                      )}
                      onChange={(e) => handleChangeCompany(e?.value)}
                    />
                    <select
                      className="form-select d-none"
                      {...register("companyId")}
                    >
                      <option value="">Select Company</option>
                      {filteredCmp?.length > 0
                        ? filteredCmp?.map((v, i) => {
                            return (
                              <option value={v?.companyId} key={i}>
                                {v?.companyName}
                              </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      {...register("productName")}
                      type="text"
                      placeholder="Search By Product Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      {...register("gtinNumber")}
                      type="number"
                      placeholder="Search By Gtin"
                      className="form-control"
                      min={0}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      {...register("brandName")}
                      type="text"
                      placeholder="Search Brand Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      {...register("categoryName")}
                      type="text"
                      placeholder="Search By Category Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      {...register("subCategoryName")}
                      type="text"
                      placeholder="Search By Sub Category Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      {...register("manufacturer")}
                      type="text"
                      placeholder="Search By Manufacturer"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-3 text-end">
                  <Button className="detail_button" type="submit">
                    Apply
                  </Button>
                  <Button
                    className="ms-2 back_button"
                    onClick={() => {
                      clear(true);
                      setCurrentPage(1);
                    }}
                  >
                    Clear Filter
                  </Button>
                </div>
              </div>
            </form>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="justify-content-between new_listdata_control_sec">
        <h3 className="card_title m-0">{title}</h3>
        {/* <div className="search_input w-100">
                        <img src={search_icon} alt="" />
                        <input
                            onChange={(e) => {
                                setSearchField(e.target.value);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Type your search here"
                        />
                    </div> */}
      </div>
      {/* <Button
                className="ms-2 detail_button"
                onClick={() => prop?.ReactiveProducts()}
            >
                Reactive Products
            </Button> */}
      <div className="new_all_list_one_world_sync">
        <Paper className="table-box">
          <TableContainer className="" ref={tableRef}>
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              {/* ?.slice(
                                        (currentPage - 1) * pageSize,
                                        (currentPage - 1) * pageSize + pageSize
                                    ) */}
              <TableBody>
                {stableSort(ListData, getComparator(order, orderBy))?.map(
                  (row, index) => {
                    return (
                      <StyledTableRow className="table_body" key={index + 1}>
                        {actionFirst ? (
                          <StyledTableCell
                            className={`column_data`}
                            key={index + 1}
                          >
                            <div className="edit_delete justify-content-center">
                              {row?.Action}
                            </div>
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                        {headCells?.map((column) => {
                          const value = row[column?.id];
                          if (column?.color) {
                            return (
                              <StyledTableCell
                                className="column_data"
                                key={column?.id}
                              >
                                <span className={`status_tag ${row.color}`}>
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </span>
                              </StyledTableCell>
                            );
                          }
                          return (
                            <StyledTableCell
                              className="column_data"
                              key={column?.id}
                            >
                              {column?.format && typeof value === "number"
                                ? column?.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                        {actionFirst ? (
                          ""
                        ) : (
                          <>
                            {action ? (
                              <StyledTableCell
                                className="column_data"
                                key={index + 1}
                              >
                                <div className="edit_delete">{row?.Action}</div>
                              </StyledTableCell>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </StyledTableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {ListData?.length === 0 ? (
            <div className="er_nodata">No Data Found</div>
          ) : (
            <div className="page_nation">
              <div className="rowsPerPageOptions">
                <span className="p_18_text">Items per page:</span>
                <select
                  className="pagination_select p_16_text"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value));
                    handlePageChange(1, parseInt(e.target.value));
                    setCurrentPage(1);
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                </select>
              </div>
              <NewPaginations
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalRecord}
                pageSize={pageSize}
                onPageChange={(page) => {
                  handlePageChange(page, pageSize);
                }}
              />
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}
export default React.memo(OneWorldSyncTable);
