import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../Utils/AxiosConfig";
import "./index.scss";
import {
  Button,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import CropModel from "../../../Components/CropModel";
import { VscTrash } from "react-icons/vsc";
import Select from "react-select";
import { customFilter } from "../../../Components/CustomFilterForReactSelect";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function SessionCreate() {
  const { id } = useParams();
  const [derror, setDerror] = useState();
  const [conferenceList, setConferenceList] = useState([]);
  // const [eventList, setEventList] = useState([]);
  // const [hhList, setHHList] = useState([]);
  // const [mmList, setMMList] = useState([]);
  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [matD, setMatD] = React.useState();
  const [dataOfSpeaker, setDataOfSpeaker] = useState([]);
  const [speakerSelect, setSpeakerSelect] = useState([]);

  // const [derrors, setDerrors] = useState({});
  const [courseCode, setCourseCode] = useState([]);
  const [courseCodeList, setCourseCodeList] = useState([]);
  const [courseLear, setCourseLear] = useState([]);
  const [courseLearList, setCourseLearList] = useState([]);
  const [courseTrai, setCourseTrai] = useState([]);
  const [courseTraiList, setCourseTraiList] = useState([]);
  const [confId, setConfId] = useState();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [displayDoc, setDisplayDoc] = useState([]);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let minDate = new Date()
    .toLocaleString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");

  useEffect(() => {
    if (courseTraiList?.length > 0) {
      if (courseLearList?.length > 0) {
        if (courseCodeList?.length > 0) {
          if (conferenceList?.length > 0) {
            if (dataOfSpeaker?.length > 0) {
              if (id) {
                getClassEditDetail(id);
              } else {
                setValue("Ceus", "0");
                setValue("ClassesId", "00000000-0000-0000-0000-000000000000");
              }
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    id,
    conferenceList,
    dataOfSpeaker,
    courseTraiList,
    courseLearList,
    courseCodeList,
  ]);
  const getClassEditDetail = (id) => {
    Axios.get(`/api/Classes/GetClassesDataByClassesId?classesId=${id}`, {
      headers,
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let useDe = res.data.payload;
            setValue("ClassesId", useDe?.classesId);
            setValue("Title", useDe?.title);
            setValue("ConferenceId", useDe?.conferenceId);
            setConfId(useDe?.conferenceId);
            // setValue("SpeakerId", useDe?.speakerId);
            // setValue("EventId", useDe?.eventId);
            setValue("Cost", useDe?.cost);
            setValue("Ceus", useDe?.ceus);
            setValue("CoverImage", useDe?.coverImage);
            setValue("CoverImage1", useDe?.coverImage);
            setValue("TargetAudienceCode", useDe?.targetAudienceCode);
            // setValue("Keyarea", useDe?.keyarea);
            // setValue("Learningobjective", useDe?.learningobjective);
            // setValue("TrainingTopic", useDe?.trainingTopic);
            setValue("MeetingRoomNumber", useDe?.meetingRoomNumber);
            setValue(
              "startDate",
              useDe?.classesStartdate
                ? useDe?.classesStartdate?.split("T")[0]
                : ""
            );
            setDisplayDoc(useDe?.classesDocument);
            setValue("Descriptions", useDe?.descriptions);
            // setValue(`DocumentPathf`, useDe?.classesDocument);
            setValue(
              `SessionDoc1`,
              useDe?.classesDocument?.map((x) => x.fileName)
            );
            setValue(`startTime`, useDe?.startTime);
            setValue(`endTime`, useDe?.endTime);
            // setValue("sHH", useDe?.startTime?.split(":")[0]);
            // setValue("sMM", useDe?.startTime?.split(":")[1]);
            // setValue("eHH", useDe?.endTime?.split(":")[0]);
            // setValue("eMM", useDe?.endTime?.split(":")[1]);
            if (useDe?.mainCategory?.length > 0) {
              setCourseCode(useDe?.mainCategory);
            }
            if (useDe?.learningObjective?.length > 0) {
              setCourseLear(useDe?.learningObjective);
            }
            if (useDe?.trainingTopic?.length > 0) {
              setCourseTrai(useDe?.trainingTopic);
            }
            if (useDe?.speakerId?.length > 0) {
              setSpeakerSelect(useDe?.speakerId);
            }
          }
        }
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    // function createOption(value, text) {
    //   var option = {};
    //   option.text = text < 10 ? "0" + text.toString() : text.toString();
    //   option.value = value;
    //   return option;
    // }

    // var hourSelect = [];
    // for (var i = 0; i <= 23; i++) {
    //   hourSelect.push(createOption(i, i));
    // }

    // var minutesSelect = [];
    // for (var j = 0; j < 60; j += 15) {
    //   minutesSelect.push(createOption(j, j));
    // }
    // setHHList(hourSelect);
    // setMMList(minutesSelect);
    GetConferenceList();
    // GetEventList();
    GetSpeakerList();
    GetCategoryList();
    // eslint-disable-next-line
  }, []);
  const onSubmit = (data) => {
    let is_success = true;
    // let stime = data.sHH + ":" + data.sMM;
    // let etime = data.eHH + ":" + data.eMM;
    if (data?.startTime > data?.endTime) {
      is_success = false;
      setDerror({ time_err: "Start time cannot greater than from end time" });
    } else {
      setDerror({ time_err: "" });
    }
    if (speakerSelect?.length === 0) {
      is_success = false;
      setDerror({ speak_err: "Select minimum one speaker " });
    } else {
      setDerror({ speak_err: "" });
    }
    // let sData = {
    //   ...data,
    //   Cost: data?.Cost ? data?.Cost : 0,
    //   CreatedBy: logDetail?.userId,
    //   StartTime: stime,
    //   EndTime: etime,
    // };
    var datestr = new Date(data.startDate).toISOString();
    datestr = datestr?.split(".")[0];
    let Sessiondata = new FormData();
    Sessiondata.append("ClassesId", data?.ClassesId);
    Sessiondata.append("CreatedBy", logDetail?.userId);
    Sessiondata.append("ConferenceId", data?.ConferenceId);
    // Sessiondata.append("EventId", data?.EventId);
    // if (data?.SpeakerId) Sessiondata.append("SpeakerId", data?.SpeakerId);
    Sessiondata.append("Title", data?.Title);
    Sessiondata.append("Cost", data?.Cost ? data?.Cost : 0);
    Sessiondata.append("Ceus", data?.Ceus);
    Sessiondata.append("TargetAudienceCode", data?.TargetAudienceCode);
    // Sessiondata.append("Keyarea", data?.Keyarea);
    // Sessiondata.append("Learningobjective", data?.Learningobjective);
    // Sessiondata.append("TrainingTopic", data?.TrainingTopic);
    Sessiondata.append("MeetingRoomNumber", data?.MeetingRoomNumber);
    Sessiondata.append("Descriptions", data?.Descriptions);
    Sessiondata.append("ClassesStartdate", datestr);
    Sessiondata.append("StartTime", data?.startTime);
    Sessiondata.append("EndTime", data?.endTime);
    Sessiondata.append("CoverImage", data?.CoverImage);
    Sessiondata.append("SpeakerTrainer", data?.SpeakerTrainer);

    // for (var key in sData) {
    //   Sessiondata.append(key, sData[key]);
    // }
    if (data?.DocumentPathf?.length > 0) {
      data?.DocumentPathf?.map((d) => {
        return Sessiondata.append("DocumentPath", d);
      });
    } else {
      Sessiondata.append("DocumentPath", []);
    }
    if (speakerSelect?.length > 0) {
      speakerSelect?.map((d) => {
        return Sessiondata.append("SpeakerIdList", d);
      });
    } else {
      // Sessiondata.append("SpeakerIdList", []);
    }
    if (courseCode?.length > 0) {
      courseCode?.map((d) => {
        return Sessiondata.append("MainCategories", d);
      });
    } else {
      Sessiondata.append("MainCategories", []);
    }
    if (courseLear?.length > 0) {
      courseLear?.map((d) => {
        return Sessiondata.append("LearningObjectives", d);
      });
    } else {
      Sessiondata.append("LearningObjectives", []);
    }
    if (courseTrai?.length > 0) {
      courseTrai?.map((d) => {
        return Sessiondata.append("TrainingTopics", d);
      });
    } else {
      Sessiondata.append("TrainingTopics", []);
    }

    if (is_success) {
      Axios.post("/api/Classes/CreateClasses", Sessiondata, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              toast.success(res.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              reset();
              nav("/sessions", { replace: true });
              setDerror({ err: "" });
            } else {
              setDerror({ err: res.data?.message });
            }
          }
        })
        .catch((e) => console.log(e));
    }
  };
  const GetConferenceList = () => {
    // `/api/Conference/GetAllConferenceList?userId=${LogDetails?.userId}`,
    Axios.get(
      `/api/Conference/GetAllConferenceList?&userId=${logDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let comList = res?.data?.payload
              ?.filter((x) => x?.status === "Approved")
              ?.map((c) => {
                return {
                  cofId: c.conferenceId,
                  title: c?.conferenceName,
                  value: c.conferenceId,
                  label: c?.conferenceName,
                };
              });
            setConferenceList(comList);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const GetCategoryList = () => {
    Axios.get("/api/Classes/GetCourseCategory", { headers })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let keyArea = res?.data?.payload?.keyArea?.map((c) => {
              return {
                value: c.courseCategoryId,
                label: c?.courseCategoryCode + " - " + c?.courseCategoryName,
              };
            });
            setCourseCodeList(keyArea);
            let learningObjective = res?.data?.payload?.learningObjective?.map(
              (c) => {
                return {
                  value: c.courseCategoryId,
                  label: c?.courseCategoryCode + " - " + c?.courseCategoryName,
                };
              }
            );
            setCourseLearList(learningObjective);
            let trainingTopics = res?.data?.payload?.trainingTopics?.map(
              (c) => {
                return {
                  value: c.courseCategoryId,
                  label: c?.courseCategoryCode + " - " + c?.courseCategoryName,
                };
              }
            );
            setCourseTraiList(trainingTopics);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  // const GetEventList = () => {
  //   Axios.get(`/api/Events/GetActiveEventList?userId=${logDetail?.userId}`, {
  //     headers,
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         if (res.data.success) {
  //           let comList = res?.data?.payload?.map((c, i) => {
  //             return {
  //               eventId: c.eventId,
  //               name: c?.name,
  //             };
  //           });
  //           setEventList(comList);
  //         }
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };
  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("CoverImage", url);
        setValue("CoverImage1", url.name);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      // e.target.value = null;
    }
  };
  // crop code end
  const handleDoCMultiFile = async (e) => {
    if (e.target.files.length) {
      //   setImgName(e.target.files[0]);
      if (e.target.files.length > 5) {
        setValue(`DocumentPathf`, "");
        setValue(`SessionDoc1`, "");
      } else if (e.target.files?.length > 0) {
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];
          multifile.push(multi?.name);
          base64_namemulti.push(multi);
        }

        setValue(`DocumentPathf`, base64_namemulti);
        setValue(`SessionDoc1`, multifile);
      }
    }
  };
  const handleOpen = (d) => {
    setMatD(d);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setMatD();
  };
  const deleteDocument = () => {
    setOpenCircle(true);
    Axios.delete(
      `/api/Classes/DeleteClassesDocumentById?classesDocumnetId=${matD?.classesDocumnetId}`,
      {
        headers,
      }
    )
      .then((responce) => {
        if (responce.status === 200) {
          if (responce.data.success) {
            setOpenCircle(false);
            handleClose();
            toast.success(responce.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setDerror({ err: "" });
            getClassEditDetail(id);
          } else {
            setOpenCircle(false);
            setDerror({ err: responce.data?.message });
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const GetSpeakerList = () => {
    setOpenCircle(true);
    Axios.get("/api/Speaker/GetAllSpeakerList", { headers })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let sp = res?.data?.payload?.map((x) => {
              return {
                value: x?.speakerId,
                label: x?.speakerName,
              };
            });
            setDataOfSpeaker(sp);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const handleChangeSpeakerMulti = (e) => {
    setSpeakerSelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleChangeCourceMulti = (e) => {
    setCourseCode(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleChangeLearMulti = (e) => {
    setCourseLear(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const handleChangeTraiMulti = (e) => {
    setCourseTrai(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const handleConferenceSelect = (e) => {
    setConfId(e);
    setValue("ConferenceId", e);
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: () => 9999 }} open={openCircle}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="create_event_class">
        <div className="main_wrapper">
          <div className="title mb-3">
            {id ? "Edit" : "Create"} Session/Class{" "}
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  {/* <div className="col-md-12">
                    <div className="title mb-3">
                      {id ? "Edit" : "Create"} Session/Class{" "}
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Session/Class Title
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter session title"
                        {...register("Title", {
                          required: "This field is required",
                          maxLength: {
                            value: 150,
                            message: "Max character 150 allowed",
                          },
                        })}
                      />
                      {errors.Title && (
                        <span role="alert" className="error_text">
                          {errors.Title.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Select Speaker/Trainer Teaching
                        <span className="red-color"> *</span>
                      </label>
                      <Select
                        isMulti
                        name="colors"
                        options={dataOfSpeaker}
                        className="course-caretory-multi-select f-14-400"
                        classNamePrefix="select"
                        filterOption={customFilter}
                        value={dataOfSpeaker?.filter((obj) =>
                          speakerSelect?.includes(obj.value)
                        )}
                        onChange={handleChangeSpeakerMulti}
                      />
                      {derror?.speak_err && (
                        <span role="alert" className="d-block error_text">
                          {derror.speak_err}
                        </span>
                      )}
                      {/* <select
                        className="form-select "
                        {...register("SpeakerId", {
                          required: "This field is required",
                        })}
                      >
                        <option value="">Select Speaker</option>
                        {dataOfSpeaker?.map((m, i) => (
                          <option value={m.speakerId} key={i}>
                            {m.speakerName}
                          </option>
                        ))}
                      </select>
                      {errors.SpeakerId && (
                        <span role="alert" className="error_text">
                          {errors.SpeakerId.message}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Select Conference
                        <span className="red-color"> *</span>
                      </label>

                      <Select
                        isMulti={false}
                        name="colors"
                        options={conferenceList}
                        isClearable={true}
                        isDisabled={id ? true : false}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Conference"
                        filterOption={customFilter}
                        value={conferenceList?.filter(
                          (obj) => obj.value === confId
                        )}
                        onChange={(e) => handleConferenceSelect(e?.value)}
                      />
                      <select
                        className="form-select d-none"
                        disabled={id ? true : false}
                        {...register("ConferenceId", {
                          required: "This field is required",
                        })}
                      >
                        <option value="">Select Conference</option>
                        {conferenceList &&
                          conferenceList?.map((m, i) => (
                            <option value={m.cofId} key={i}>
                              {m.title}
                            </option>
                          ))}
                      </select>
                      {errors.ConferenceId && (
                        <span role="alert" className="error_text">
                          {errors.ConferenceId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Select Event </label>
                      <select
                        className="form-select "
                        {...register("EventId", {
                          required: "Please select minite",
                        })}
                      >
                        <option value="">Select Event</option>
                        {eventList &&
                          eventList?.map((m, i) => (
                            <option value={m.eventId} key={i}>
                              {m.name}
                            </option>
                          ))}
                      </select>
                      {errors.EventId && (
                        <span role="alert" className="error_text">
                          {errors.EventId.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label">Key Area</label>
                      <Select
                        isMulti
                        name="colors"
                        options={courseCodeList}
                        className="course-caretory-multi-select f-14-400"
                        classNamePrefix="select"
                        filterOption={customFilter}
                        value={courseCodeList?.filter((obj) =>
                          courseCode?.includes(obj.value)
                        )}
                        onChange={handleChangeCourceMulti}
                      />
                      {/* {derrors.course_code && (
                        <span role="alert" className="error_text">
                          {derrors.course_code}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group  ">
                      <label className="form-label">Learning Objectives</label>
                      <Select
                        isMulti
                        name="colors"
                        options={courseLearList}
                        className="course-caretory-multi-select f-14-400"
                        classNamePrefix="select"
                        filterOption={customFilter}
                        value={courseLearList?.filter((obj) =>
                          courseLear?.includes(obj.value)
                        )}
                        onChange={handleChangeLearMulti}
                      />
                      {/* {derrors.course_l && (
                        <span role="alert" className="error_text">
                          {derrors.course_l}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label">Training Topic</label>
                      <Select
                        isMulti
                        name="colors"
                        options={courseTraiList}
                        className="course-caretory-multi-select f-14-400"
                        classNamePrefix="select"
                        filterOption={customFilter}
                        value={courseTraiList?.filter((obj) =>
                          courseTrai?.includes(obj.value)
                        )}
                        onChange={handleChangeTraiMulti}
                      />
                      {/* {derrors.course_t && (
                        <span role="alert" className="error_text">
                          {derrors.course_t}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Session Image
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`CoverImage1`, {
                            // required: {
                            //   value: true,
                            //   message: "This field is required",
                            // },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handleFile(e)}
                          />
                        </IconButton>
                      </div>
                      {/* crop code start */}
                      {src && (
                        <CropModel
                          src={src}
                          filename={filename}
                          setCroppedImageUrl={setCroppedImageUrlCallback}
                          openCropModal={openCropModal}
                          setCropModal={setCropModal}
                        />
                      )}
                      {/* crop code end */}
                      {errors.CoverImage1 && (
                        <span role="alert" className="error_text">
                          {errors.CoverImage1.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        CEU&apos;s
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Ceu"
                        {...register("Ceus", {
                          // required: "This field is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message:
                              "Enter valid ceu the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                          },
                        })}
                      />
                      {errors.Ceus && (
                        <span role="alert" className="error_text">
                          {errors.Ceus.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Cost</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter cost"
                        {...register("Cost", {
                          required: "This field is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message:
                              "Enter valid cost the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                          },
                        })}
                      />
                      {errors.Cost && (
                        <span role="alert" className="error_text">
                          {errors.Cost.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Target Audience
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter target audience"
                        {...register("TargetAudienceCode", {
                          // required: "This field is required",
                        })}
                      />
                      {errors.TargetAudienceCode && (
                        <span role="alert" className="error_text">
                          {errors.TargetAudienceCode.message}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Key Area</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter key area"
                        {...register("Keyarea", {
                          required: "This field is required",
                        })}
                      />
                      {errors.Keyarea && (
                        <span role="alert" className="error_text">
                          {errors.Keyarea.message}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Training Topic</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter training topic"
                        {...register("TrainingTopic", {
                          required: "This field is required",
                        })}
                      />
                      {errors.TrainingTopic && (
                        <span role="alert" className="error_text">
                          {errors.TrainingTopic.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Learning Objective</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter learning objective"
                        {...register("Learningobjective", {
                          required: "This field is required",
                        })}
                      />
                      {errors.Learningobjective && (
                        <span role="alert" className="error_text">
                          {errors.Learningobjective.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label ">
                        Start date
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        min={id ? "" : minDate}
                        {...register("startDate", {
                          required: "This field is required",
                          valueAsDate: true,
                          validate: (val) => {
                            if (new Date(minDate) > val) {
                              return "Your end date can not less than from today date";
                            }
                          },
                        })}
                      />
                      {errors.startDate && (
                        <span role="alert" className="error_text">
                          {errors.startDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label ">
                        Start Time
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        {...register("startTime", {
                          required: "This field is required",
                        })}
                      />
                      {errors.startTime && (
                        <span role="alert" className="error_text">
                          {errors.startTime.message}
                        </span>
                      )}
                      {/* <div className="d-flex">
                        <select
                          className="form-select "
                          {...register("sHH", {
                            required: "Please select hour",
                          })}
                        >
                          <option value="">HH</option>
                          {hhList &&
                            hhList?.map((h, i) => (
                              <option value={h.text} key={i}>
                                {h.text}
                              </option>
                            ))}
                        </select>
                        <select
                          className="form-select "
                          {...register("sMM", {
                            required: "Please select minute",
                          })}
                        >
                          <option value="">MM</option>
                          {mmList &&
                            mmList?.map((m, i) => (
                              <option value={m.text} key={i}>
                                {m.text}
                              </option>
                            ))}
                        </select>
                      </div> */}
                      {/* {errors.sHH && (
                        <span role="alert" className="error_text">
                          {errors.sHH.message}
                        </span>
                      )}{" "}
                      {errors.sMM && (
                        <span role="alert" className="error_text">
                          {errors.sMM.message}
                        </span>
                      )}{" "}
                      {errors.sAM && (
                        <span role="alert" className="error_text">
                          {errors.sAM.message}
                        </span>
                      )} */}
                      {derror?.time_err && (
                        <span role="alert" className="d-block error_text">
                          {derror.time_err}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label ">
                        End Time
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        {...register("endTime", {
                          required: "This field is required",
                          validate: (val) => {
                            if (watch("startTime") >= val) {
                              return "Your end time can not smaller than from start time";
                            }
                          },
                        })}
                      />
                      {errors.endTime && (
                        <span role="alert" className="error_text">
                          {errors.endTime.message}
                        </span>
                      )}
                      {/* <div className="d-flex">
                        <select
                          className="form-select "
                          {...register("eHH", {
                            required: "Please select hour",
                          })}
                        >
                          <option value="">HH</option>
                          {hhList &&
                            hhList?.map((h, i) => (
                              <option value={h.text} key={i}>
                                {h.text}
                              </option>
                            ))}
                        </select>
                        <select
                          className="form-select "
                          {...register("eMM", {
                            required: "Please select minute",
                          })}
                        >
                          <option value="">MM</option>
                          {mmList &&
                            mmList?.map((m, i) => (
                              <option value={m.text} key={i}>
                                {m.text}
                              </option>
                            ))}
                        </select>
                      </div>
                      {errors.eHH && (
                        <span role="alert" className="error_text">
                          {errors.eHH.message}
                        </span>
                      )}{" "}
                      {errors.eMM && (
                        <span role="alert" className="error_text">
                          {errors.eMM.message}
                        </span>
                      )}{" "}
                      {errors.eAM && (
                        <span role="alert" className="error_text">
                          {errors.eAM.message}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Meeting Room Number
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter meeting room number"
                        {...register("MeetingRoomNumber", {
                          required: "This field is required",
                        })}
                      />
                      {errors.MeetingRoomNumber && (
                        <span role="alert" className="error_text">
                          {errors.MeetingRoomNumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Documents (Add Multiple Documents)
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`SessionDoc1`, {
                            // required: {
                            //   value: true,
                            //   message: "This field is required",
                            // },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            multiple
                            max={5}
                            className="form-control"
                            hidden
                            onChange={(e) => handleDoCMultiFile(e)}
                          />
                        </IconButton>
                      </div>

                      {/* crop code end */}
                      {errors.SessionDoc1 && (
                        <span role="alert" className="error_text">
                          {errors.SessionDoc1.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label ">
                        Description
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <textarea
                        rows="5"
                        type="text"
                        className="form-control"
                        placeholder="Enter description"
                        {...register("Descriptions")}
                      />
                      {errors.Descriptions && (
                        <span role="alert" className="error_text">
                          {errors.Descriptions.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mat_box col-md-12">
                    <div className="row">
                      {displayDoc?.map((mat, no) => (
                        <div className="col-md-3" key={no}>
                          <div className="mat_box_main mb-3">
                            <div className="num me-3">{no + 1}</div>
                            <div className="text_data">
                              {mat?.fileName ? mat?.fileName : "Material"}
                            </div>
                            <button
                              className="btn_dlt "
                              type="button"
                              onClick={() => handleOpen(mat)}
                            >
                              <VscTrash className="icon" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={`col-md-12 text-end`}>
                    {/* <div className="form-group"> */}
                    <Button
                      className="back_button"
                      onClick={() => nav("/sessions", { replace: true })}
                    >
                      Back
                    </Button>
                    <Button className="detail_button ms-3" type="submit">
                      {id ? "Update" : "Save"}
                    </Button>
                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                    {/* </div> */}
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Delete Class Document</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {matD?.fileName}?</span>
                  </p>
                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={deleteDocument}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default SessionCreate;
