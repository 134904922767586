import React, { Suspense, useEffect, useState } from "react";
import Charts from "../../Components/Chart/Charts";
import "./index.scss";
import { Link } from "react-router-dom";
import Highcharts from "highcharts/highmaps";
import NewLIstTable from "../../Components/NewLIstTable";
import { PostCallApi } from "../../../Action/Action";
import { Config } from "../../../Utils/Config";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress, Tooltip } from "@mui/material";

import ImgPopUp from "../../Components/ImgPopUp";
import DeletePopup from "../../Components/DeletePopup";
// import { Pagination } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import welcome from "../../../assets/images/Flat-Journalist.png";

const calendaruser = "/assets/icons/calendaruser.svg";
const eventschedule = "/assets/icons/event-schedule.svg";
const exhibitor = "/assets/icons/exhibitor.svg";
const floorplan = "/assets/icons/floorplan.svg";
const registerAsAttendee = "/assets/icons/registerAsAttendee.svg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsConference = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "contactName",
    label: "Contact Name",
  },
  {
    id: "contactNumber",
    label: "Contact Number",
  },
  {
    id: "conferenceStartdate",
    label: "Start Date",
  },
  {
    id: "conferenceEnddate",
    label: "End Date",
  },
  {
    id: "status",
    label: "Status",
    color: true,
  },
];
const headCellsSessionAttended = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "conferenceName",
    label: "Conference Name",
  },
  {
    id: "sessionName",
    label: "Session Name",
  },
  {
    id: "ceuEarned",
    label: "CEU's earned",
  },
  {
    id: "instructorName",
    label: "Instructor Name",
  },
];
const headCellInterestedProducts = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "productName",
    label: "Product Name",
    widthFix: 600,
  },
  {
    id: "productImage",
    label: "Product Image",
  },
  {
    id: "manufacturer",
    label: "Manufacturer",
  },
  {
    id: "exhibitorName",
    label: "Exhibitor Name",
  },
  {
    id: "compnayName",
    label: "Company Name",
  },
  {
    id: "createdOn",
    label: "Created Date",
  },
  // {
  //   id: "phone",
  //   label: "Contact Number",
  // },
];
function AttendeeDashboard() {
  const [openCircle, setOpenCircle] = useState(false);
  const [interestedProductList, setInterestedProductList] = useState([]);
  const [attendedCEUEarned, setAttendedCEUEarned] = useState([]);
  const [conferenceList, setConferenceList] = useState([]);
  const [conferenceCEUEarned, setConferenceCEUEarned] = useState([]);
  // const [seAt, setSeAt] = useState([]);
  // const [announcementsDetails, setAnnouncementsDetails] = useState();
  const [speakerId, setSpeakerId] = useState();
  // const today = new Date();

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logindetail = useSelector((state) => state.login.LoginDetails);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    document.title = "FIG Events | Dashboard";
    GetInterestedProducts();
    GetAttendedCEUEarned();
    GetConferenceList();
    GetConferenceCEUEarnedList();
    // GetAnnouncementsDetails();
    // getSessionAttend();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetInterestedProducts();
    // eslint-disable-next-line
  }, [deleteInfo]);

  // let highchartSession = {
  //   chart: {
  //     type: "line",
  //     height: 160,
  //   },
  //   title: {
  //     text: "",
  //   },
  //   subtitle: {
  //     text: "",
  //   },
  //   color: ["#3CD856"],
  //   xAxis: {
  //     categories: seAt?.length > 0 ? seAt?.map((x) => x?.monthName) : [],
  //     // categories: [
  //     //   "Jan",
  //     //   "Feb",
  //     //   "Mar",
  //     //   "Apr",
  //     //   "May",
  //     //   "Jun",
  //     //   "Jul",
  //     //   "Aug",
  //     //   "Sep",
  //     //   "Oct",
  //     //   "Nov",
  //     //   "Dec",
  //     // ],
  //   },
  //   yAxis: {
  //     allowDecimals: false,
  //     title: {
  //       text: "",
  //     },
  //     min: 0,
  //   },
  //   plotOptions: {
  //     line: {
  //       dataLabels: {
  //         enabled: false,
  //       },
  //       enableMouseTracking: false,
  //       background: "#3CD856",
  //     },
  //   },
  //   series: [
  //     {
  //       name: "Reggane",
  //       data: seAt?.length > 0 ? seAt?.map((x) => x?.counts) : [],
  //       showInLegend: false,
  //       color: "#3CD856",
  //     },
  //   ],
  // };
  let highchartCEU = {
    chart: {
      height: "500",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    colors: ["#18BAD4"],
    xAxis: {
      categories:
        conferenceCEUEarned?.length > 0
          ? conferenceCEUEarned?.map((x) => x.conferenceName)
          : [],
    },
    yAxis: {
      title: {
        text: "",
      },
      min: 0,
    },
    series: [
      {
        type: "column",
        name: "CEU",
        borderRadius: 5,
        colorByPoint: true,
        data:
          conferenceCEUEarned?.length > 0
            ? conferenceCEUEarned?.map((x) => x.ceuEarned)
            : [],
        // data: [55, 35, 23, 35, 15, 52, 28, 22],
        showInLegend: false,
      },
    ],
  };
  const GetInterestedProducts = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AttendeeDashboard/GetAttendeeInterestedProductList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            createdOn: c?.createdOn
              ? new Date(c?.createdOn).toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            productImage: c?.productImage ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${c.productImage}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : c?.mutipleImages?.length > 0 ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${c?.mutipleImages?.[0]}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              ""
            ),
            Action: (
              <>
                {/* <Link className="me-2">
                  <img src={download} alt="icon" />
                </Link> */}
                <Link
                  to={`/exhibitor-product-detail/${c?.productId}`}
                  className="btn_edit me-2"
                >
                  View
                </Link>
                <DeletePopup
                  title={"Delete Product"}
                  text={c?.productName}
                  url={`/api/AttendeeDashboard/DeleteProductFromInterestedProductList?attendeeFavoriteProductId=${c?.attendeeFavoriteProductId}`}
                />
              </>
            ),
          };
        });
        setInterestedProductList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const GetAttendedCEUEarned = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AttendeeDashboard/GetSessionAttendedCEUEarnedList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            instructorName: (
              <select
                value={
                  c?.speakerList[0]?.speakerId
                    ? c?.speakerList[0]?.speakerId
                    : ""
                }
                onChange={(e) => handleSpeaker(e.target.value, i)}
                className="form-select"
              >
                {c?.speakerList?.map((x) => (
                  <option key={x?.speakerId} value={x?.speakerId}>
                    {x?.speakerName}
                  </option>
                ))}
              </select>
            ),
            Action: (
              <>
                <Link
                  className="btn_edit"
                  to={`/speaker-public-profile`}
                  state={{
                    speakerId: c?.speakerList[0]?.speakerId
                      ? c?.speakerList[0]?.speakerId
                      : "",
                  }}
                >
                  Contact Instructor
                </Link>
              </>
            ),
          };
        });
        setAttendedCEUEarned(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const handleSpeaker = (value, index) => {
    setSpeakerId({ value: value, index: index });
  };

  useEffect(() => {
    if (speakerId) {
      ChangeDataOfEarnCEu();
    }
  }, [speakerId]);

  const ChangeDataOfEarnCEu = () => {
    let comList = attendedCEUEarned?.map((c, i) => {
      return {
        ...c,
        instructorName: (
          <select
            value={
              speakerId?.index === i
                ? speakerId?.value
                : c?.speakerList[0]?.speakerId
                ? c?.speakerList[0]?.speakerId
                : ""
            }
            className="form-select"
            onChange={(e) => handleSpeaker(e.target.value, i)}
          >
            {c?.speakerList?.map((x) => (
              <option key={x?.speakerId} value={x?.speakerId}>
                {x?.speakerName}
              </option>
            ))}
          </select>
        ),
        Action: (
          <>
            <Link
              className="btn_edit"
              to={`/speaker-public-profile`}
              state={{
                speakerId:
                  speakerId?.index === i
                    ? speakerId?.value
                    : c?.speakerList[0]?.speakerId
                    ? c?.speakerList[0]?.speakerId
                    : "",
              }}
            >
              Contact Instructor
            </Link>
          </>
        ),
      };
    });
    setAttendedCEUEarned(comList);
  };

  const GetConferenceList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AttendeeDashboard/GetConferenceList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            conferenceStartdate: c?.conferenceStartdate
              ? new Date(c?.conferenceStartdate)?.toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "",
            conferenceEnddate: c?.conferenceEnddate
              ? new Date(c?.conferenceEnddate)?.toLocaleString("en-us", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "",
            status: c?.isRegister || c?.isExhiRegister ? "REGISTERED" : "",
            color: c?.isRegister || c?.isExhiRegister ? "approv" : "",
            Action: (
              <>
                {c?.isRegister || !c?.isAttendeeRegister ? (
                  ""
                ) : (
                  <Tooltip title="Register as Attendee">
                    <Link
                      className="icon_btn me-2 "
                      to={`/conference-user-register/${c?.conferenceId}`}
                    >
                      <img src={registerAsAttendee} alt="" />
                    </Link>
                  </Tooltip>
                )}
                {c?.isExhiRegister ||
                !c?.isExhibitorRegister ||
                c?.isAllBoothBooked ? (
                  ""
                ) : (
                  <Tooltip title="Register as Exhibitor">
                    <Link
                      className="icon_btn me-2 "
                      to={`/conference-exhibotor-register/${c?.conferenceId}`}
                    >
                      <img src={exhibitor} alt="" />
                    </Link>
                  </Tooltip>
                )}
                <Tooltip title="View Conference Schedule">
                  <Link to={`/attendee-schedule`} className="icon_btn me-2">
                    {/* <Link to={`/conference-calendar`} className="btn_edit me-2"> */}
                    <img src={eventschedule} alt="" />
                  </Link>
                </Tooltip>
                {c?.isRegister || c?.isExhiRegister ? (
                  <Tooltip title="My Schedule">
                    <Link to={`/my-schedule`} className="icon_btn me-2">
                      <img src={calendaruser} alt="" />
                    </Link>
                  </Tooltip>
                ) : (
                  ""
                )}
                {c?.isExhibitorRegister && (
                  <Tooltip title="View Show Floor">
                    <Link
                      to={`/conference-floor-plan`}
                      state={{ conferenceId: c?.conferenceId }}
                      className="icon_btn me-2"
                    >
                      <img src={floorplan} alt="" />
                    </Link>
                  </Tooltip>
                )}
              </>
            ),
          };
        });
        setConferenceList(comList);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  const GetConferenceCEUEarnedList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AttendeeDashboard/GetConferenceCEUEarnedList?userId=${logindetail?.userId}`,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        setConferenceCEUEarned(res.data.payload);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  // const GetAnnouncementsDetails = async () => {
  //   setOpenCircle(true);
  //   let state = "true";
  //   let seData = {
  //     url: `/api/EventPromotion/GetAllEventPromotionList?isActiveEventPromotion=${state}`,
  //     headers: headers,
  //   };
  //   let res = await GetCallApi(seData);

  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       setOpenCircle(false);
  //       setAnnouncementsDetails(
  //         res.data.payload?.filter((x) => x?.type === "Web")
  //       );
  //     } else {
  //       setOpenCircle(false);
  //     }
  //   } else {
  //     setOpenCircle(false);
  //   }
  // };
  // const getSessionAttend = async () => {
  //   let seData = {
  //     url: `/api/AttendeeDashboard/GetSessionAttendedList?userId=${logindetail?.userId}`,
  //     body: {},
  //     headers: headers,
  //   };
  //   let res = await PostCallApi(seData);
  //   if (res?.status === 200) {
  //     if (res?.data?.success) {
  //       setSeAt(res?.data?.payload);
  //     }
  //   }
  // };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="attendee-dashboard">
          <div className="container-fluid">
            <h1>Dashboard</h1>
            <div className="row">
              {/* <div className="col-lg-8">
                <h6>Announcements</h6>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 1,
                    },
                    1024: {
                      slidesPerView: 1,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {announcementsDetails?.length > 0 ? (
                    announcementsDetails?.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div className="welcome-box">
                          <div className="row">
                            <div className="col-lg-8">
                              <h1 className="mb-3">{item?.title}</h1>
                              <p className="mb-0">
                                {item?.description
                                  ? item?.description
                                  : "No Description"}
                              </p>
                            </div>
                            <div className="col-lg-4">
                              <div className="welcome-img">
                                <img
                                  loading="lazy"
                                  className="img-fluid"
                                  src={
                                    item?.eventImageUrl
                                      ? Config.API_HOST_URL_live +
                                        item?.eventImageUrl
                                      : welcome
                                  }
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = welcome;
                                  }}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <div className="f-20-600">No Data</div>
                  )}
                </Swiper>
              </div>
              <div className="col-lg-4 padding-top">
                <div className="wht-box">
                  <h6>My Classes/Sessions</h6>
                  <Charts series={highchartSession} highcharts={Highcharts} />
                </div>
              </div> */}
            </div>
            <div className="row pt-30">
              <div className="col-lg-12">
                <div className="new_card p-0">
                  <NewLIstTable
                    title="Conference List"
                    headCells={headCellsConference}
                    action={true}
                    ListData={conferenceList}
                    dropdown={false}
                    btnshow={true}
                    exportBtn={false}
                  />
                </div>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="wht-box">
                  <h6>CEU&apos;s Earned</h6>
                  {/* <h6>CEU's Earned</h6> */}
                  <Charts series={highchartCEU} highcharts={Highcharts} />
                </div>
              </div>

              <div className="col-lg-6 mt-4">
                <div className="new_card p-0">
                  <NewLIstTable
                    title="Sessions Attended and CEU's Earned"
                    headCells={headCellsSessionAttended}
                    action={true}
                    ListData={attendedCEUEarned}
                    dropdown={false}
                    btnshow={true}
                    exportBtn={false}
                  />
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                <div className="new_card p-0">
                  <NewLIstTable
                    title="Your Interested Products"
                    headCells={headCellInterestedProducts}
                    action={true}
                    ListData={interestedProductList}
                    dropdown={false}
                    btnshow={true}
                    exportBtn={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AttendeeDashboard;
