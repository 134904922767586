import React, { useCallback, useEffect, useState } from "react";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
// import NotFound from "../../../assets/images/Image_not_available.jpg";
import { Config } from "../../../Utils/Config";
import { Backdrop, Button, CircularProgress, IconButton } from "@mui/material";
import { LoginUser } from "../../../reducers/login";
import { toast } from "react-toastify";
import CropModel from "../../Components/CropModel";
import CropFix from "../../Components/CropModel/CropFix";
import AOS from "aos";
import "aos/dist/aos.css";
import { useForm } from "react-hook-form";
import { BsFillImageFill } from "react-icons/bs";
import MultiAddress from "./MultiAddress";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import UserBio from "./UserBio";
import { customFilter } from "../../Components/CustomFilterForReactSelect";

const pro_img = "/assets/images/default_profile.jpg";

const diaList = [
  {
    label: "Gluten-free",
    value: "Gluten-free",
  },
  {
    label: "Vegetarian",
    value: "Vegetarian",
  },
  {
    label: "Vegan",
    value: "Vegan",
  },
  {
    label: "Dairy Free",
    value: "Dairy Free",
  },
  {
    label: "Nut Allergy",
    value: "Nut Allergy",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const adaList = [
  {
    label: "Wheelchair User",
    value: "Wheelchair User",
  },
  {
    label: "Mobility Scooter User",
    value: "Mobility Scooter User",
  },
  {
    label: "Cane/Walker User",
    value: "Cane/Walker User",
  },
  {
    label: "Visual Impairment (Blind)",
    value: "Visual Impairment (Blind)",
  },
  {
    label: "Low Vision",
    value: "Low Vision",
  },
  {
    label: "Hearing Impaired",
    value: "Hearing Impaired",
  },
  {
    label: "Cognitive Disabilities (e.g., Autism, Dyslexia)",
    value: "Cognitive Disabilities (e.g., Autism, Dyslexia)",
  },
  {
    label: "Speech Impairments",
    value: "Speech Impairments",
  },
  {
    label: "Assistive Device User (e.g., Hearing Aids, Visual Aid Devices)",
    value: "Assistive Device User (e.g., Hearing Aids, Visual Aid Devices)",
  },
  {
    label: "Other",
    value: "Other",
  },
];

function AttendeeProfile() {
  const { id } = useParams();
  const [usDetails, setUsDetails] = useState();
  // const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [selectDr, setSelectDr] = useState([]);
  const [selectAda, setSelectAda] = useState([]);
  // const [bio, setBio] = useState(false);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const fixSize = { fixwidth: "1920", fixheight: "240" };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    document.title = "FIG Events | Profile";

    GetUsDetails(id ? id : LogDetail?.userId);
    // eslint-disable-next-line
  }, [id]);

  const GetUsDetails = async (id) => {
    let seData = {
      url: `/api/UserMst/GetAttendeeRoleData?userId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let ud = res.data.payload[0];
        setValue("firstName", ud?.firstName);
        setValue("lastName", ud?.lastName);
        setValue("email", ud?.email);
        setValue("phone", ud?.phone);
        setValue("jobTitle", ud?.jobTitle);
        setValue("workEmail", ud?.workEmail);
        setValue("workPhone", ud?.workPhone);
        setValue("CoverImageUrl", ud?.coverPhoto);
        setValue(
          "otherMealDescription",
          ud?.mealsRequirements?.filter((x) => x?.meal === "Other")[0]
            ?.otherDescription
        );
        setSelectDr(ud?.mealsRequirements?.map((m) => m?.meal));
        setValue(
          "otherADADescription",
          ud?.adaRequirements?.filter((x) => x?.ada === "Other")[0]
            ?.otherDescription
        );
        setSelectAda(ud?.adaRequirements?.map((m) => m?.ada));
        setUsDetails(ud);
      }
    }
  };

  let dispatch = useDispatch();

  // const handleProImg = async (img) => {
  //   let seData = {
  //     url: `/api/UserMst/CreateUser`,
  //     body: {
  //       firstName: LogDetail?.firstName,
  //       lastName: LogDetail?.lastName,
  //       email: LogDetail?.email,
  //       phone: LogDetail?.phone,
  //       roleId: LogDetail?.roleId,
  //       userId: LogDetail?.userId,
  //       createdBy: LogDetail?.userId,
  //       UserType: LogDetail?.roleName,
  //       ProfileImage: img,
  //     },

  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "multipart/form-data",
  //       Accept: "*/*",
  //     },
  //   };
  //   setOpenCircle(true);
  //   let res = await PostCallApi(seData);
  //   setOpenCircle(false);
  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       dispatch(
  //         LoginUser({
  //           ...LogDetail,
  //           profileImage: res.data.payload.profileImage,
  //         })
  //       );

  //       toast.success(res.data.message, {
  //         position: "top-right",
  //         autoClose: 1500,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     }
  //   }
  // };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setCroppedImageUrl(url);
        // handleProImg(url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end

  useEffect(() => {
    AOS.init({
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const UpdateUser = async (data) => {
    if (id) {
      return false;
    }
    let sendData = {
      ...data,
      userId: LogDetail?.userId,
      userMealDetails: selectDr ? selectDr : [],
      userADADetails: selectAda ? selectAda : [],
      ProfileImage: croppedImageUrl ? croppedImageUrl : "",
    };

    let seData = {
      url: `/api/UserMst/UpdateUserProfile`,
      body: sendData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        dispatch(
          LoginUser({
            ...LogDetail,
            ...data,
            profileImage: res.data.payload.profileImage,
          })
        );
        setCroppedImageUrl();
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        GetUsDetails(LogDetail?.userId);
      }
    }
  };

  const handleChangeDr = (e) => {
    setSelectDr(Array.isArray(e) ? e?.map((d) => d?.value) : []);
  };

  const handleChangeAda = (e) => {
    setSelectAda(Array.isArray(e) ? e?.map((d) => d?.value) : []);
  };

  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("CoverPhoto", url);
        setValue("CoverImageUrl", url.name);
        // handleProImg(url);
        // setCroppedImageUrl(URL.createObjectURL(url));
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );

  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  const selectStyle = {
    control: (base) => ({
      ...base,
      borderColor: "hsl(0, 0%, 80%)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "hsl(0, 0%, 80%)",
      },
    }),
    multiValueRemove: (style, state) => {
      return {
        ...style,
        ...(state.data?.isDisabled
          ? {
              visibility: "hidden",
              width: "4px",
            }
          : {}),
      };
    },
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {src && (
        <CropModel
          src={src}
          filename={filename}
          setCroppedImageUrl={setCroppedImageUrlCallback}
          openCropModal={openCropModal}
          setCropModal={setCropModal}
        />
      )}
      <section className="attendee_pro">
        <div className="main_wrapper">
          <div className="d-flex justify-content-between">
            <div className="title mb-3">Edit Profile </div>
            <div className="mt-3">
              {!id ? (
                <Link to="/update-password" className="detail_button">
                  Update Password
                </Link>
              ) : (
                <Link to={-1} className="detail_button">
                  Go Back
                </Link>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <label className="m-auto profile_div" htmlFor="fileToUpload">
                    <div className="profile_pic">
                      <img
                        src={
                          croppedImageUrl
                            ? URL.createObjectURL(croppedImageUrl)
                            : usDetails?.profileImage
                            ? Config.API_HOST_URL_live + usDetails?.profileImage
                            : pro_img
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = pro_img;
                        }}
                        className="img-fluid"
                        alt=""
                      />
                      <div className="icon">
                        <BsFillImageFill className="icon_pic" />
                      </div>
                    </div>
                  </label>
                  <input
                    hidden
                    type="file"
                    name="fileToUpload"
                    id="fileToUpload"
                    accept="image/*"
                    disabled={id ? true : false}
                    onChange={handleFile}
                  />
                </div>
                <div className="col-md-9">
                  <form onSubmit={handleSubmit(UpdateUser)}>
                    <div className="row ">
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            First Name
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter First Name"
                            disabled={id ? true : false}
                            {...register("firstName", {
                              required: "This field is required",
                            })}
                          />
                          {errors?.firstName && (
                            <span role="alert" className="error_text">
                              {errors.firstName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Last Name
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter last Name"
                            disabled={id ? true : false}
                            {...register("lastName", {
                              required: "This field is required",
                            })}
                          />
                          {errors?.lastName && (
                            <span role="alert" className="error_text">
                              {errors.lastName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Email
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter email"
                            disabled={id ? true : false}
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Please Enter Valid Email",
                              },
                            })}
                          />
                          {errors?.email && (
                            <span role="alert" className="error_text">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Phone
                            <span className="red-color"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone"
                            disabled={id ? true : false}
                            {...register("phone", {
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          {errors?.phone && (
                            <span role="alert" className="error_text">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">Job Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Job Title"
                            disabled={id ? true : false}
                            {...register("jobTitle")}
                          />
                          {errors?.jobTitle && (
                            <span role="alert" className="error_text">
                              {errors.jobTitle.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">Work Email</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Work Email"
                            disabled={id ? true : false}
                            {...register("workEmail", {
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Please Enter Valid Email",
                              },
                            })}
                          />
                          {errors?.workEmail && (
                            <span role="alert" className="error_text">
                              {errors.workEmail.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">Work Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Work Phone"
                            disabled={id ? true : false}
                            {...register("workPhone", {
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          {errors?.workPhone && (
                            <span role="alert" className="error_text">
                              {errors.workPhone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">
                            Dietary Requirements
                          </label>
                          <Select
                            isMulti
                            name="colors"
                            isDisabled={id ? true : false}
                            options={diaList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Dietary Requirements"
                            filterOption={customFilter}
                            value={diaList?.filter((obj) =>
                              selectDr?.includes(obj.value)
                            )}
                            onChange={handleChangeDr}
                          />
                        </div>
                      </div>
                      {selectDr?.filter((d) => d === "Other")?.length > 0 ? (
                        <div className="col-md-4" data-aos="fade-in">
                          <div className="form-group">
                            <label className="form-label">
                              Dietary Description
                              <span className="red-color"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Description"
                              disabled={id ? true : false}
                              {...register("otherMealDescription", {
                                required: "This field is required",
                              })}
                            />
                            {errors?.otherMealDescription && (
                              <span role="alert" className="error_text">
                                {errors.otherMealDescription.message}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">ADA Requirements</label>
                          <Select
                            isMulti
                            name="colors"
                            isDisabled={id ? true : false}
                            options={adaList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select ADA Requirements"
                            filterOption={customFilter}
                            value={adaList?.filter((obj) =>
                              selectAda?.includes(obj.value)
                            )}
                            onChange={handleChangeAda}
                          />
                        </div>
                      </div>
                      {selectAda?.filter((d) => d === "Other")?.length > 0 ? (
                        <div className="col-md-4" data-aos="fade-in">
                          <div className="form-group">
                            <label className="form-label">
                              ADA Description
                              <span className="red-color"> *</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Description"
                              disabled={id ? true : false}
                              {...register("otherADADescription", {
                                required: "This field is required",
                              })}
                            />
                            {errors?.otherADADescription && (
                              <span role="alert" className="error_text">
                                {errors.otherADADescription.message}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* {LogDetail?.roleName === "Exhibitor" ||
                      LogDetail?.roleName === "Admin" ? ( */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label"> Cover Image</label>
                          <div className="d-flex upload_btn_sec">
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              placeholder="No file choose"
                              disabled={id ? true : false}
                              {...register(`CoverImageUrl`)}
                            />
                            {!id ? (
                              <IconButton
                                component="label"
                                className="upload_btn m-0"
                              >
                                Choose Cover Image
                                <input
                                  id="upload"
                                  type="file"
                                  accept="image/*"
                                  className="form-control"
                                  hidden
                                  disabled={id ? true : false}
                                  onChange={(e) => handleFile1(e)}
                                />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </div>
                          {src1 && (
                            <CropFix
                              coverCropImage={true}
                              src={src1}
                              filename={filename1}
                              setCroppedImageUrl={setCroppedImageUrlCallback1}
                              openCropModal={openCropModal1}
                              setCropModal={setCropModal1}
                              fixSize={fixSize}
                            />
                          )}
                        </div>
                      </div>
                      {/* ) : (
                        ""
                      )} */}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">Association</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            menuIsOpen={false}
                            // isDisabled={true}
                            styles={selectStyle}
                            className="Association-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Association"
                            filterOption={customFilter}
                            value={usDetails?.associations?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">LEA</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            styles={selectStyle}
                            menuIsOpen={false}
                            className="Association-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LEA"
                            filterOption={customFilter}
                            value={usDetails?.lea?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">State Agency</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            className="Association-multi-select"
                            styles={selectStyle}
                            menuIsOpen={false}
                            classNamePrefix="select"
                            placeholder="Select State Agency"
                            filterOption={customFilter}
                            value={usDetails?.stateAgency?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-4" data-aos="fade-in">
                        <div className="form-group">
                          <label className="form-label">Industry</label>
                          <Select
                            isMulti
                            name="colors"
                            // options={diaList}
                            isClearable={false}
                            styles={selectStyle}
                            menuIsOpen={false}
                            className="Association-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Industry"
                            filterOption={customFilter}
                            value={usDetails?.industry?.map((x) => {
                              return {
                                label: x,
                                value: x,
                                isDisabled: true,
                              };
                            })}
                            // onChange={handleChangeDr}
                          />
                        </div>
                      </div>
                      {!id ? (
                        <div className="col-md-12">
                          <div className="w-auto form-group filter_btn mt-auto">
                            <Button className="detail_button" type="submit">
                              Update
                            </Button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <MultiAddress usDetails={usDetails} />
          <UserBio />
        </div>
      </section>
    </>
  );
}

export default AttendeeProfile;
