import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import "./index.scss";
import { PostCallApi } from "../../../Action/Action";
import { Link } from "react-router-dom";
import { IoReceiptOutline } from "react-icons/io5";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { ToastError, ToastSuccess } from "../../Components/Toast";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "exhibitorName",
    label: "Exhibitor Name",
  },
  {
    id: "exhibitorEmail",
    label: "Email",
  },
  {
    id: "exhibitorPhone",
    label: "Phone",
  },
  {
    id: "subscriptionStartDate",
    label: "Subscription StartDate",
  },
  {
    id: "subscriptionEndDate",
    label: "Subscription EndDate",
  },
  {
    id: "subscriptionPrice",
    label: "Subscription Price",
  },
  {
    id: "subscriptionValue",
    label: "Subscription Value",
  },
  {
    id: "planStatus",
    label: "Plan Status",
  },
  {
    id: "remindBeforeDays",
    label: "Remind Before Days",
  },
];
function ExhibitorSubscriptionList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [planStatusValue, setPlanStatusValue] = useState();
  const [filterPage, setFilterPage] = useState(false);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const { register, setValue, reset, handleSubmit } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Exhibitor Subscription";

    // eslint-disable-next-line
  }, []);

  const GetEXSUBList = async (data) => {
    setOpenCircle(true);
    setDataOfTable([]);
    let res = await PostCallApi({
      url: `/api/ExhibitorSubscription/GetAllExhibitorSubscriptionList`,
      body: {
        ...data,
        userId: LogDetail?.userId,
        planStatus: data?.planStatus ? data?.planStatus : "",
      },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          groupUserList:
            c?.companySubscriptionDetails?.length > 0
              ? c?.companySubscriptionDetails?.map((x, index) => {
                  return {
                    ...x,
                    id: index + 1,
                  };
                })
              : "",
          subscriptionStartDate: c?.subscriptionStartDate
            ? new Date(c?.subscriptionStartDate).toLocaleDateString("en-us", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "",
          subscriptionEndDate: c?.subscriptionEndDate
            ? new Date(c?.subscriptionEndDate).toLocaleDateString("en-us", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "",
          Action: (
            <Tooltip title="View Receipt">
              <Link className="btn_edit" to={c?.exhibitorSubscriptionPaymentId}>
                <IoReceiptOutline />
              </Link>
            </Tooltip>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res.data.message);
    }
  };
  const sendEmail = async () => {
    let res = await PostCallApi({
      url: `api/ExhibitorSubscription/CreateSendEmailToExhibitorForPlanExpires`,
      body: {},
    });
    if (res?.status === 200 && res?.data?.success) {
      ToastSuccess(res.data.message);
    } else {
      ToastError(res.data.message);
    }
  };

  const handlePlanStatus = (e) => {
    setPlanStatusValue(e);
    setValue("planStatus", e);
  };

  const planStatusOption = [
    { value: "Plan Expired", label: "Plan Expired" },
    { value: "Current Plan", label: "Current Plan" },
  ];
  const handleClearFilter = () => {
    reset();
    setDataOfTable([]);
    setPlanStatusValue();
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              {" "}
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetEXSUBList)}>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Plan Status</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={planStatusOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Duration"
                            filterOption={customFilter}
                            value={planStatusOption?.filter(
                              (obj) => obj.value === planStatusValue
                            )}
                            onChange={(e) => handlePlanStatus(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label"> Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("startDate", { valueAsDate: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label"> End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("endDate", { valueAsDate: true })}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <NewLIstTable
                  headCells={headCellsEvent}
                  title="Exhibitor Subscription List"
                  action={true}
                  ListData={dataOfTable}
                  filterPage={filterPage}
                  addBtnCall={LogDetail?.roleId === 1 ? sendEmail : false}
                  showUserListInTable={true}
                  innerProduct={true}
                  fileName={"Association Report"}
                  exportDataList={true}
                  subHeadCells={[
                    {
                      id: "id",
                      label: "id",
                    },
                    {
                      id: "companyName",
                      label: "Company Name",
                    },
                    {
                      id: "subscriptionTitle",
                      label: "Subscription Title",
                    },
                    {
                      id: "subscriptionPrice",
                      label: "Subscription Price",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ExhibitorSubscriptionList;
