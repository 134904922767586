import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import PaymentTable from "../../user/PaymentHistory/PaymentTable";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { Link } from "react-router-dom";
import { IoReceiptOutline } from "react-icons/io5";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useForm } from "react-hook-form";
import { ToastError } from "../../Components/Toast";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import Select from "react-select";
import TableHeader from "../../../TableHeader.json";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const headCellsEvent = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "associationName",
    label: "Association Name",
  },
  {
    id: "associationMemberName",
    label: "Company Name",
  },
  {
    id: "userName",
    label: "User Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "phoneNumber",
    label: "Phone",
  },
  {
    id: "paymentAmount",
    label: "Payment Amount",
  },
  {
    id: "planStatus",
    label: "Plan Status",
  },
  {
    id: "date",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
];

function AssociationMembershipReport() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);
  const [asList, setASList] = useState([]);
  const [asId, setASId] = useState();
  const [planStatus, setPlanStatus] = useState();
  const [leaList, setLeaList] = useState([]);
  const [selectLea, setSelectLea] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [selectAgency, setSelectAgency] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectIndustry, setSelectIndustry] = useState([]);

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Association Membership Report";
    getAssoList();
    getLEAList();
    getAgencyist();
    getIndustryist();
    // eslint-disable-next-line
  }, []);

  const getAssoList = async () => {
    let seData = {
      url: `/api/Association/GetAssociationListForFliter?userId=${LogDetails?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationId,
            label: x?.name,
          };
        });
        setASList(data);
      }
    }
  };

  const getLEAList = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
        LogDetails?.userId
      }`,
      // url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"LEA"}&userId=${
      //   LogDetails?.userId
      // }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setLeaList(data);
      }
    }
  };

  const getAgencyist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
        LogDetails?.userId
      }`,
      // url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"State Agency"}&userId=${
      //   LogDetails?.userId
      // }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setAgencyList(data);
      }
    }
  };

  const getIndustryist = async () => {
    let seData = {
      url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}&userId=${
        LogDetails?.userId
      }`,
      //    url: `/api/AssociationMembers/GetAssociationMembersListbyType?type=${"Industry"}&userId=${
      //   LogDetails?.userId
      // }`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            ...x,
            value: x?.associationMemberId,
            label: x?.name,
          };
        });
        setIndustryList(data);
      }
    }
  };

  const handleChangeLea = (e) => {
    setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeAgency = (e) => {
    setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const handleChangeIndustry = (e) => {
    setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  };

  const GetPaymentList = async (data) => {
    setDataOfTable([]);
    setOpenCircle(true);
    let memberList = [];
    if (selectLea?.length > 0) {
      memberList = selectLea;
    }
    if (selectAgency?.length > 0) {
      memberList = [...memberList, ...selectAgency];
    }
    if (selectIndustry?.length > 0) {
      memberList = [...memberList, ...selectIndustry];
    }
    let res = await PostCallApi({
      url: `/api/AssociationPayment/GetAssociationPaymentHistory`,
      body: {
        ...data,
        loginUserId: LogDetails?.userId,
        associationMemberId: memberList?.length > 0 ? memberList : [],
      },
    });
    setOpenCircle(false);
    setFilterPage(!filterPage);
    if (res.status === 200 && res.data.success) {
      setOpenCircle(false);
      let comList = res?.data?.payload?.map((c, i) => {
        let astype = c?.associationType ? " - " + c?.associationType : "";

        return {
          ...c,
          id: i + 1,
          date: c?.date
            ? new Date(c?.date).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          endDate: c?.endDate
            ? new Date(c?.endDate).toLocaleDateString("en-us", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
          associationMemberName: c?.associationMemberName
            ? c?.associationMemberName + astype
            : "",
          groupUserList:
            c?.userType === "User"
              ? c?.groupAssociationUserList?.length > 0
                ? c?.groupAssociationUserList?.map((x, index) => {
                    return {
                      ...x,
                      registerType: "User",
                      id: index + 1,
                    };
                  })
                : []
              : c?.groupAssociationCompanyList?.length > 0
              ? c?.groupAssociationCompanyList?.map((x, index) => {
                  return {
                    ...x,
                    userName: x?.companyName,
                    userEmail: x?.companyEmail,
                    userPhone: x?.companyPhone,
                    registerType: "Company",
                    id: index + 1,
                  };
                })
              : [],
          Action:
            c?.registerType === "Stripe" ? (
              <Tooltip title="View Receipt">
                <Link
                  className="btn_edit"
                  to={`/association-stripe-recipe/${c?.paymentId}`}
                >
                  <IoReceiptOutline />
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title="View Receipt">
                <Link
                  className="btn_edit"
                  to={`/association-invoice-list/view/${c?.invoiceId}`}
                >
                  <IoReceiptOutline />
                </Link>
              </Tooltip>
            ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleAS = (e) => {
    setASId(e);
    setValue("associationId", e);
  };

  const PlanStatusOption = [
    { value: "Plan Expired", label: "Plan Expired" },
    { value: "Current Plan", label: "Current Plan" },
  ];

  const handlePaymentStatus = (e) => {
    setPlanStatus(e);
    setValue("planStatus", e);
  };

  const handleClearFilter = () => {
    setASId();
    setPlanStatus();
    setSelectLea([]);
    setSelectIndustry([]);
    setSelectAgency([]);
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetPaymentList)}>
                    <div className="row" style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Association</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={asList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Association"
                            filterOption={customFilter}
                            value={asList?.filter((obj) => obj.value === asId)}
                            onChange={(e) => handleAS(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Plan Status</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={PlanStatusOption}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Plan Status"
                            filterOption={customFilter}
                            value={PlanStatusOption?.filter(
                              (obj) => obj.value === planStatus
                            )}
                            onChange={(e) => handlePaymentStatus(e?.value)}
                          />
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Start Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("paymentDate", {
                              valueAsDate: true,
                            })}
                          />
                          {errors.paymentDate && (
                            <span role="alert" className="error_text">
                              {errors.paymentDate.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">End Date</label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("endDate", {
                              valueAsDate: true,
                              validate: (val) => {
                                if (
                                  watch("paymentDate")?.toString() !==
                                    "Invalid Date" &&
                                  val?.toString() === "Invalid Date"
                                ) {
                                  return "This field is required";
                                }
                                if (watch("paymentDate") > val) {
                                  return "Your end date can not greater than from start date";
                                }
                              },
                            })}
                          />
                          {errors.endDate && (
                            <span role="alert" className="error_text">
                              {errors.endDate.message}
                            </span>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">LEA Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={leaList?.filter(
                              (x) => x?.associationId === asId
                            )}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LEA Member"
                            filterOption={customFilter}
                            value={leaList?.filter((obj) =>
                              selectLea?.includes(obj.value)
                            )}
                            onChange={handleChangeLea}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">State Agencies</label>
                          <Select
                            isMulti
                            name="colors"
                            options={agencyList?.filter(
                              (x) => x?.associationId === asId
                            )}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select State Agencies"
                            filterOption={customFilter}
                            value={agencyList?.filter((obj) =>
                              selectAgency?.includes(obj.value)
                            )}
                            onChange={handleChangeAgency}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Industry Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={industryList?.filter(
                              (x) => x?.associationId === asId
                            )}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Industry Member"
                            filterOption={customFilter}
                            value={industryList?.filter((obj) =>
                              selectIndustry?.includes(obj.value)
                            )}
                            onChange={handleChangeIndustry}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <PaymentTable
                  title="Association Membership Report"
                  action={true}
                  headCells={headCellsEvent}
                  ListData={dataOfTable}
                  dropdown={false}
                  addBtn={false}
                  showUserListInTable={true}
                  fileName={"Association Membership Report"}
                  filterPage={filterPage}
                  exportDataList={true}
                  innerProduct={true}
                  subHeadCells={
                    TableHeader?.UserAssociationPaymentHistorySubHeader
                  }
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AssociationMembershipReport;
