import React, { Suspense, useState } from "react";
import "./index.scss";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import WebMenu from "../../../Shared/WebMenu";
import exploreUpcoming from "../../../assets/images/exploreUpcoming.png";
import easyRegistration from "../../../assets/images/easyRegistration.png";
import paymentProcess from "../../../assets/images/paymentProcess.png";
import personalizeSchedule from "../../../assets/images/personalizeSchedule.png";
import earnCounting from "../../../assets/images/earnCounting.png";
import leadTracking from "../../../assets/images/leadTracking.png";
import engageWith from "../../../assets/images/engageWith.png";
import WebFooter from "../../../Shared/WebFooter";
import { useForm } from "react-hook-form";
import { PostCallApi } from "../../../Action/Action";
import { ToastContainer, toast } from "react-toastify";

const logo = "/assets/images/fig_events.png";
const chooseYourBooth = "/assets/images/chooseYourBooth.png";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const bigDotVector = "/assets/icons/bigDotVector.svg";
const imageDot = "/assets/icons/imageDots.svg";
const imageLeftDots = "/assets/icons/imageLeftDots.svg";

function HowItWorks() {
  const [openCircle] = useState(false);
  const [apply, setApply] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();
  const Apply = () => {
    setApply(true);
  };
  const Close = () => {
    setApply(false);
  };
  const onSubmit = async (data) => {
    let passData = {
      ...data,
    };

    let apiUrl = {
      url: `/api/Inquiry/CreateInquiry`,
      body: passData,
    };

    const resp = await PostCallApi(apiUrl);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        Close();
        reset();
        setTimeout(() => {
          toast.success(resp?.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }, 500);
      } else {
        toast.error(resp?.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(resp?.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <WebMenu />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <section className="howItsWorksBanner">
          <img src={imageLeftDots} className="imageDot left" alt="" />
          <img src={imageDot} className="imageDot right" alt="" />
          <div className="container">
            <div className="content">
              <h1>How FIG Event Platform Works</h1>
              <p>
                Planning and managing events has never been easier with FIG! Our
                platform streamlines every aspect of your event planning
                process, from start to finish. Start by creating your event with
                a few simple clicks. Whether it&apos;s a corporate conference,
                wedding, workshop, or festival, FIG&apos;s user-friendly
                interface lets you set up your event quickly. Add essential
                details like event type, date, time, and location.
              </p>
              <Button className="detail_button" onClick={Apply}>
                Know More
              </Button>
            </div>
          </div>
        </section>
        <section className="UpcomingConferences">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={exploreUpcoming}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>01</span>
                    <h4>Conference Check</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <h1>
                    <span>Explore</span> Upcoming Conferences
                  </h1>
                  <p>
                    Discover detailed information about upcoming conferences,
                    including schedules, keynote speakers, session topics, and
                    exhibitor lists, to help you decide which events to attend.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="EasyRegistration">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <h1>
                    <span>Easy</span> Registration
                  </h1>
                  <p>
                    Seamlessly register for the conference as either an attendee
                    or an exhibitor. Our intuitive process ensures you are ready
                    to participate in no time.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={easyRegistration}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>02</span>
                    <h4>Register as Attendee or Exhibitor</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="UpcomingConferences">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={chooseYourBooth}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>03</span>
                    <h4>Exhibitor Selects Booth on Show Floor</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <h1>
                    <span>Choose</span> Your Booth
                  </h1>
                  <p>
                    Exhibitors can select their preferred booth location on the
                    interactive show floor map, ensuring prime positioning to
                    attract attendees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="EasyRegistration">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <h1>
                    <span>Secure</span> Payment Processing
                  </h1>
                  <p>
                    Complete your registration by making a secure payment
                    directly through the app. Our platform supports various
                    payment methods for your convenience.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={paymentProcess}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>04</span>
                    <h4>Make the Payment</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="UpcomingConferences">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={personalizeSchedule}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>05</span>
                    <h4>Add Events or Classes to My Schedule</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <h1>
                    <span>Personalize</span> Your Schedule
                  </h1>
                  <p>
                    Browse and add events or classes to your personal schedule.
                    Stay organized and make the most of your conference
                    experience by planning your itinerary in advance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="EasyRegistration">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <h1>
                    <span>Earn</span> Continuing Education Units
                  </h1>
                  <p>
                    Attend classes and sessions to earn CEU certificates. The
                    app automatically tracks your attendance and provides
                    certification for completed sessions.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={earnCounting}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>06</span>
                    <h4>Earn CEU Certificates for Attended Classes</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="UpcomingConferences">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={leadTracking}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>07</span>
                    <h4>Track Leads for Your Products</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <h1>
                    <span>Lead</span> Tracking
                  </h1>
                  <p>
                    Exhibitors can track and manage leads generated during the
                    conference. The app helps you capture attendee information
                    and follow up effectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="EasyRegistration">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="left">
                  <h1>
                    <span>Engage</span> with the Community
                  </h1>
                  <p>
                    Connect with other attendees and exhibitors through live
                    chat. Build a community, share insights, and engage in
                    real-time discussions to enhance your conference experience.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right">
                  <img src={bigDotVector} className="bigDotVector" alt="" />
                  <img
                    src={engageWith}
                    className="exploreUpcoming img-fluid"
                    alt=""
                  />
                  <div className="infoBox">
                    <span>08</span>
                    <h4>Build Community and Do Live Chat</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={`apply_now_back ${apply ? "active" : ""}`}>
          <button className="close" onClick={Close}>
            +
          </button>
          <div className="apply_now">
            <img
              src={logo}
              className="img-fluid mb-3"
              style={{ height: "70px" }}
            />
            <h3>Launch Your Conference: Build and Manage with Our App</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <input
                    type="text"
                    placeholder="First Name *"
                    className="form-control"
                    {...register("firstName", {
                      required: "This field is required",
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  />
                  {errors?.firstName && (
                    <span role="alert" className="error_text">
                      {errors?.firstName?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    {...register("lastName", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  />
                  {errors?.lastName && (
                    <span role="alert" className="error_text">
                      {errors?.lastName?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-4 col-lg-4 col-md-6 mb-3">
                  <input
                    type="email"
                    placeholder="Email *"
                    className="form-control"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please Enter Valid Email",
                      },
                    })}
                  />
                  {errors?.email && (
                    <span role="alert" className="error_text">
                      {errors?.email?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 mb-3">
                  <input
                    type="text"
                    placeholder="Phone *"
                    className="form-control"
                    {...register("phone", {
                      required: "This field is required",
                      pattern: {
                        value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message: "Enter valid phone number",
                      },
                    })}
                  />
                  {errors?.phone && (
                    <span role="alert" className="error_text">
                      {errors?.phone?.message}
                    </span>
                  )}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="School District Name"
                    className="form-control"
                    {...register("schoolDistrictName", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  />
                  {errors?.schoolDistrictName && (
                    <span role="alert" className="error_text">
                      {errors?.schoolDistrictName?.message}
                    </span>
                  )}
                </div>
                <div className="col-md-12 mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Type your message here..."
                    name=""
                    id=""
                    rows={5}
                    {...register("description", {
                      pattern: {
                        value: /^[^\s]+(\s+[^\s]+)*$/,
                        message: "Starting and Ending Space not allowed",
                      },
                    })}
                  ></textarea>
                  {errors?.description && (
                    <span role="alert" className="error_text">
                      {errors?.description?.message}
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <button type="submit" className="theme_btn">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <WebFooter />
      </Suspense>
    </>
  );
}
export default HowItWorks;
