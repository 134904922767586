import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi.jsx";
import { customFilter } from "../../Components/CustomFilterForReactSelect/index.jsx";
import { GetCallApi, PostCallApi } from "../../../Action/Action.jsx";
import { ToastError } from "../../Components/Toast/index.jsx";
import TableHeader from "../../../TableHeader.json";

const NewLIstTable = lazy(() =>
  import("../../Components/NewLIstTable/index.jsx")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function EventAttendedList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [eventList, setEventList] = useState([]);
  const [eventId, setEventId] = useState();
  const [filterPage, setFilterPage] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Event Attended";
    getConfList();
    // eslint-disable-next-line
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const logindetail = useSelector((state) => state.login.LoginDetails);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
    }
  };

  const GetAllEventList = async (id) => {
    setEventList([]);
    setConfId(id);
    setValue("conferenceId", id);
    if (id) {
      let res = await GetCallApi({
        url: `/api/Conference/GetAllWithoutTokenConferenceInformation?conferenceId=${id}&userId=${logindetail?.userId}&isAlphanumericOrder=true`,
      });
      if (res.status === 200 && res.data.success) {
        let comList = res?.data?.payload?.eventLists?.map((x) => {
          return {
            ...x,
            value: x?.eventId,
            label: x?.eventName,
          };
        });
        setEventList(comList);
      }
    } else {
      setValue("eventId", null);
      setEventId();
    }
  };

  const GetAttendedList = async (data) => {
    setDataOfTable([]);
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/SessionAttendance/GetEventAttendedUserDataByUserId`,
      body: {
        ...data,
        userId: logindetail?.userId,
      },
    });
    setFilterPage(!filterPage);

    setOpenCircle(false);
    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          ...c,
          eventDate: c?.eventDate
            ? new Date(c?.eventDate)?.toLocaleDateString()
            : null,
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleEvent = (e) => {
    setEventId(e);
    setValue("eventId", e);
  };

  const handleClearFilter = () => {
    setConfId();
    setEventId();
    setDataOfTable([]);
    reset();
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetAttendedList)}>
                    <div className="row" style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => GetAllEventList(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Event</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={eventList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Event"
                            filterOption={customFilter}
                            value={eventList?.filter(
                              (obj) => obj.value === eventId
                            )}
                            onChange={(e) => handleEvent(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">User Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter User Name"
                            {...register("userName", {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 200,
                                message: "Max 200 characters",
                              },
                            })}
                          />
                          {errors.userName && (
                            <span role="alert" className="error_text">
                              {errors.userName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <NewLIstTable
                  headCells={TableHeader?.EventAttendedListHeader}
                  title="Event Attended List"
                  action={false}
                  ListData={dataOfTable}
                  filterPage={filterPage}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default EventAttendedList;
