import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { customFilter } from "../Components/CustomFilterForReactSelect";
import { GetCallApi } from "../../Action/Action";
import Select from "react-select";
import { ToastError } from "../Components/Toast";
import { useReactToPrint } from "react-to-print";
import NewPaginations from "../Components/NewPaginations/Pagination";
import logo from "../../assets/images/fig_events.png";

function MYQRCodes() {
  const qrRef = useRef();
  const [qrSave] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [confDetail, setConfDetail] = useState();
  const [usDetails, setUsDetails] = useState();
  const [userId, setUserId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchField, setSearchField] = useState("");

  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  useEffect(() => {
    document.title = "FIG Events | My QR Codes";
    getConfList(LogDetail?.userId);
    GetUserQRDetail("", LogDetail?.userId);
    setUserId(LogDetail?.userId);
  }, []);

  const getConfList = async (uId) => {
    setConList([]);
    let seData = {
      url: `/api/Conference/GetApproveConferenceList?userId=${uId}`,
    };
    let res = await GetCallApi(seData);
    if (res.status === 200 && res.data.success) {
      let co = res.data.payload?.map((x) => {
        return {
          ...x,
          value: x?.conferenceId,
          label: x?.conferenceName,
        };
      });
      setConList(co);
    }
  };

  const GetUserQRDetail = async (e, uId) => {
    setConfId(e);
    setSearchField("");
    setCurrentPage(1);
    setUsDetails([]);
    if (e) {
      let conf = conList?.find((obj) => obj.value === e);
      setConfDetail(conf);
    } else {
      setConfDetail();
    }

    if (uId) {
      setOpenCircle(true);
      let res = await GetCallApi({
        url: `/api/UserMst/GetUserQRCodeString?userId=${uId}&conferenceId=${
          e ? e : ""
        }`,
      });
      setOpenCircle(false);
      if (res?.status === 200 && res?.data?.success) {
        let usd = res?.data?.payload;
        setUsDetails(usd);
      } else {
        ToastError(res?.data?.message);
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    removeAfterPrint: true,
  });

  const mainCrad = {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    overflow: "hidden",
    border: "1px solid gainsboro",
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.11)",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
  };

  const searchitem = ["userName", "role"];
  const formattedSearchResults = usDetails?.filter((user) => {
    if (searchField) {
      return searchitem?.some((newItem) => {
        return (
          user[newItem]
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(searchField.toLowerCase()) > -1
        );
      });
    } else return user;
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="my-qr-codes-page">
        <style type="text/css" media="print">
          {` 
          @media print {
            .badges {
              break-inside: avoid;
            }
            .badges:nth-child(6n) {
              page-break-after: always;
            }
          }
          `}
        </style>
        <div className="main_wrapper">
          <div className="title mb-3">My Badges </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Conference</label>
                    <Select
                      isMulti={false}
                      name="colors"
                      options={conList}
                      isClearable={true}
                      className="dropdown-single-select"
                      classNamePrefix="select"
                      placeholder="Select Conference"
                      filterOption={customFilter}
                      value={conList?.filter((obj) => obj.value === confId)}
                      onChange={(e) => GetUserQRDetail(e?.value, userId)}
                    />
                  </div>
                </div>
              </div>
              {usDetails ? (
                <div
                  className="user_detail_QR"
                  style={{ width: qrSave ? "100%" : "" }}
                >
                  <div className="d-flex">
                    <button
                      type="button"
                      className="dashboard_btn background"
                      style={{ marginRight: "auto" }}
                      onClick={() => handlePrint()}
                      // onClick={(e) => saveQR(e, LogDetail?.userId)}
                    >
                      Download QR Code
                    </button>
                    {usDetails?.length > 1 ? (
                      <div className={` search_sec ms-auto`}>
                        <div className="search_input w-100">
                          <img src={"/assets/icons/search_icon.svg"} alt="" />
                          <input
                            onChange={(e) => {
                              setSearchField(e.target.value);
                              setCurrentPage(1);
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Type your search here"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {usDetails?.length > 1 ? (
                    <div className="page_nation">
                      <div className="rowsPerPageOptions">
                        <span className="p_18_text">Items per page:</span>
                        <select
                          className="pagination_select p_16_text"
                          value={pageSize}
                          onChange={(e) => {
                            setPageSize(parseInt(e.target.value));
                            setCurrentPage(1);
                          }}
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                      <NewPaginations
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={formattedSearchResults?.length}
                        pageSize={pageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "2.5rem",
                      padding: "2.5rem",
                    }}
                    id="printablediv"
                    ref={componentRef}
                  >
                    {formattedSearchResults
                      ?.slice(
                        (currentPage - 1) * pageSize,
                        (currentPage - 1) * pageSize + pageSize
                      )
                      ?.map((x, i) => (
                        <div
                          className="badges"
                          key={i}
                          style={{ width: "465px", height: "180px",background:"linear-gradient(90.24deg, #18bad42e 2.7%, #31b6802e 54.38%, #80c2422e 98.4%)" }}
                        >
                          <Box className="qr-card" sx={mainCrad} ref={qrRef}>
                            <div className="Card_of_user">
                              <div className="info" style={{ padding: "1rem" }}>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    marginBottom: "1rem",
                                    color: "#4e4e4e",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <img
                                    src={logo}
                                    style={{ width: "80px", height: "80px" }}
                                    alt=""
                                  />
                                  <div>
                                    <div
                                      style={{
                                        fontSize: "22px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {x?.userName ? x?.userName : " "}
                                    </div>
                                    {x?.companyName && (
                                      <div>
                                        {x?.companyName ? x?.companyName : ""}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {x?.userTitle && (
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {x?.userTitle ? x?.userTitle : ""}
                                  </div>
                                )}
                                {confId ? (
                                  <div>
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {confDetail?.conferenceName}
                                    </h6>
                                    {/* <div
                                      className="con_de"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <span className="left_text">Date </span>:
                                      <span className="right_text">
                                        {" "}
                                        {confDetail?.conferenceStartDate
                                          ? new Date(
                                              confDetail?.conferenceStartDate
                                            )?.toLocaleString("en-us", {
                                              year: "numeric",
                                              month: "2-digit",
                                              day: "2-digit",
                                              hour: "2-digit",
                                              minute: "2-digit",
                                              hour12: true,
                                            })
                                          : ""}
                                      </span>
                                    </div>
                                    <div
                                      className="con_de"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <span className="left_text">
                                        {" "}
                                        Address{" "}
                                      </span>
                                      :{" "}
                                      <span className="right_text">
                                        {confDetail?.address}
                                      </span>
                                    </div> */}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                            <Box
                              className="qr_code"
                              style={{
                                padding: "1rem",
                              }}
                            >
                              <QRCode
                                size={80}
                                value={x?.qrCodeString ? x?.qrCodeString : ""}
                                viewBox="0 0 80 80"
                              />
                            </Box>
                          </Box>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MYQRCodes;
