import React, { lazy, Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Select from "react-select";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { useForm } from "react-hook-form";
import { Config } from "../../../Utils/Config";
import { VscEye } from "react-icons/vsc";
import { Link } from "react-router-dom";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsUser = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "schoolDistrict",
    label: "School District",
  },
  {
    id: "submittedDate",
    label: "Submitted Date",
  },
];

function ConferenceTestimonialVideoOrAudioReport() {
  const [openCircle, setOpenCircle] = useState(false);
  const [attendeeList, setAttendeeList] = useState([]);
  const [conList, setConList] = useState([]);
  const [selectConference, setSelectConference] = useState();
  const logindetail = useSelector((state) => state.login.LoginDetails);
  const [filterPage, setFilterPage] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    document.title = "FIG Events | Conference Testimonial Video/Audio Report";
    getConferenceList();
    // eslint-disable-next-line
  }, []);

  const GetAttList = async (data) => {
    setOpenCircle(true);
    setAttendeeList([]);

    let seData = {
      url: `/api/ConferenceTestimonialVideoOrAudio/GetConferenceTestimonialVideoOrAudioReport`,
      body: {
        ...data,
        userId: logindetail?.userId,
      },
    };
    let res = await PostCallApi(seData);
    setFilterPage(!filterPage);

    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          return {
            id: i + 1,
            ...c,
            submittedDate: c?.submittedDate
              ? new Date(c?.submittedDate)?.toLocaleDateString("en-us", {
                  year: "2-digit",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            groupUserList:
              c?.videoOrAudioList?.length > 0
                ? c?.videoOrAudioList?.map((x, index) => {
                    return {
                      ...x,
                      id: index + 1,

                      View: x?.attachmentPath ? (
                        <div className="d-flex">
                          <Link
                            target="_blank"
                            className="btn_edit d-flex align-items-center"
                            to={Config.API_HOST_URL_live + x?.attachmentPath}
                          >
                            <VscEye />
                          </Link>
                        </div>
                      ) : (
                        ""
                      ),
                    };
                  })
                : "",
            Action: "",
          };
        });
        setAttendeeList(comList);
      }
    }
  };

  const getConferenceList = async () => {
    let seData = {
      url: `/api/ConferenceTestimonialVideoOrAudio/GetTestimonialVideoOrAudioConferenceList?userId=${logindetail?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            value: x?.conferenceId,
            label: x?.conferenceName,
          };
        });
        setConList(data);
      }
    }
  };

  const handleChangeCon = (e) => {
    setSelectConference(e);
    setValue("conferenceId", e);
  };

  const handleClearFilter = () => {
    setSelectConference();
    reset();
  };

  var dtTen = new Date();
  dtTen?.setFullYear(dtTen?.getFullYear() + 10);
  dtTen = dtTen.toISOString().split("T")[0];

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="conference_testimonial_video_audio_report">
          <div className="main_wrapper">
            <form className="form_box" onSubmit={handleSubmit(GetAttList)}>
              <div className="row">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography style={{ marginLeft: "20px" }}>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === selectConference
                            )}
                            onChange={(e) => handleChangeCon(e?.value)}
                          />
                          <select
                            className="form-select d-none"
                            {...register("conferenceId", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Conference</option>
                            {conList &&
                              conList?.map((m, i) => (
                                <option value={m.value} key={i}>
                                  {m.label}
                                </option>
                              ))}
                          </select>
                          {errors.conferenceId && (
                            <span role="alert" className="error_text">
                              {errors.conferenceId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Date</label>
                          <input
                            type="date"
                            className="form-control"
                            max={dtTen}
                            {...register("submittedDate", {
                              valueAsDate: true,
                            })}
                          />
                          {errors.submittedDate && (
                            <span role="alert" className="error_text">
                              {errors.submittedDate.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className="accordian_card">
                  <div className="">
                    <NewLIstTable
                      title="Conference Testimonial Video/Audio Report"
                      headCells={headCellsUser}
                      ListData={attendeeList}
                      action={true}
                      fileName={"Conference Testimonial Video/Audio Report"}
                      exportDataList={true}
                      showUserListInTable={true}
                      innerProduct={true}
                      filterPage={filterPage}
                      subHeadCells={[
                        {
                          id: "id",
                          label: "id",
                        },
                        {
                          id: "attachmentTitle",
                          label: "Attachment Title",
                        },
                        {
                          id: "attachmentType",
                          label: "Attachment Type",
                        },
                        {
                          id: "View",
                          label: "Attachment Link",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceTestimonialVideoOrAudioReport;
