import React, { useState } from "react";
import "./index.scss";
import star_full from "../../../../assets/images/star_full.png";
import star_blank from "../../../../assets/images/star_blank.png";
import Rating from "react-rating";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import NewPaginations from "../../../Components/NewPaginations/Pagination";
import NotFound from "../../../../assets/images/Image_not_available.jpg";
import { Config } from "../../../../Utils/Config";

export default function ProductViewCard({ data }) {
  //pagination
  let Pagesize = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const searchField = "";
  const formattedSearchResults = data?.filter((user) => {
    if (searchField) {
      return user;
    } else return user;
  });

  const showReadMore = (des) => {
    Swal.fire({
      position: "top-center",
      width: "50%",
      title: "Description",
      text: des,
      confirmButtonColor: "#fff",
      confirmButtonTextColor: "#000000",
      color: "#ffffff",
      background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
    });
  };

  return (
    <>
      {formattedSearchResults &&
        formattedSearchResults
          ?.slice(
            (currentPage - 1) * Pagesize,
            (currentPage - 1) * Pagesize + Pagesize
          )
          .map((item, i) => (
            <div className={`col-md-2 col-sm-4 mb-5`} key={i}>
              <div className={`card_detail_box `}>
                <div className="img_box_text">
                  <img
                    src={
                      item?.productImage
                        ? Config.API_HOST_URL_live + item?.productImage
                        : item?.mutipleImages
                        ? item?.mutipleImages[0]
                        : NotFound
                    }
                    className="img-fluid main_card_img"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = NotFound;
                    }}
                    alt=""
                  />
                </div>
                <div className="card_details">
                  <div className={`card_title tr`}>{item?.productName}</div>
                  <div className="rating_completed d-flex justify-content-between align-items-center">
                    <div className="rating_box">
                      <Rating
                        emptySymbol={
                          <img src={star_blank} alt="rating_blank" />
                        }
                        fullSymbol={<img src={star_full} alt="rating-full" />}
                        initialRating={
                          item?.productRating ? item?.productRating : 0
                        }
                        readonly
                      />
                    </div>
                    {item.ed}
                  </div>
                  <div className="mt-3">
                    <span className="description ">
                      Short Description:{" "}
                      {item?.productDescription?.slice(0, 85)}
                    </span>
                    {item?.productDescription?.length > 85 && (
                      <span
                        className="description cr"
                        onClick={() => showReadMore(item?.des)}
                      >
                        ...read more
                      </span>
                    )}
                  </div>
                </div>
                <Link
                  to={`/exhibitor-product-detail/${item.productId}`}
                  className="theme_btn"
                  style={{ fontSize: "15px" }}
                >
                  View Product
                </Link>
              </div>
            </div>
          ))}
      {formattedSearchResults?.length === 0 ? (
        <div className="h6_text text-center">No Data Found</div>
      ) : (
        ""
      )}
      <div className="page_nation">
        <NewPaginations
          className="pagination-bar justify-content-end"
          currentPage={currentPage}
          totalCount={formattedSearchResults?.length}
          pageSize={Pagesize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
}
