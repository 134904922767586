import React, { Suspense, useState } from "react";
import Charts from "../../Components/Chart/Charts";
import "./index.scss";
//import Highcharts from "highcharts";
import { Link } from "react-router-dom";
// import Config from "../../../Utils/Config";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Box from "@mui/material/Box";
import { CiImport } from "react-icons/ci";
import { GoPlus } from "react-icons/go";
// import { DataGrid } from '@mui/x-data-grid';
import CRMHeader from "../Shared/CRMHeader";
import Highcharts from "highcharts/highmaps";
import LinearProgress from "@mui/material/LinearProgress";
import pcdimg from "../../../assets/images/pcd-img.png";
import phonepay from "../../../assets/images/phonepay.png";
import icon8 from "../../../assets/images/icon8.png";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { IoIosArrowRoundUp } from "react-icons/io";
import { IoIosArrowRoundDown } from "react-icons/io";
import { MdOutlineFilterList } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import Pagination from "@mui/material/Pagination";
import { CircularProgress } from "@mui/material";

const CD = "/assets/icons/ca.svg";
const Uk = "/assets/icons/uk.svg";
// const AS = "/assets/icons/au.svg";
const US = "/assets/icons/us.svg";
const india = "/assets/icons/in.svg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

// Tabel Data
// const columns = [
//   { field: 'id', headerName: 'ID', width: 100 },
//   { field: 'firstName', headerName: 'First name', width: 200 },
//   { field: 'lastName', headerName: 'Last name', width: 200 },
//   { field: 'update', headerName: 'Last Update', width: 200 },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     // description: 'This column has a value getter and is not sortable.',
//     // sortable: false,
//     width: 100,
//     valueGetter: (params) =>
//       `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//   },
// ];

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', update: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', update: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', update: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', update: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', update: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, update: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', update: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', update: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', update: 65 },
// ];

// End Tabel Data

function Dashboard() {
  const [startDate, setStartDate] = useState(new Date());
  // const [dataOfTable, setDataOfTable] = useState([]);

  const [progress] = React.useState(0);
  // const handleView = (e) => { };
  // useEffect(() => {
  //   const user = [
  //     1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1,
  //     2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2, 3, 4, 1, 2,
  //   ]?.map((item, i) => {
  //     return {
  //       Company: item?.eventName ? item?.eventName : "Test",

  //       ContactNumber: item?.contactNumber
  //         ? item?.contactNumber
  //         : "98547564521",
  //       email: item?.email ? item?.email : "test@gmail.com",
  //       Action: (
  //         <>
  //           <button
  //             type="button"
  //             className="btn_edit"
  //             onClick={(e) => handleView(item)}
  //           >
  //             view
  //           </button>
  //         </>
  //       ),
  //     };
  //   });
  //   setDataOfTable(user);
  //   // eslint-disable-next-line
  // }, []);

  // let highchartOptions = {

  //   chart: {
  //     type: 'column'
  //   },

  //   title: {
  //     text: '',
  //     align: 'left'
  //   },

  //   xAxis: {
  //     categories: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //     crosshair: true,
  //   },

  //   yAxis: {
  //     allowDecimals: false,
  //     min: 0,
  //     title: {
  //       text: 'Count medals'
  //     }
  //   },

  //   tooltip: {
  //     format: '<b>{key}</b><br/>{series.name}: {y}<br/>' +
  //       'Total: {point.stackTotal}'
  //   },

  //   plotOptions: {
  //     column: {
  //       stacking: 'normal'
  //     }
  //   },

  //   series: [{
  //     name: 'Direct',
  //     data: [148, 133, 124],
  //     stack: 'Europe'
  //   },
  //   {
  //     name: 'Organic search',
  //     data: [102, 98, 65],
  //     stack: 'Europe'
  //   },
  //   {
  //     name: 'Referral',
  //     data: [113, 122, 95],
  //     stack: 'North America'
  //   }]
  // };

  // const colors = Highcharts.getOptions().colors.map((c, i) =>
  //   // Start out with a darkened base color (negative brighten), and end
  //   // up with a much brighter color
  //   Highcharts.color(Highcharts.getOptions().colors[0])
  //     .brighten((i - 3) / 7)
  //     .get()
  // );

  // let highchartOptions1 = {

  //   chart: {
  //     plotBackgroundColor: null,
  //     plotBorderWidth: null,
  //     plotShadow: false,
  //     type: 'pie'
  //   },
  //   title: {
  //     text: 'Browser market shares in February, 2022',
  //     align: 'left'
  //   },
  //   tooltip: {
  //     pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  //   },
  //   accessibility: {
  //     point: {
  //       valueSuffix: '%'
  //     }
  //   },
  //   plotOptions: {
  //     pie: {
  //       allowPointSelect: true,
  //       cursor: 'pointer',
  //       colors,
  //       borderRadius: 5,
  //       dataLabels: {
  //         enabled: true,
  //         format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
  //         distance: -50,
  //         filter: {
  //           property: 'percentage',
  //           operator: '>',
  //           value: 4
  //         }
  //       }
  //     }
  //   },
  //   series: [{
  //     name: 'Share',
  //     data: [
  //       { name: 'Chrome', y: 74.03 },
  //       { name: 'Edge', y: 12.66 },
  //       { name: 'Firefox', y: 4.96 },
  //       { name: 'Safari', y: 2.49 },
  //       { name: 'Internet Explorer', y: 2.31 },
  //       { name: 'Other', y: 3.398 }
  //     ]
  //   }]
  // };

  // Map chart

  (async () => {
    const topology = await fetch(
      "https://code.highcharts.com/mapdata/custom/world.topo.json"
    ).then((response) => response.json());

    const data = await fetch(
      "https://cdn.jsdelivr.net/gh/highcharts/highcharts@v7.0.0/samples/data/world-population.json"
    ).then((response) => response.json());

    Highcharts.mapChart("highmapchartOptions", {
      chart: {
        map: topology,
      },

      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      accessibility: {
        description:
          "We see how China and India by far are the countries with the largest population.",
      },

      legend: {
        enabled: false,
      },

      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },

      mapView: {
        fitToGeometry: {
          type: "MultiPoint",
          coordinates: [
            // Alaska west
            [-164, 54],
            // Greenland north
            [-35, 84],
            // New Zealand east
            [179, -38],
            // Chile south
            [-68, -55],
          ],
        },
      },

      series: [
        {
          name: "Countries",
          color: "#E0E0E0",
          enableMouseTracking: false,
        },
        {
          type: "mapbubble",
          name: "Population 2016",
          joinBy: ["iso-a3", "code3"],
          data: data,
          minSize: 4,
          maxSize: "12%",
          tooltip: {
            pointFormat: "{point.properties.hc-a2}: {point.z} thousands",
          },
        },
      ],
    });
  })();

  // End Map

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator htmlFor the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  const options = {
    colors: ["#248e98", "#39a387"],
    labels: ["A", "B"],
    legend: {
      show: false,
      floating: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,

      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      height: undefined,
      // formatter: function (seriesName, opts) {
      //   return [
      //     seriesName + " " + opts.w.globals.series[opts.seriesIndex] + "%",
      //   ];
      // },
      position: "left",
      offsetX: 100,
      offsetY: 40,
      labels: {
        useSeriesColors: true,
      },
      markers: {
        width: 0,
        height: 0,
        strokeWidth: 0,
        strokeColor: "#fff",
        radius: 0,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    plotOptions: {
      radialBar: {
        size: undefined,
        inverseOrder: false,
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: "50%",
          background: "transparent",
          image: undefined,
          imageWidth: 150,
          imageHeight: 150,
          imageOffsetX: 0,
          imageOffsetY: 0,
          imageClipped: true,
          position: "front",
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#f2f2f2",
          strokeWidth: "97%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          show: true,
          name: {
            show: false,
            fontSize: "22px",
            fontFamily: undefined,
            color: undefined,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: undefined,
            color: undefined,
            offsetY: 6,
            formatter: function (val) {
              return val;
            },
          },
          total: {
            show: true,
            label: "",
            color: "#373d3f",
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
  };

  const series = [80, 65];

  let highchartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
      align: "left",
    },
    // colors: ["#007D88", "#25cba1", "#ebf3fe"],
    colors: ["#007D88"],
    xAxis: {
      categories: [
        "02 Jan",
        "03 Jan",
        "04 Jan",
        "05 Jan",
        "06 Jan",
        "07 Jan",
        "08 Jan",
        "09 Jan",
        "10 Jan",
        "11 Jan",
        "12 Jan",
        "13 Jan",
      ],
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      title: {
        text: "",
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [
      {
        borderRadius: 5,
        name: "norway",
        data: [
          850, 900, 2000, 500, 200, 950, 800, 1000, 2000, 2025, 1040, 3000,
        ],
        stack: "Europe",
        color: "#ebf3fe",
      },
      {
        name: "Germany",
        data: [
          1500, 2000, 1505, 1050, 800, 3800, 950, 2500, 1400, 500, 3500, 1800,
        ],
        stack: "Europe",
        color: "#25cba1",
      },
      {
        type: "column",
        name: "Unemployed",
        colorByPoint: true,
        stack: "Europe",

        data: [
          5412, 4977, 4730, 4437, 3947, 3707, 4143, 3609, 3311, 3072, 2899,
          2887,
        ],

        showInLegend: false,
        color: "#007D88",
      },
    ],
  };

  return (
    <>
      <CRMHeader />
      <Suspense fallback={renderLoader()}>
        <section className="crm-dashboard">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h1>Welcome back</h1>
                <p>
                  Create pages using a variety of features that leverage jampack
                  components
                </p>
              </div>
              <div className="col-md-4 date-picker">
                {/* <input type="Date" className="form-control" min="2024-01-01" name="EventStartdate" /> */}
                <DatePicker
                  showIcon
                  icon="fa fa-calendar"
                  renderCustomHeader={({
                    monthDate,
                    customHeaderCount,
                    decreaseMonth,
                    increaseMonth,
                  }) => (
                    <div>
                      <button
                        aria-label="Previous Month"
                        className={
                          "react-datepicker__navigation react-datepicker__navigation--previous"
                        }
                        style={
                          customHeaderCount === 1
                            ? { visibility: "hidden" }
                            : null
                        }
                        onClick={decreaseMonth}
                      >
                        <span
                          className={
                            "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                          }
                        >
                          {"<"}
                        </span>
                      </button>
                      <span className="react-datepicker__current-month">
                        {monthDate.toLocaleString("en-US", {
                          month: "long",
                          year: "numeric",
                        })}
                      </span>
                      <button
                        aria-label="Next Month"
                        className={
                          "react-datepicker__navigation react-datepicker__navigation--next"
                        }
                        style={
                          customHeaderCount === 0
                            ? { visibility: "hidden" }
                            : null
                        }
                        onClick={increaseMonth}
                      >
                        <span
                          className={
                            "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                          }
                        >
                          {">"}
                        </span>
                      </button>
                    </div>
                  )}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  monthsShown={2}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-5 dashboard-tab">
                <Tabs aria-label="Basic tabs" defaultValue={"0"}>
                  <TabList>
                    <Tab>Overview</Tab>
                    <Tab>Analytics</Tab>
                    <Tab>Operations</Tab>
                  </TabList>
                  <TabPanel value={"0"}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="first-tab">
                          <div className="first-tab-header">
                            <h6>Audience Overview</h6>
                            <Tabs aria-label="Basic tabs1" defaultValue={"0"}>
                              <TabList>
                                <Tab>All</Tab>
                                <Tab>Sessions</Tab>
                                <Tab>Source</Tab>
                                <Tab>Referrals</Tab>
                              </TabList>
                            </Tabs>
                          </div>
                          s
                          <div className="chart-block">
                            <div className="text-center">
                              <div className="d-inline-block">
                                <span className="badge-status">
                                  <span className="badge bg-primary-color badge-indicator badge-indicator-nobdr"></span>
                                  <span className="badge-label">Direct</span>
                                </span>
                              </div>
                              <div className="d-inline-block ms-3">
                                <span className="badge-status">
                                  <span className="badge bg-primary-light-2 badge-indicator badge-indicator-nobdr"></span>
                                  <span className="badge-label">
                                    Organic search
                                  </span>
                                </span>
                              </div>
                              <div className="d-inline-block ms-3">
                                <span className="badge-status">
                                  <span className="badge bg-primary-light-4 badge-indicator badge-indicator-nobdr"></span>
                                  <span className="badge-label">Referral</span>
                                </span>
                              </div>
                            </div>
                            <Charts
                              series={highchartOptions}
                              highcharts={Highcharts}
                            />
                            <div className="chart-details">
                              <ul>
                                <li>
                                  <p>Users</p>
                                  <h6>
                                    8.8k{" "}
                                    <span className="badge rounded-pill text-bg-lightsky">
                                      <IoIosArrowRoundUp /> 7.5%
                                    </span>
                                  </h6>
                                </li>
                                <li>
                                  <p>Sessions</p>
                                  <h6>
                                    18.2k{" "}
                                    <span className="badge rounded-pill text-bg-lightsky">
                                      <IoIosArrowRoundUp /> 7.2%
                                    </span>
                                  </h6>
                                </li>
                                <li>
                                  <p>Bounce rate</p>
                                  <h6>
                                    46.2%{" "}
                                    <span className="badge rounded-pill text-bg-soft">
                                      <IoIosArrowRoundDown /> 0.2%
                                    </span>
                                  </h6>
                                </li>
                                <li>
                                  <p>Session duration</p>
                                  <h6>
                                    4m 24s{" "}
                                    <span className="badge rounded-pill text-bg-lightsky">
                                      <IoIosArrowRoundUp /> 10.8%
                                    </span>
                                  </h6>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="first-tab">
                          <div className="first-tab-header mb-5">
                            <h6>Returning Customers</h6>
                          </div>
                          {/* <Charts series={highchartOptions1} highcharts={Highcharts} /> */}
                          <Chart
                            options={options}
                            series={series}
                            type="radialBar"
                            width="370"
                          />
                          <div className="mt-4 text-center">
                            <div className="mb-4">
                              <span className="d-block badge-status lh-1">
                                <span className="badge bg-primary-color badge-indicator badge-indicator-nobdr"></span>
                                <span className="badge-label d-inline-block">
                                  Organic
                                </span>
                              </span>
                              <span className="d-block text-dark fs-5 fw-medium mb-0 mt-1">
                                $243.50
                              </span>
                            </div>
                            <div>
                              <span className="badge-status lh-1">
                                <span className="badge bg-primary-light-2 badge-indicator badge-indicator-nobdr"></span>
                                <span className="badge-label">Marketing</span>
                              </span>
                              <span className="d-block text-dark fs-5 fw-medium mb-0 mt-1">
                                $1469
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-5">
                        <div className="first-tab">
                          <div className="first-tab-header">
                            <h6>Active users</h6>
                          </div>
                          <div className="row mt-5">
                            <div className="col-lg-8">
                              <div id="highmapchartOptions"></div>
                              {/* <button onClick={dataOne}>View User</button>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                                constructorType={"mapChart"}
                                updateArgs={[true, true, true]}
                                ref={chartRef}
                              /> */}
                            </div>
                            <div className="col-md-4 active-user-data">
                              <div className="d-flex align-items-center mb-3">
                                <div className="media-head me-3">
                                  <img
                                    src={US}
                                    alt="user"
                                    className="avatar-img"
                                  />
                                </div>
                                <div className="media-body">
                                  <label className="progress-label">
                                    United States
                                  </label>
                                  <LinearProgressWithLabel value={progress} />
                                </div>
                              </div>
                              <div className="d-flex align-items-center mb-3">
                                <div className="media-head me-3">
                                  <img
                                    src={india}
                                    alt="user"
                                    className="avatar-img"
                                  />
                                </div>
                                <div className="media-body">
                                  <label className="progress-label">
                                    India
                                  </label>
                                  <LinearProgressWithLabel
                                    value={progress}
                                    color="secondary"
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center mb-3">
                                <div className="media-head me-3">
                                  <img
                                    src={Uk}
                                    alt="user"
                                    className="avatar-img"
                                  />
                                </div>
                                <div className="media-body">
                                  <label className="progress-label">
                                    United Kingdom
                                  </label>
                                  <LinearProgressWithLabel
                                    value={progress}
                                    color="success"
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center mb-3">
                                <div className="media-head me-3">
                                  <img
                                    src={
                                      // AS
                                      "/assets/icons/au.svg"
                                    }
                                    alt="user"
                                    className="avatar-img"
                                  />
                                </div>
                                <div className="media-body">
                                  <label className="progress-label">
                                    Australia
                                  </label>
                                  <LinearProgressWithLabel
                                    value={progress}
                                    color="inherit"
                                  />
                                </div>
                              </div>
                              <div className="d-flex align-items-center mb-3">
                                <div className="media-head me-3">
                                  <img
                                    src={CD}
                                    alt="user"
                                    className="avatar-img"
                                  />
                                </div>
                                <div className="media-body">
                                  <label className="progress-label">
                                    Canada
                                  </label>
                                  <LinearProgressWithLabel value={progress} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-5">
                        <div className="first-tab">
                          <div className="first-tab-header">
                            <h6>New Customers</h6>
                            <div>
                              <button type="button" className="import-btn">
                                <CiImport className="me-2" />
                                import
                              </button>
                              <button type="button" className="add-btn">
                                <GoPlus className="me-2" />
                                Add New
                              </button>
                            </div>
                          </div>
                          <div className="table-details mt-3">
                            <div className="d-flex justify-content-between">
                              <div>
                                <Tabs
                                  aria-label="Basic tabs1"
                                  defaultValue={"0"}
                                >
                                  <TabList>
                                    <Tab>View All</Tab>
                                    <Tab>Monitored</Tab>
                                    <Tab>Unmonitored</Tab>
                                  </TabList>
                                </Tabs>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <input
                                  type="search"
                                  placeholder="Search"
                                  className="form-control w-auto"
                                />
                                <button type="text" className="import-btn">
                                  <MdOutlineFilterList /> Filters
                                </button>
                              </div>
                            </div>
                            {/* style={{ height: 400, width: '100%' }} */}
                            <div className="mt-3">
                              {/* <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                  pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                  },
                                }}
                                pageSizeOptions={[5, 10]}
                                checkboxSelection
                              /> */}
                              <table className="table-responisve dashboard-table">
                                <thead>
                                  <tr>
                                    <th>
                                      <span className="form-check fs-6 mb-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input check-select-all"
                                          id="customCheck1"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customCheck1"
                                        ></label>
                                      </span>
                                    </th>
                                    <th>Name</th>
                                    <th>Usage</th>
                                    <th>Last Update</th>
                                    <th>Tags</th>
                                    <th></th>
                                  </tr>
                                </thead>

                                <tbody>
                                  <tr className="selected">
                                    <td>
                                      <span className="form-check mb-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input check-select"
                                          id="chk_sel_4"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="chk_sel_4"
                                        ></label>
                                      </span>
                                    </td>
                                    <td>
                                      <div className="table-name">
                                        <div className="image">
                                          <img src={pcdimg} alt="" />
                                        </div>
                                        <div className="name-content">
                                          <h6>Tinder</h6>
                                          <Link to="">tinder.com</Link>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="progress"
                                        role="progressbar"
                                        aria-label="Basic example"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        <div className="progress-bar w-50 bg-progress-primary"></div>
                                      </div>
                                    </td>
                                    <td>17 May, 2022</td>
                                    <td>
                                      <span className="badge text-bg-secondary">
                                        Social
                                      </span>
                                    </td>
                                    <td>
                                      <div className="social-action">
                                        <Link to="">
                                          <MdEdit />
                                        </Link>
                                        <Link to="">
                                          <AiOutlineDelete />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="selected">
                                    <td>
                                      <span className="form-check mb-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input check-select"
                                          id="chk_sel_4"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="chk_sel_4"
                                        ></label>
                                      </span>
                                    </td>
                                    <td>
                                      <div className="table-name">
                                        <div className="image">
                                          <img src={phonepay} alt="" />
                                        </div>
                                        <div className="name-content">
                                          <h6>Phone Pay</h6>
                                          <Link to="">phonepay.in</Link>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="progress"
                                        role="progressbar"
                                        aria-label="Basic example"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        <div className="progress-bar w-80 bg-progress-primary"></div>
                                      </div>
                                    </td>
                                    <td>10 May, 2022</td>
                                    <td>
                                      <span className="badge text-bg-secondary me-1">
                                        Finance
                                      </span>
                                      <span className="badge text-bg-secondary">
                                        Admin
                                      </span>
                                    </td>
                                    <td>
                                      <div className="social-action">
                                        <Link to="">
                                          <MdEdit />
                                        </Link>
                                        <Link to="">
                                          <AiOutlineDelete />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="selected">
                                    <td>
                                      <span className="form-check mb-0">
                                        <input
                                          type="checkbox"
                                          className="form-check-input check-select"
                                          id="chk_sel_4"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="chk_sel_4"
                                        ></label>
                                      </span>
                                    </td>
                                    <td>
                                      <div className="table-name">
                                        <div className="image">
                                          <img src={icon8} alt="" />
                                        </div>
                                        <div className="name-content">
                                          <h6>Icons 8</h6>
                                          <Link to="">icons8.com</Link>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className="progress"
                                        role="progressbar"
                                        aria-label="Basic example"
                                        aria-valuenow="0"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        <div className="progress-bar w-45 bg-progress-green"></div>
                                      </div>
                                    </td>
                                    <td>10 May, 2022</td>
                                    <td>
                                      <span className="badge text-bg-secondary me-1">
                                        Library
                                      </span>
                                      <span className="badge text-bg-secondary me-1">
                                        Asset
                                      </span>
                                      <span className="badge text-bg-secondary">
                                        Admin
                                      </span>
                                    </td>
                                    <td>
                                      <div className="social-action">
                                        <Link to="">
                                          <MdEdit />
                                        </Link>
                                        <Link to="">
                                          <AiOutlineDelete />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="table-footer">
                                <p>1 - 6 of 6</p>
                                <Pagination
                                  count={1}
                                  className="pagination-table"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={1}>
                    <b>Second</b> tab panel
                  </TabPanel>
                  <TabPanel value={2}>
                    <b>Third</b> tab panel
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Dashboard;
