import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import Rating from "react-rating";
import star_full from "../../../../../../assets/images/star_full.png";
import star_blank from "../../../../../../assets/images/star_blank.png";
import { Config } from "../../../../../../Utils/Config";
import NotFound from "../../../../../../assets/images/Image_not_available.jpg";
import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Modal,
} from "@mui/material";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { GoHeart, GoHeartFill } from "react-icons/go";
import ReactPlayer from "react-player";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { FaCartPlus } from "react-icons/fa";
import DeletePopup from "../../../../../Components/DeletePopup";

const pro_img = "/assets/images/default_profile.jpg";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 600 : "auto",
  maxWidth: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function AssociationProductDetail() {
  const { id, pId } = useParams();
  const [prDetails, setPrDetails] = useState();
  const [openCall, setOpenCall] = React.useState(false);
  const [rate, setRate] = React.useState(5);
  const [openCircle, setOpenCircle] = useState(false);
  const [feedBackText, setFeedbackText] = useState("");
  const [multiImg, setMultiImg] = useState([]);
  const [dfImg, setDfImg] = useState();
  const [videoDetail, setVideoDetail] = useState();
  const [cartItems, setCartItems] = useState([]);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  // const { cartItems } = useSelector((state) => state.Cart);
  // const diapatch = useDispatch();
  useEffect(() => {
    document.title = "FIG Events | Product";
    if (pId) {
      GetProductDetails(pId);
      GetCartData();
    }
    // eslint-disable-next-line
  }, [pId, deleteInfo]);

  const GetCartData = async () => {
    let seData = {
      url: `/api/AssociationProductAddToCart/GetAllAddToCartProductByUserId?userId=${LogDetail?.userId}&associationId=${id}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let ud = res.data.payload;
        setCartItems(ud);
      }
    }
  };

  const GetProductDetails = async (id) => {
    let seData = {
      url: `/api/AssociationProduct/GetAssociationProductByAssociationProductId?associationProductId=${id}&userId=${LogDetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setVideoDetail({ ...res.data.payload?.productVideoLink[0], no: 1 });
        setPrDetails({
          ...res.data.payload,
          cardImg: res.data.payload?.multipleImages[0]?.imagePath,
          quentity: 1,
          productVideoLink: res.data.payload?.productVideoLink?.filter(
            (x) => x?.videoUrl !== ""
          ),
        });
        setDfImg(res.data.payload?.multipleImages[0]?.imagePath);
        setMultiImg(res.data.payload?.multipleImages);
      }
    }
  };

  const userInLike = async (p) => {
    if (LogDetail?.userId) {
      let seData = {
        url: "/api/AssociationProduct/CreateAssociationProductUserInteraction",
        body: {
          AssociationProductId: p,
          userId: LogDetail?.userId,
          status: "Like",
        },
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          GetProductDetails(pId);
        }
      }
    } else {
      swalWithBootstrapButtons.fire({
        position: "center",
        // icon: "success",
        text: `Please Login after you can like product`,
        // text: des,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const userRating = async () => {
    if (LogDetail?.userId) {
      let seData = {
        url: "/api/AssociationProduct/CreateAssociationProductRating",
        body: {
          associationProductId: pId,
          userId: LogDetail?.userId,
          rating: rate,
          Feedback: feedBackText,
        },
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          GetProductDetails(pId);
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          handleCloseCall();
        }
      }
    } else {
      swalWithBootstrapButtons.fire({
        position: "center",
        // icon: "success",
        text: `Please Login after you can rate product`,
        // text: des,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
      });
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn_success",
      cancelButton: "btn btn_success",
    },
    buttonsStyling: true,
  });
  const handleOpenCall = (e) => {
    setRate(e);
    setOpenCall(true);
  };

  const handleCloseCall = () => {
    setOpenCall(false);
    setRate();
    setFeedbackText("");
  };

  const addToCartHandler = async (data) => {
    setOpenCircle(true);
    let res = await PostCallApi({
      url: `/api/AssociationProductAddToCart/AddUpdateAddToCartProduct`,
      body: {
        associationProductId: data?.associationProductId,
        quntity: 1,
        associationId: id,
        userId: LogDetail?.userId,
        createdBy: LogDetail?.userId,
      },
    });
    setOpenCircle(false);

    if (res?.status === 200 && res?.data?.success) {
      GetCartData();
      toast.success(res?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      toast.error(res?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    // diapatch(addToCart(data));
    // diapatch(calculatePrice());
    // toast.success("Added to cart", {
    //   position: "top-right",
    //   autoClose: 1500,
    //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="association_product_detail">
        <div className="container-fluid">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="d-flex justify-content-between">
                  <div className="title">Product Details</div>
                  <div>
                    <Badge
                      badgeContent={cartItems?.length}
                      color="warning"
                      // sx={{ color: "#FFF", backgroundColor: "#f79155" }}
                    >
                      <Link
                        to={`/association/store/user/${id}/product-cart`}
                        className="dashboard_btn background ms-2"
                      >
                        <FaCartPlus fontSize={24} />
                      </Link>
                    </Badge>
                  </div>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                <div className="left_side">
                  <img
                    // src={
                    //   dfImg
                    //     ? `${Config?.API_HOST_URL_live}${dfImg}`
                    //     : prDetails?.productImage
                    //     ? `${Config?.API_HOST_URL_live}${prDetails?.productImage}`
                    //     : card_default_image
                    // }
                    src={
                      dfImg
                        ? dfImg
                        : prDetails?.productImage
                        ? prDetails?.productImage
                        : NotFound
                    }
                    className="img-fluid"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NotFound;
                    }}
                    alt=""
                  />
                </div>
                <div className="row mt-4 thumbnail">
                  {prDetails?.productImage ? (
                    <div className="col-sm-3 col-md-2 col-xl-2 col-6 mb-4">
                      <button
                        onClick={() => setDfImg()}
                        style={{ border: "none", background: "transparent" }}
                      >
                        <img
                          // src={
                          //   prDetails?.productImage
                          //     ? `${Config?.API_HOST_URL_live}${prDetails?.productImage}`
                          //     : card_default_image
                          // }
                          src={
                            prDetails?.productImage
                              ? prDetails?.productImage
                              : NotFound
                          }
                          className="img-fluid"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = NotFound;
                          }}
                          alt=""
                          // style={{ height: "100px", width: "100px" }}
                        />
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {multiImg?.map((allimg, ind) => {
                    return (
                      <div
                        className="col-sm-3 col-md-2 col-xl-2 col-6 mb-4"
                        key={ind}
                      >
                        <button
                          onClick={() => setDfImg(allimg?.imagePath)}
                          style={{
                            border: "none",
                            background: "transparent",
                            boxShadow:
                              "0px 4px 20px 0px rgba(238, 238, 238, 0.5)",
                          }}
                        >
                          <img
                            // src={
                            //   allimg?.images
                            //     ? `${Config?.API_HOST_URL_live}${allimg?.images}`
                            //     : card_default_image
                            // }
                            src={
                              allimg?.imagePath ? allimg?.imagePath : NotFound
                            }
                            className="img-fluid"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NotFound;
                            }}
                            alt=""
                            // style={{ height: "100px", width: "100px" }}
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                <div className="product_detail_content">
                  <div className="hed">
                    <div className="info">
                      <h3>{prDetails?.productName}</h3>
                      <div className="rating_box">
                        <Rating
                          emptySymbol={
                            <img src={star_blank} alt="rating_blank" />
                          }
                          fullSymbol={<img src={star_full} alt="rating-full" />}
                          initialRating={prDetails?.overAllRatings}
                          onClick={(e) => handleOpenCall(e)}
                        />
                      </div>
                      <p className="price_tag">
                        {prDetails?.isDiscountedProduct
                          ? "Discounted Price"
                          : "Price"}
                        :{" "}
                        {prDetails?.isDiscountedProduct ? (
                          <span className="me-2">
                            -
                            {prDetails?.discountedType === "Percentage"
                              ? prDetails?.discountedValue + "%"
                              : " " + prDetails?.discountedValue}{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        <b>
                          {prDetails?.isDiscountedProduct
                            ? prDetails?.price?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })
                            : prDetails?.totalProductPrice?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                        </b>
                        {prDetails?.isDiscountedProduct ? (
                          <div
                            className="description f-18-400 "
                            // style={{
                            //   textDecoration: "line-through",
                            // }}
                          >
                            Price:{" "}
                            <span
                              style={{
                                textDecoration: "line-through",
                              }}
                            >
                              {prDetails?.totalProductPrice?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {prDetails?.price ? (
                          <>
                            {prDetails?.price?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </>
                        ) : (
                          ""
                        )} */}
                      </p>
                    </div>
                    <div className="hover_btn me-2">
                      <Button onClick={() => userInLike(pId)}>
                        {prDetails?.isLikeProduct ? (
                          <GoHeartFill color="#fff" fontSize={25} />
                        ) : (
                          <GoHeart color="#fff" fontSize={25} />
                        )}

                        {/* <img src={heart} className="img-fluid" alt="" /> */}
                      </Button>
                      <Button
                        onClick={() => addToCartHandler(prDetails)}
                        className="orange-btn p-0"
                        style={{ fontSize: "15px" }}
                      >
                        <FaCartPlus fontSize={24} />
                      </Button>
                      {/* <Link>
                        <img src={bookmarkWhite} className="img-fluid" alt="" />
                      </Link> */}
                    </div>

                    <div className="action_btn">
                      <Link
                        to={-1}
                        className="btn_edit d-flex align-items-center text-decoration-none"
                      >
                        Back
                      </Link>
                    </div>
                  </div>
                  <div className="desc">
                    <div className="document-file gap-5 mt-3">
                      <div className="row">
                        <p className="doc_title">
                          Association Name :{" "}
                          <span style={{ fontWeight: "400" }}></span>
                          {prDetails?.associationName}
                        </p>
                        <p className="doc_title">
                          Store Name :{" "}
                          <span style={{ fontWeight: "400" }}></span>
                          {prDetails?.storeName}
                        </p>
                        <p className="doc_title">
                          Brand Name :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.brandName}
                          </span>
                        </p>
                        <p className="doc_title">
                          Category Name :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.categoryName}
                          </span>
                        </p>
                        <p className="doc_title">
                          SubCategory Name :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.subCategoryName}
                          </span>
                        </p>

                        <p className="doc_title">
                          Manufacturer :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.manufacturer}
                          </span>
                        </p>
                        <p className="doc_title">
                          gtin :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.gtinNo}
                          </span>
                        </p>
                        {/* {prDetails?.categoryName === "Food Products" ? (
                          <>
                            <p className="doc_title">
                              upc :{" "}
                              <span style={{ fontWeight: "400" }}>
                                {prDetails?.upcNo}
                              </span>
                            </p>
                            <p className="doc_long_title">
                              ingredients :{" "}
                              <span style={{ fontWeight: "400" }}>
                                {prDetails?.ingredients}
                              </span>
                            </p>
                          </>
                        ) : (
                          ""
                        )} */}
                        <p className="doc_long_title">
                          Short Description :{" "}
                          <span style={{ fontWeight: "400" }}>
                            {prDetails?.shortDescription}
                          </span>
                        </p>
                        <p className="multi_document">Documents : </p>
                        {prDetails?.documents?.length > 0 ? (
                          prDetails?.documents?.map((docPth, no) => (
                            <div className="document-box mb-2" key={no}>
                              <div className="text_data">
                                {docPth.fileName
                                  ? docPth.fileName
                                  : "Product PDF"}
                              </div>
                              <Link
                                to={`${Config.API_HOST_URL_live}/${docPth?.documentPath}`}
                                target="_blank"
                                className="btn_dlt"
                              >
                                <img
                                  src={"/assets/icons/lucide_view.svg"}
                                  alt=""
                                />
                              </Link>
                            </div>
                          ))
                        ) : (
                          <div className="document-box mb-2 justify-content-start p-2">
                            No Document
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="contact_info">
                    <h2 className="title">Contact Information</h2>
                    <div className="info">
                      <h4>
                        {prDetails?.exhibitorName
                          ? prDetails?.exhibitorName
                          : "John Kartar"}
                      </h4>
                      <p>
                        <img src={mail} alt="" />
                        <Link to={`mailto:${prDetails?.email}`}>
                          {prDetails?.email
                            ? prDetails?.email
                            : "swans@swansolutionsllc.com"}
                        </Link>
                      </p>
                      <p>
                        <img src={phone} alt="" />
                        {prDetails?.phone ? prDetails?.phone : "(800) 817-1408"}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="col-md-12 benefits mb-3">
                <div className="new_card">
                  <h3 className="card_title m-0">Benefits</h3>
                  <ul className="row">
                    {prDetails?.benefits?.map((item, i) => (
                      <li className="col-lg-3 col-md-6" key={i}>
                        <p>
                          {i + 1}. {item?.benefitTitle}:
                        </p>
                        {item?.benefitDescription}
                      </li>
                    ))}
                    {prDetails?.benefits?.length === 0 ? (
                      <div className="f-20-600 my-4">No Data Found</div>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
              {prDetails?.productVideoLink?.length > 0 ? (
                <div className="col-md-12">
                  <div className="new_card ">
                    <h3 className="card_title">Video</h3>
                    <div className="d-flex gap-3">
                      <div style={{ height: "500px" }} className="w-100">
                        <ReactPlayer
                          url={videoDetail?.videoUrl}
                          className="react-player"
                          volume={1}
                          width="100%"
                          height="100%"
                          controls
                        />
                      </div>
                      <div className="w-100 video_list">
                        {prDetails?.productVideoLink?.map((y, i) => (
                          <div
                            className="showmb"
                            onClick={() => setVideoDetail({ ...y, no: i + 1 })}
                            key={i}
                          >
                            <div className={`name `}>
                              <div
                                className={` ${
                                  i + 1 === videoDetail?.no ? "Active" : ""
                                }`}
                              >
                                {i + 1}. {y?.fileName}
                              </div>
                              <div>
                                <MdOutlineOndemandVideo
                                  fontSize={24}
                                  color={` ${
                                    y?.speakerDocumentId ===
                                    videoDetail?.speakerDocumentId
                                      ? "#80c242"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-12 review-section mt-5">
                <div className="new_card">
                  <h3 className="card_title m-0">Reviews</h3>
                  <div className="row">
                    {prDetails?.userProductRatingList?.map((x, i) => (
                      <div className="col-lg-6 review-border" key={i}>
                        <div className="review-block">
                          <div className="reviewcontent-block">
                            <div className="image">
                              {x?.uesrImage ? (
                                <img
                                  src={`${Config.API_HOST_URL_live}${x?.uesrImage}`}
                                  width={40}
                                  height={40}
                                  style={{
                                    borderRadius: "20px",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = pro_img;
                                  }}
                                  alt="review-img"
                                />
                              ) : (
                                <img
                                  src={pro_img}
                                  alt="review-img"
                                  style={{
                                    borderRadius: "20px",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                />
                              )}
                            </div>
                            <div className="profile-name">
                              <h6>{x?.userName}</h6>
                              <div className="rating_box">
                                <Rating
                                  emptySymbol={
                                    <img src={star_blank} alt="rating_blank" />
                                  }
                                  fullSymbol={
                                    <img src={star_full} alt="rating-full" />
                                  }
                                  initialRating={x?.rating}
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                          {LogDetail?.userId === x?.userId ? (
                            <div>
                              <p>
                                {x?.date
                                  ? new Date(x?.date)?.toLocaleDateString(
                                      "en-us",
                                      {
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit",
                                      }
                                    )
                                  : ""}
                              </p>
                              <div className="d-flex">
                                <DeletePopup
                                  title={"Delete Product Rating"}
                                  text={"your rating"}
                                  url={`/api/AssociationProduct/DeleteAssociationProductRatingById?associationProductRatingById=${x?.associationProductRatingId}`}
                                  callBack={true}
                                  callBackFunction={() => GetProductDetails(id)}
                                />
                              </div>
                            </div>
                          ) : (
                            <p>
                              {x?.date
                                ? new Date(x?.date)?.toLocaleDateString(
                                    "en-us",
                                    {
                                      year: "2-digit",
                                      month: "2-digit",
                                      day: "2-digit",
                                    }
                                  )
                                : ""}
                            </p>
                          )}
                        </div>
                        <p style={{ whiteSpace: "pre-line" }}>{x?.feedback}</p>
                      </div>
                    ))}
                    {prDetails?.userProductRatingList?.length === 0 ? (
                      <div className="f-20-600 my-4">No Data Found</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={openCall}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="toggle_delete_data"
      >
        <Box sx={style1} className="delete_data_box">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openCircle}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="popup_delete_detail_box">
            <h6>Write Feedback</h6>
            <div className="popup_delete_detail">
              <div className="text-center">
                <Rating
                  emptySymbol={<img src={star_blank} alt="" />}
                  fullSymbol={<img src={star_full} alt="" />}
                  initialRating={rate}
                  className="rate_star_mb"
                  onClick={(e) => setRate(e)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">FeedBack</label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder="Enter Feedback"
                  value={feedBackText}
                  onChange={(e) => setFeedbackText(e.target.value)}
                ></textarea>
              </div>
              <button
                type="button"
                className="detail_button me-3"
                onClick={userRating}
                disabled={openCircle}
              >
                Submit
              </button>
              <button
                type="button"
                className="back_button "
                onClick={handleCloseCall}
              >
                Cancel
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AssociationProductDetail;
