import React, { lazy, Suspense, useEffect, useState } from "react";
import "./index.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import Select from "react-select";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { SwalMessage, ToastError } from "../../Components/Toast";
import ImgPopUp from "../../Components/ImgPopUp";
import { Config } from "../../../Utils/Config";
import NotFound from "../../../assets/images/Image_not_available.jpg";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsCompanyReg = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "boothNumber",
    label: "Booth No",
  },
  {
    id: "logo",
    label: "Logo",
  },
  {
    id: "companyName",
    label: "Company Name",
  },
  {
    id: "contactName",
    label: "Contact Name",
  },
  {
    id: "companyEmail",
    label: "Company Email",
  },
  {
    id: "companyPhone",
    label: "Company Phone",
  },
  {
    id: "isApprove",
    label: "Booth Status",
  },
  // {
  //   id: "payment",
  //   label: "Payment Amount",
  // },

  {
    id: "paymentStatus",
    label: "Payment Status",
  },
];
function ConferenceBoothsReport() {
  const [conferenceList, setConferenceList] = useState([]);
  const [dataOfTable1, setDataOfTable1] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [confId, setConfId] = useState("");
  const [filterPage, setFilterPage] = useState(false);

  useEffect(() => {
    document.title = "FIG Events | Conference Booths Report";
    GetAllConferenceList();

    // eslint-disable-next-line
  }, []);

  const logindetail = useSelector((state) => state.login.LoginDetails);

  const GetAllConferenceList = async () => {
    let res = await GetCallApi({
      url: `/api/Conference/GetApproveConferenceList?userId=${logindetail?.userId}`,
    });
    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((x) => {
        return {
          ...x,
          label: x?.conferenceName,
          value: x?.conferenceId,
        };
      });
      setConferenceList(comList);
    }
  };

  const GetRegistrationList = async (conId) => {
    setDataOfTable1([]);

    if (conId) {
      setOpenCircle(true);
      let SedData = {
        url: `/api/Reports/ConferenceBoothsReport`,
        body: {
          userId: logindetail?.userId,
          conferenceId: conId,
        },
      };
      const res = await PostCallApi(SedData);
      setOpenCircle(false);
      setFilterPage(!filterPage);

      if (res?.status === 200 && res.data.success) {
        let comList = res?.data?.payload?.map((c, i) => {
          let com = c?.comapanyDetails?.length > 0 ? c?.comapanyDetails[0] : "";
          return {
            ...c,
            ...com,
            isApprove: com?.companyName ? com?.isApprove : "",
            paymentStatus: com?.companyName ? c?.paymentStatus : "",
            logo: com?.companyLogo ? (
              <ImgPopUp
                img={`${Config.API_HOST_URL_live}${com?.companyLogo}`}
                addClass={"h_fix"}
                hide_icon={true}
              />
            ) : (
              <ImgPopUp img={NotFound} addClass={"h_fix"} hide_icon={true} />
            ),
            id: i + 1,
          };
        });
        setDataOfTable1(comList);
      } else {
        ToastError(res.data.message);
      }
    } else {
      let ms = "Please Select Conference";

      SwalMessage(ms);
    }
  };

  const handleChangeValue = (conId) => {
    setConfId(conId);
  };

  const handleClearFilter = () => {
    setConfId();
    setDataOfTable1([]);
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form>
                    <div className="row mb-3" style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>

                          <Select
                            isMulti={false}
                            name="colors"
                            options={conferenceList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conferenceList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => handleChangeValue(e?.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            onClick={() => GetRegistrationList(confId)}
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card mb-4">
                <NewLIstTable
                  headCells={headCellsCompanyReg}
                  title="Conference Booths Report"
                  action={false}
                  ListData={dataOfTable1}
                  dropdown={false}
                  addBtn={false}
                  fileName={"Conference Booths Report"}
                  exportDataList={true}
                  filterPage={filterPage}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceBoothsReport;
