import React, { lazy, Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { GridExpandMoreIcon } from "@mui/x-data-grid";
import Select from "react-select";
import { GetAllWithoutTokenConferenceListDropDown } from "../../../Action/ConferenceListApi";
import { customFilter } from "../../Components/CustomFilterForReactSelect";
import { GetCallApi, PostCallApi } from "../../../Action/Action";
import { ToastError } from "../../Components/Toast";
import TableHeader from "../../../TableHeader.json";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function SessionAttendedList() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [filterPage, setFilterPage] = useState(false);

  const [conList, setConList] = useState([]);
  const [confId, setConfId] = useState();
  const [sessionList, setSessionList] = useState([]);
  const [sessionId, setSessionId] = useState();

  useEffect(() => {
    document.title = "FIG Events | Session Attended";
    getConfList();
    // eslint-disable-next-line
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const logindetail = useSelector((state) => state.login.LoginDetails);

  const getConfList = async () => {
    let res = await GetAllWithoutTokenConferenceListDropDown();
    if (res?.length > 0) {
      setConList(res);
      // handleConfereceSelect(res[0]?.value);
    }
  };

  const GetAllSessionList = async (id) => {
    setSessionList([]);
    setConfId(id);
    setValue("conferenceId", id);
    if (id) {
      let res = await GetCallApi({
        url: `/api/Conference/GetAllWithoutTokenConferenceInformation?conferenceId=${id}&userId=${logindetail?.userId}&isAlphanumericOrder=true`,
      });
      if (res.status === 200 && res.data.success) {
        let comList = res?.data?.payload?.classLists?.map((x) => {
          return {
            ...x,
            value: x?.classId,
            label: x?.classTitle,
          };
        });
        setSessionList(comList);
      }
    } else {
      setSessionId();
      setValue("sessionId", null);
    }
  };

  const GetAttendedList = async (data) => {
    setOpenCircle(true);
    setDataOfTable([]);
    let res = await PostCallApi({
      url: `/api/SessionAttendance/GetSessionAttendedUserDataByUserId`,
      body: {
        ...data,
        userId: logindetail?.userId,
      },
    });
    setFilterPage(!filterPage);

    setOpenCircle(false);
    if (res.status === 200 && res.data.success) {
      let comList = res?.data?.payload?.map((c, i) => {
        return {
          id: i + 1,
          conferenceId: c?.conferenceId,
          conferenceName: c?.conferenceName,
          sessionAttendanceId: c?.sessionAttendanceId,
          sessionDate: c?.sessionDate
            ? new Date(c?.sessionDate)?.toLocaleDateString()
            : null,
          sessionId: c?.sessionId,
          sessionName: c?.sessionName,
          speakerName: c?.speakerName,
          userId: c?.userId,
          userName: c?.userName,
          Action: (
            <>
              {c?.ceu > 0 ? (
                <Link
                  className="btn_edit text h-auto px-3 py-1"
                  to={`/certificate/${c?.userId}/${c?.sessionId}/${c?.typeOfUser}`}
                >
                  View Certificate
                </Link>
              ) : (
                ""
              )}
            </>
          ),
        };
      });
      setDataOfTable(comList);
    } else {
      ToastError(res?.data?.message);
    }
  };

  const handleSession = (e) => {
    setSessionId(e);
    setValue("sessionId", e);
  };

  const handleClearFilter = () => {
    setConfId();
    setSessionId();
    setDataOfTable([]);
    reset();
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="company_user_dashboard py-4">
          <div className="main_wrapper">
            <div className="row">
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography style={{ marginLeft: "20px" }}>
                    <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                    <span className="filter_by">Filter By</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form onSubmit={handleSubmit(GetAttendedList)}>
                    <div className="row" style={{ marginLeft: "20px" }}>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === confId
                            )}
                            onChange={(e) => GetAllSessionList(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Session</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={sessionList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Session"
                            filterOption={customFilter}
                            value={sessionList?.filter(
                              (obj) => obj.value === sessionId
                            )}
                            onChange={(e) => handleSession(e?.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">User Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter User Name"
                            {...register("userName", {
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                              maxLength: {
                                value: 200,
                                message: "Max 200 characters",
                              },
                            })}
                          />
                          {errors.userName && (
                            <span role="alert" className="error_text">
                              {errors.userName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 d-flex justify-content-start mt-auto">
                        <div className="form-group">
                          <Button
                            className="detail_button ms-3"
                            type="submit"
                            disabled={openCircle ? true : false}
                          >
                            {openCircle ? (
                              <CircularProgress
                                color="inherit"
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            ) : (
                              "Apply Filter"
                            )}
                          </Button>
                          <Button
                            className={`back_button ms-3 ${
                              window.innerWidth > 400 ? "" : "mt-3"
                            }`}
                            onClick={() => handleClearFilter()}
                          >
                            Clear Filter
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <div className="col-md-12 accordian_card p-0 mb-4">
                <NewLIstTable
                  headCells={TableHeader?.SessionAttendedListHeader}
                  title="Session Attended List"
                  action={true}
                  actionFirst={false}
                  ListData={dataOfTable}
                  filterPage={filterPage}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SessionAttendedList;
