import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { AttendeeRegistrationConference } from "../../../../../../reducers/Common";
import ReactQuill from "react-quill";

import { useNavigate, useParams } from "react-router-dom";

function EditAssociationUserPayment(props) {
  const { invoiceId } = useParams();
  const { companyData } = props;
  const [subtotal, setSubTotal] = useState("");
  const { setValue } = useForm();

  const LogDetails = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let ATRC = useSelector((state) => state.Common?.ATRC);
  let dispatch = useDispatch();
  let nav = useNavigate();
  useEffect(() => {
    if (companyData) {
      setValue("amount", companyData.subtotal);
      setSubTotal(companyData?.subtotal);
    }
    // eslint-disable-next-line
  }, [companyData]);

  const RequestInvoice = async (data) => {
    let seData = {
      url: `/api/AssociationPayment/AddAssociationInvoice`,
      body: {
        ...data,
        associationInvoiceId: invoiceId,
        createdBy: LogDetails?.userId,
        totalAmount: subtotal,
        addressLine1: data?.address1,
        zipCode: data?.zip,
        invoiceInstructions: data?.editorValue,
        paymentMode: "Invoice",
        paymentStatus: "Success",
        invoiceStatus: "Approve",
        // discountValue: discountValue?.discountValue,
        discountValue: companyData?.associationUserList
          ?.filter((curr) => curr?.discount > 0)
          ?.reduce((acc, curr) => acc + curr?.discount, 0),
        associationName: companyData?.name,
        associationPrice: subtotal.toString(),
        associationMembershipsTypesId:
          companyData?.associationMembershipsTypesId,
        associationId: companyData?.associationId,
        associationMemberId: companyData?.associationMemberId,
        companyName: companyData?.companyName,
        paymentAddress:
          ATRC?.paymentRemittanceAddress +
          " \r\n" +
          ATRC?.paymentCity +
          " " +
          ATRC?.paymentState +
          " " +
          ATRC?.paymentZip,
        registerType: "User",
      },
      headers: headers,
    };
    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res?.data?.success) {
        await SendUser({
          paymentMode: "Invoice",
          associationInvoiceId: res?.data?.payload?.associationInvoiceId,
          associationPaymentId: res?.data?.payload?.associationPaymentId,
        });
        dispatch(AttendeeRegistrationConference());
        nav(`/association-invoice-list/view/${invoiceId}`, { replace: true });
      }
    }
  };

  const ShowInvoice = ({ RequestInvoice, invoiceId }) => {
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm();
    const [editorValue, setEditorValue] = useState("");
    const [derror, setDerror] = useState();

    useEffect(() => {
      if (invoiceId) {
        getInvoiceData(invoiceId);
      }
      // eslint-disable-next-line
    }, [invoiceId]);
    const getInvoiceData = async (ind) => {
      let res = await GetCallApi({
        url: `/api/AssociationPayment/GetAssociationInvoiceDataByAssociationInvoiceId?associationInvoiceId=${ind}`,
      });
      if (res.status === 200) {
        if (res.data.success) {
          let useDe = res.data.payload;
          setValue("name", useDe?.name);
          setValue("email", useDe?.email);
          setValue("address1", useDe?.addressLine1);
          setValue("purchaseOrderNumber", useDe?.purchaseOrderNumber);
          setValue("city", useDe?.city);
          setValue("State", useDe?.state);
          setValue("zip", useDe?.zipCode);
          setEditorValue(useDe?.invoiceInstructions);
        }
      }
    };

    const handleEditotValue = (value) => {
      if (value?.length > 1000) {
        setDerror({ ed: "Max 1000 character" });
      } else {
        setDerror({ ed: "" });
        setEditorValue(value);
      }
    };

    return (
      <form
        onSubmit={handleSubmit(async (data) => {
          RequestInvoice({ ...data, editorValue });
        })}
      >
        <div className="row">
          <div className="col-md-6">
            <label className="form-label">
              Email Invoice To
              <span className="red-color"> *</span>
            </label>

            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email"
                {...register("email", {
                  required: "This field is require",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please Enter Valid Email",
                  },
                })}
              />
              {errors?.email && (
                <span className="error_text">{errors.email.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <div>
                <label className="form-label">Address</label>
              </div>
              <label className="form-label">
                Name<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                {...register("name", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 500,
                    message: "You can enter max 500 charactor",
                  },
                })}
              />
              {errors?.name && (
                <span className="error_text">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3 mt-auto">
            <div className="form-group  mb-3">
              <label className="form-label">
                Address Line 1<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Address Line 1"
                {...register("address1", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.address1 && (
                <span className="error_text">{errors.address1.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-6 mt-auto">
            <div className="form-group mb-3">
              <label className="form-label">PO Number </label>
              <input
                type="text"
                placeholder="Enter PO Number"
                className="form-control"
                {...register("purchaseOrderNumber", {
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                })}
              />
              {errors?.purchaseOrderNumber && (
                <span className="error_text">
                  {errors.purchaseOrderNumber.message}
                </span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                City<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter City"
                {...register("city", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.city && (
                <span className="error_text">{errors.city.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                State<span className="red-color"> *</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter State"
                {...register("State", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 100,
                    message: "You can enter max 100 charactor",
                  },
                })}
              />
              {errors?.State && (
                <span className="error_text">{errors.State.message}</span>
              )}
            </div>
          </div>{" "}
          <div className="col-md-3">
            <div className="form-group mb-3">
              <label className="form-label">
                Zip Code
                <span className="red-color"> *</span>
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                placeholder="Enter Zip Code"
                {...register("zip", {
                  required: "This field is require",
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/,
                    message: "Starting and Ending Space not allowed",
                  },
                  maxLength: {
                    value: 10,
                    message: "You can enter max 10 charactor",
                  },
                })}
              />
              {errors?.zip && (
                <span className="error_text">{errors.zip.message}</span>
              )}
            </div>
          </div>
          <div className="col-md-12 ">
            <label className="form-label">Invoice Introduction</label>
            <ReactQuill
              value={editorValue}
              onChange={(value) => handleEditotValue(value)}
              // style={{ height: "200px", overflow: "auto" }}
              className="ql_show"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline", "strike"], // toggled buttons
                  ["blockquote", "code-block"],

                  [{ header: 1 }, { header: 2 }], // custom button values
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ script: "sub" }, { script: "super" }], // superscript/subscript
                  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                  [{ direction: "rtl" }], // text direction

                  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],

                  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                  [{ font: [] }],
                  [{ align: [] }],

                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              theme="snow"
            />
            {derror?.ed && (
              <span role="alert" className="d-block error_text">
                {derror.ed}
              </span>
            )}
          </div>
          <div className="col-md-12 ">
            <Button className="detail_button mt-3" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  };
  const SendUser = async (data) => {
    let Dt =
        companyData?.associationUserList
          ?.filter((curr) => curr?.discount > 0)
          ?.reduce((acc, curr) => acc + curr?.discount, 0) ?? 0,
      ta = subtotal + Dt;

    let user = companyData?.associationUserList?.map((x) => {
      let cost = x?.totalAmount ?? 0,
        myDISAmount = cost > 0 ? Dt / (ta / cost) : 0,
        FA = cost > 0 ? cost - myDISAmount : 0;
      return {
        ...x,
        totalAmount: FA ? parseFloat(FA?.toFixed(2)) : FA,
        discount: myDISAmount
          ? parseFloat(myDISAmount?.toFixed(2))
          : myDISAmount,
      };
    });
    let send = { ...data, ...companyData, associationUserList: user };

    await PostCallApi({
      url: `/api/AssociationPayment/GroupAssociationUserAllocation`,
      body: send,
    });
  };

  return (
    <>
      <div className="">
        <div className="title attend-title mb-3">Payment Details</div>
        <div className="col-md-6">
          <div className="conference_details border-bottom-0 mb-4">
            <ul>
              <li>
                Fees
                <span>
                  {companyData?.subtotal?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </li>{" "}
            </ul>
            <div className="subtotal">
              <p>
                Total{" "}
                <span>
                  {subtotal
                    ? subtotal?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                    : "0.00"}
                </span>
              </p>
            </div>
          </div>
        </div>
        <ShowInvoice RequestInvoice={RequestInvoice} invoiceId={invoiceId} />
      </div>
    </>
  );
}

export default EditAssociationUserPayment;
