import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../Utils/AxiosConfig";
import "./index.scss";
import {
  Button,
  Box,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import CropModel from "../../../Components/CropModel";
import { VscAdd, VscTrash } from "react-icons/vsc";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
// import eye from "../../../../assets/icons/eye.svg";
// import eyeOff from "../../../../assets/icons/eye-off.svg";
import { MdDelete } from "react-icons/md";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function SpeakerCreate() {
  const [derror, setDerror] = useState();
  // const [displayDoc, setDisplayDoc] = useState([]);
  const [displayDoc1, setDisplayDoc1] = useState([]);
  // const [videos, setVideos] = useState([]);
  const { id } = useParams();
  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [matD, setMatD] = React.useState();
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const {
    fields: fieldArray1,
    append: AppendArray1,
    remove: RemoveArray1,
  } = useFieldArray({
    control,
    name: "speakerSpeechesList",
  });

  const {
    fields: fieldArray2,
    append: AppendArray2,
    remove: RemoveArray2,
  } = useFieldArray({
    control,
    name: "speakerVideoLinks",
  });

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const logDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (id) {
      getSpakerD(id);
    } else {
      AppendArray1({ title: "", description: "" });
      AppendArray2({ fileName: "", videoLink: "" });
    }
    // eslint-disable-next-line
  }, [id]);
  const getSpakerD = async (id) => {
    // setDisplayDoc([]);
    setDisplayDoc1([]);
    // setVideos([]);
    let seData = {
      url: `/api/Speaker/GetSpeakerDataBySpeakerId?speakerId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let useDe = res.data.payload;
        let resv = await GetCallApi({
          url: `/api/UserMst/EditUser?userId=${id}`,
        });

        if (resv.status === 200) {
          if (resv.data.success) {
            let useDe1 = resv.data.payload;
            setValue("lastName", useDe1?.lastName);
          }
        }
        setValue("SpeakerId", useDe?.speakerId);
        setValue("SpeakerName", useDe?.speakerName);
        setValue("ribbon", useDe?.ribbon);
        setValue("SpeakerImage", useDe?.speakerImage);
        setValue("CoverImage1", useDe?.speakerImage);
        setValue("SpeakerDesignations", useDe?.speakerDesignations);
        setValue("SpeakerIntroductions", useDe?.speakerIntroductions);
        setValue("Address", useDe?.address);
        setValue("Phone", useDe?.phone);
        setValue("Email", useDe?.email);
        setValue("Website", useDe?.website);
        // setValue("VideoClipUrl", useDe?.videoClipUrl);
        // setValue("VideoClipUrl1", useDe?.videoClipUrl);
        setValue("ClassworkDocument1", res.data.payload.documets);
        setValue("brounch", res.data.payload.broachers);
        setValue("Biography", useDe.biography);
        if (useDe?.speeches?.length > 0) {
          setValue("speakerSpeechesList", useDe?.speeches);
        } else {
          AppendArray1({ title: "", description: "" });
        }
        if (useDe?.videos?.length > 0) {
          setValue(
            "speakerVideoLinks",
            useDe?.videos?.map((x) => {
              return {
                ...x,
                videoLink: x?.attachmentPath,
              };
            })
          );
        } else {
          AppendArray2({ fileName: "", videoLink: "" });
        }
        // setValue("AttachementType", useDe?.attachmentType);
        // AttachmentTypeSet(useDe?.attachmentType);
        // if (res.data.payload.documents?.length > 0) {
        //   setDisplayDoc(res.data.payload.documents);
        // }
        if (res.data.payload.broachers?.length > 0) {
          setDisplayDoc1(res.data.payload.broachers);
        }
        // if (res.data.payload.videos?.length > 0) {
        //   setVideos(res.data.payload.videos);
        // }
      }
    }
  };
  const onSubmit = async (data) => {
    let Speaker = {
      ...data,
      CreatedBy: logDetail?.userId,
      // ClassworkDocument: data?.ClassworkDocument1[0],
    };
    let Speakerdata = new FormData();
    if (data?.ClassworkDocument1?.length > 0) {
      data?.ClassworkDocument1?.map((d) => {
        return Speakerdata.append("SpeakerDocuments", d);
      });
    } else {
      Speakerdata.append("SpeakerDocuments", []);
    }
    if (data?.VideoClipUrl?.length > 0) {
      data?.VideoClipUrl?.map((v) => {
        return Speakerdata.append("VideoClipUrl", v);
      });
    } else {
      Speakerdata.append("VideoClipUrl", []);
    }
    if (data?.brounch?.length > 0) {
      data?.brounch?.map((d) => {
        return Speakerdata.append("SpeakerBrochers", d);
      });
    } else {
      Speakerdata.append("SpeakerBrochers", []);
    }
    [
      "CoverImage1",
      "VideoClipUrl1",
      "SpeakerBrochures",
      "SpeakerClassworkDocumentation",
      "ClassworkDocument1",
      "brounch",
    ].forEach((e) => delete Speaker[e]);
    for (var key in Speaker) {
      Speakerdata.append(key, Speaker[key]);
    }
    let seData = {
      url: "/api/Speaker/CreateSpeaker",
      body: Speakerdata,
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let spId = res.data.payload?.speakerId;
        let upsl = {
          url: "/api/Speaker/AddSpeakerSpeechesAndVideoLink",
          body: { ...Speaker, speakerId: spId },
        };
        let res12 = await PostCallApi(upsl);

        let seData1 = {
          url: "/api/UserMst/CreateUser",
          body: {
            UserId: spId,
            FirstName: Speaker?.SpeakerName,
            LastName: Speaker?.lastName,
            Password: Speaker?.Password,
            Email: Speaker?.Email,
            Phone: Speaker?.Phone,
            RoleName: "",
            RoleId: "",
            UserType: "Speaker",
            CreatedBy: logDetail?.userId,
            ProfileImage: "",
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        };
        let resv = await PostCallApi(seData1);
        setOpenCircle(false);
        if (resv.status === 200) {
          if (resv.data.success) {
            if (Speaker?.SpeakerImage) {
              let spd = {
                url: `/api/UserMst/SaveUserProfileImage`,
                body: {
                  userId: spId,
                  ProfileImage: Speaker?.SpeakerImage,
                  CreatedBy: logDetail?.userId,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                  Accept: "*/*",
                },
              };
              let resv12 = await PostCallApi(spd);
              // remove warning
              res12 = resv12;
              resv12 = res12;
            }
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            reset();
            nav("/speakers", { replace: true });
            setDerror({ err: "" });
          } else {
            setDerror({ err: res.data?.message });
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else {
        setDerror({ err: res.data?.message });
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("SpeakerImage", url);
        setValue("CoverImage1", url.name);
        // handleProImg(url);
        // setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  // crop code end

  const handleDoCMultiFile = async (e) => {
    if (e.target.files.length) {
      if (e.target.files.length > 5) {
        setValue(`brounch`, "");
        setValue(`SpeakerBrochures`, "");
        setDerror({ bro: `You can upload maximum 5 brochures` });
      } else if (e.target.files?.length > 0) {
        setDerror({ bro: `` });
        let multifile = [];
        let base64_namemulti = [];
        for (var i = 0; i < e.target.files?.length; i++) {
          let multi = e.target.files[i];
          multifile.push(multi?.name);
          base64_namemulti.push(multi);
        }
        // let file = e.target.files[0];
        setValue(`brounch`, base64_namemulti);
        setValue(`SpeakerBrochures`, multifile);
        e.target.value = null;
      }
    }
  };

  // const handleDoCMultiFile1 = async (e) => {
  //   if (e.target.files.length) {
  //     if (e.target.files.length > 5) {
  //       setValue(`ClassworkDocument1`, "");
  //       setValue(`SpeakerClassworkDocumentation`, "");
  //       setDerror({ doc: `You can upload maximum 5 documents` });
  //     } else if (e.target.files?.length > 0) {
  //       setDerror({ doc: `` });
  //       let multifile = [];
  //       let base64_namemulti = [];
  //       for (var i = 0; i < e.target.files?.length; i++) {
  //         let multi = e.target.files[i];
  //         multifile.push(multi?.name);
  //         base64_namemulti.push(multi);
  //       }

  //       setValue(`ClassworkDocument1`, base64_namemulti);
  //       setValue(`SpeakerClassworkDocumentation`, multifile);
  //     }
  //   }
  // };

  // const handleVideo = (e) => {
  //   if (e.target.files.length > 5) {
  //     setValue(`VideoClipUrl1`, "");
  //     setValue(`VideoClipUrl`, "");
  //     setDerror({ vid: `You can upload maximum 5 videos` });
  //   } else if (e.target.files?.length > 0) {
  //     setDerror({ vid: `` });
  //     let multifile = [];
  //     let base64_namemulti = [];
  //     for (var i = 0; i < e.target.files?.length; i++) {
  //       let multi = e.target.files[i];
  //       if (multi.size > 5242880) {
  //         setDerror({ vid: ` Select less than 5mb ${multi?.name} video` });
  //       } else {
  //         multifile.push(multi?.name);
  //         base64_namemulti.push(multi);
  //       }
  //     }

  //     setValue(`VideoClipUrl`, base64_namemulti);
  //     setValue(`VideoClipUrl1`, multifile);
  //   } else {
  //   }
  // };

  const handleOpen = (d) => {
    setMatD(d);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMatD();
  };

  const deleteDocument = () => {
    if (matD?.speakerDocumentId) {
      setOpenCircle(true);
      Axios.delete(
        `/api/Speaker/DeleteSpeakerDocumentById?speakerDocumnetId=${matD?.speakerDocumentId}`,
        {
          headers,
        }
      )
        .then((responce) => {
          if (responce.status === 200) {
            if (responce.data.success) {
              setOpenCircle(false);
              handleClose();
              toast.success(responce.data.message, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setDerror({ err: "" });
              getSpakerD(id);
            } else {
              setOpenCircle(false);
              setDerror({ err: responce.data?.message });
            }
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <>
      <section className="create_speaker">
        <div className="main_wrapper">
          <div className="title mb-3">{id ? "Edit" : "Create"} Speaker </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Speaker Name
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter speaker name"
                        {...register("SpeakerName", {
                          required: "This field is required",
                          maxLength: {
                            value: 50,
                            message: "Max 50 characters",
                          },
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.SpeakerName && (
                        <span role="alert" className="error_text">
                          {errors.SpeakerName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Last Name
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter last name"
                        {...register("lastName", {
                          required: "This field is required",
                          maxLength: {
                            value: 50,
                            message: "Max 50 characters",
                          },
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.lastName && (
                        <span role="alert" className="error_text">
                          {errors.lastName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Image
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`CoverImage1`, {
                            // required: {
                            //   value: true,
                            //   message: "This field is required",
                            // },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handleFile(e)}
                          />
                        </IconButton>
                      </div>
                      {/* crop code start */}
                      {src && (
                        <CropModel
                          src={src}
                          filename={filename}
                          setCroppedImageUrl={setCroppedImageUrlCallback}
                          openCropModal={openCropModal}
                          setCropModal={setCropModal}
                        />
                      )}
                      {/* crop code end */}
                      {errors.CoverImage1 && (
                        <span role="alert" className="error_text">
                          {errors.CoverImage1.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Speaker Designations
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter speaker designations"
                        {...register("SpeakerDesignations", {
                          // required: "This field is required",
                          // pattern: {
                          //   value: /^[^\s]+(\s+[^\s]+)*$/,
                          //   message: "Starting and Ending Space not allowed",
                          // },
                        })}
                      />
                      {errors.SpeakerDesignations && (
                        <span role="alert" className="error_text">
                          {errors.SpeakerDesignations.message}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Speaker Introductions
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter speaker introductions"
                        {...register("SpeakerIntroductions", {
                          // required: "This field is required",
                          // pattern: {
                          //   value: /^[^\s]+(\s+[^\s]+)*$/,
                          //   message: "Starting and Ending Space not allowed",
                          // },
                        })}
                      />
                      {errors.SpeakerIntroductions && (
                        <span role="alert" className="error_text">
                          {errors.SpeakerIntroductions.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Speaker Email
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter speaker email"
                        {...register("Email", {
                          required: "This field is required",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Please Enter Valid Email",
                          },
                        })}
                      />
                      {errors.Email && (
                        <span role="alert" className="error_text">
                          {errors.Email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {!id ? (
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">
                          Password
                          <span className="red-color"> *</span>
                        </label>
                        <div className="password">
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control"
                            placeholder="Enter speaker password"
                            {...register("Password", {
                              required: "This field is required",
                            })}
                          />
                          <span
                            className="eye"
                            onClick={() => setPasswordShown(!passwordShown)}
                          >
                            <img
                              src={
                                passwordShown === true
                                  ? "/assets/icons/eye.svg"
                                  : "/assets/icons/eye-off.svg"
                              }
                              alt="eye"
                            />
                          </span>
                        </div>
                        {errors.Password && (
                          <span role="alert" className="error_text">
                            {errors.Password.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Speaker Phone
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter speaker phone"
                        {...register("Phone", {
                          required: "This field is required",
                          pattern: {
                            value:
                              /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                            message: "Enter valid phone number",
                          },
                        })}
                      />
                      {errors.Phone && (
                        <span role="alert" className="error_text">
                          {errors.Phone.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Speaker Address
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter speaker address"
                        {...register("Address", {
                          // required: "This field is required",
                          // pattern: {
                          //   value: /^[^\s]+(\s+[^\s]+)*$/,
                          //   message: "Starting and Ending Space not allowed",
                          // },
                        })}
                      />
                      {errors.Address && (
                        <span role="alert" className="error_text">
                          {errors.Address.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Website</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter speaker website"
                        {...register("Website", {
                          // required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.Website && (
                        <span role="alert" className="error_text">
                          {errors.Website.message}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">Biography</label>
                      <textarea
                        type="text"
                        rows={5}
                        className="form-control"
                        placeholder="Enter speaker Biography"
                        {...register("Biography", {
                          // required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.Biography && (
                        <span role="alert" className="error_text">
                          {errors.Biography.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Video Clip</label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`VideoClipUrl1`, {
                            // required: {
                            //   value: true,
                            //   message: "This field is required",
                            // },
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose Video
                          <input
                            id="upload"
                            type="file"
                            accept="video/*"
                            className="form-control"
                            hidden
                            multiple
                            max={5}
                            onChange={(e) => handleVideo(e)}
                          />
                        </IconButton>
                      </div>
                      {errors.VideoClipUrl1 && (
                        <span role="alert" className="error_text">
                          {errors.VideoClipUrl1.message}
                        </span>
                      )}
                      {derror?.vid && (
                        <span role="alert" className="error_text">
                          {derror?.vid}
                        </span>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                  <div className="form-group">
                    <label className="form-label">Attachment Type</label>
                    <select
                      className="form-select"
                      {...register("AttachementType", {
                        required: "This field is required",
                        onChange: (e) => AttachmentTypeSet(e.target.value),
                      })}
                    >
                      <option value="classWork">Classwork Documentation</option>
                      <option value="brochures">Brochures</option>
                    </select>
                    {errors.AttachmentType && (
                      <span role="alert" className="error_text">
                        {errors.AttachmentType.message}
                      </span>
                    )}
                  </div>
                </div> */}
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Classwork Documentation
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="Upload classwork documentation"
                          {...register(`SpeakerClassworkDocumentation`, {
                            // required: {
                            //   value: true,
                            //   message: "This field is required",
                            // },
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            multiple
                            max={5}
                            className="form-control"
                            hidden
                            onChange={(e) => handleDoCMultiFile1(e)}
                          />
                        </IconButton>
                      </div>

                      {errors.SpeakerClassworkDocumentation && (
                        <span role="alert" className="error_text">
                          {errors.SpeakerClassworkDocumentation.message}
                        </span>
                      )}
                      {derror?.doc && (
                        <span role="alert" className="error_text">
                          {derror?.doc}
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">
                        Ribbon
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Ribbon"
                        {...register("ribbon", {
                          // required: "This field is required",
                          maxLength: {
                            value: 45,
                            message: "Max 45 characters",
                          },
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                        })}
                      />
                      {errors.ribbon && (
                        <span role="alert" className="error_text">
                          {errors.ribbon.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Brochures</label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="Upload brochures"
                          {...register(`SpeakerBrochures`, {
                            // required: {
                            //   value: true,
                            //   message: "This field is required",
                            // },
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            multiple
                            max={5}
                            className="form-control"
                            hidden
                            onChange={(e) => handleDoCMultiFile(e)}
                          />
                        </IconButton>
                      </div>

                      {errors.SpeakerBrochures && (
                        <span role="alert" className="error_text">
                          {errors.SpeakerBrochures.message}
                        </span>
                      )}
                      {derror?.bro && (
                        <span role="alert" className="error_text">
                          {derror?.bro}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mat_box col-md-12">
                    {/* {videos?.length > 0 ? <div>Videos: </div> : ""}
                    <div className="row">
                      {videos?.map((v, no) => (
                        <div className="col-md-3" key={no}>
                          <div className="mat_box_main mb-3">
                            <div className="num me-3">{no + 1}</div>
                            <div className="text_data">
                              {v?.fileName ? v?.fileName : "Material"}
                            </div>
                            <button
                              className="btn_dlt "
                              type="button"
                              onClick={() => handleOpen(v)}
                            >
                              <VscTrash className="icon" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div> */}
                    {/* {displayDoc?.length > 0 ? <div>Documents: </div> : ""}
                    <div className="row">
                      {displayDoc?.map((mat, no) => (
                        <div className="col-md-3" key={no}>
                          <div className="mat_box_main mb-3">
                            <div className="num me-3">{no + 1}</div>
                            <div className="text_data">
                              {mat?.fileName ? mat?.fileName : "Material"}
                            </div>
                            <button
                              className="btn_dlt "
                              type="button"
                              onClick={() => handleOpen(mat)}
                            >
                              <VscTrash className="icon" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div> */}
                    {displayDoc1?.length > 0 ? <div>Brochures: </div> : ""}
                    <div className="row">
                      {displayDoc1?.map((mat, no) => (
                        <div className="col-md-3" key={no}>
                          <div className="mat_box_main mb-3">
                            <div className="num me-3">{no + 1}</div>
                            <div className="text_data">
                              {mat?.fileName ? mat?.fileName : "Material"}
                            </div>
                            <button
                              className="btn_dlt "
                              type="button"
                              onClick={() => handleOpen(mat)}
                            >
                              <VscTrash className="icon" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-4 my-auto mb-3">
                    <label className="form-label">
                      Speeches/Topics Title
                      {/* <span className="red-color"> *</span> */}
                    </label>
                  </div>
                  <div className="col-sm-10 col-md-6 my-auto mb-3">
                    <label className="form-label">
                      Speeches/Topics Description
                      {/* <span className="red-color"> *</span> */}
                    </label>
                  </div>
                  <div className="col-sm-2 col-md-2 mb-3 text-end">
                    <Button
                      className="detail_button"
                      onClick={() =>
                        AppendArray1({
                          title: "",
                          description: "",
                        })
                      }
                    >
                      <VscAdd className="me-2" />
                      Add
                    </Button>
                  </div>
                  {fieldArray1?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="col-md-4 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          {...register(`speakerSpeechesList.${index}.title`, {
                            // required: "this field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 250,
                              message: "Max 250 characters",
                            },
                          })}
                        />
                        {errors.speakerSpeechesList?.[index]?.title && (
                          <span role="alert" className="error_text">
                            {errors.speakerSpeechesList?.[index]?.title.message}
                          </span>
                        )}
                      </div>
                      <div
                        className={` mb-3 ${
                          index === 0 ? "col-md-8" : "col-sm-10 col-md-7"
                        }`}
                      >
                        <textarea
                          type="text"
                          rows={3}
                          className="form-control"
                          placeholder="Enter Description"
                          {...register(
                            `speakerSpeechesList.${index}.description`,
                            {
                              // required: "this field is required",
                              pattern: {
                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                message:
                                  "Starting and Ending Space not allowed",
                              },
                            }
                          )}
                        />
                        {errors.speakerSpeechesList?.[index]?.description && (
                          <span role="alert" className="error_text">
                            {
                              errors.speakerSpeechesList?.[index]?.description
                                .message
                            }
                          </span>
                        )}
                      </div>
                      {index === 0 ? (
                        ""
                      ) : (
                        <div className="col-sm-2 col-md-1 text-end">
                          <div>
                            <Button
                              className="delet_Icon_button"
                              onClick={() => RemoveArray1(index)}
                            >
                              {" "}
                              <MdDelete fontSize={24} />
                            </Button>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                  <div className="col-md-4 my-auto mb-3">
                    <label className="form-label">
                      Video Title
                      {/* <span className="red-color"> *</span> */}
                    </label>
                  </div>
                  <div className="col-sm-10 col-md-6 my-auto mb-3">
                    <label className="form-label">
                      Video Url
                      {/* <span className="red-color"> *</span> */}
                    </label>
                  </div>
                  <div className="col-sm-2 col-md-2 mb-3 text-end">
                    <Button
                      className="detail_button"
                      onClick={() =>
                        AppendArray2({
                          fileName: "",
                          videoLink: "",
                        })
                      }
                    >
                      <VscAdd className="me-2" />
                      Add
                    </Button>
                  </div>
                  {fieldArray2?.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="col-md-4 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Title"
                          {...register(`speakerVideoLinks.${index}.fileName`, {
                            // required: "this field is required",
                            pattern: {
                              value: /^[^\s]+(\s+[^\s]+)*$/,
                              message: "Starting and Ending Space not allowed",
                            },
                            maxLength: {
                              value: 250,
                              message: "Max 250 characters",
                            },
                          })}
                        />
                        {errors.speakerVideoLinks?.[index]?.fileName && (
                          <span role="alert" className="error_text">
                            {
                              errors.speakerVideoLinks?.[index]?.fileName
                                .message
                            }
                          </span>
                        )}
                      </div>
                      <div
                        className={` mb-3 ${
                          index === 0 ? "col-md-8" : "col-sm-10 col-md-7"
                        }`}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter URL"
                          {...register(`speakerVideoLinks.${index}.videoLink`, {
                            // required: "this field is required",
                            pattern: {
                              value:
                                // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g,
                              message: "Plese Enter Valid Url",
                            },
                          })}
                        />
                        {errors.speakerVideoLinks?.[index]?.videoLink && (
                          <span role="alert" className="error_text">
                            {
                              errors.speakerVideoLinks?.[index]?.videoLink
                                .message
                            }
                          </span>
                        )}
                      </div>
                      {index === 0 ? (
                        ""
                      ) : (
                        <div className="col-sm-2 col-md-1 text-end">
                          <div>
                            <Button
                              className="delet_Icon_button"
                              onClick={() => RemoveArray2(index)}
                            >
                              {" "}
                              <MdDelete fontSize={24} />
                            </Button>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button
                        className="back_button"
                        onClick={() => nav("/speakers", { replace: true })}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="detail_button ms-3"
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </Button>
                      {derror?.err && (
                        <span role="alert" className="d-block error_text">
                          {derror.err}
                        </span>
                      )}
                    </div>
                  </div>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Delete Class Document</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {matD?.fileName}?</span>
                  </p>
                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={deleteDocument}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default SpeakerCreate;
