import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import "./index.scss";
import { CircularProgress, IconButton } from "@mui/material";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import CropFix from "../../../../Components/CropModel/CropFix";
import Select from "react-select";
import { customFilter } from "../../../../Components/CustomFilterForReactSelect";

function EventPromotionCreate() {
  const { id } = useParams();
  const [openCircle, setOpenCircle] = React.useState(false);
  const [typeValue, setTypeValue] = React.useState();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  let nav = useNavigate();

  const onSubmit = async (data) => {
    let seData = {
      url: "/api/EventPromotion/CreateEventPromotion",
      body: { ...data, createdBy: LogDetail?.userId },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res.status === 200) {
      if (res.data.success) {
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        reset();
        nav(-1);
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    if (id) {
      getGlData();
    }
    // eslint-disable-next-line
  }, [id]);
  const getGlData = async () => {
    let seData = {
      url: `/api/EventPromotion/GetEventPromotionByEventPromotionId?eventPromotionId=${id}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let gl = res.data.payload;
        setValue("sequenceNo", gl.sequenceNo);
        setValue("title", gl.title);
        setValue("type", gl.type);
        setTypeValue(gl.type);
        setValue("description", gl.description);
        setValue("eventPromotionId", id);
        // setValue("EventImage", gl.image);
        setValue("CoverImage1", gl.eventImageUrl);
      }
    }
  };

  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("EventImage", url);
        setValue("CoverImage1", url.name);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  const TypeOption = [
    {
      label: "App",
      value: "App",
    },
    {
      label: "Web",
      value: "Web",
    },
  ];

  const handleTypeValue = (e) => {
    setTypeValue(e);
    setValue("type", e);
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <section className="create_event_class">
        <div className="main_wrapper">
          <div className="title mb-3">
            {id ? "Edit" : "Create"} Event Promotion
          </div>
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Sequence No
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Sequence No"
                        {...register("sequenceNo", {
                          required: "This field is required",
                        })}
                      />
                      {errors.sequenceNo && (
                        <span role="alert" className="error_text">
                          {errors.sequenceNo.message}
                        </span>
                      )}
                    </div>
                  </div>{" "}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Title
                        <span className="red-color"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title"
                        {...register("title", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 250,
                            message: "Max character 250 allowed",
                          },
                        })}
                      />
                      {errors.title && (
                        <span role="alert" className="error_text">
                          {errors.title.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Image
                        <span className="red-color"> *</span>
                      </label>
                      <div className="d-flex upload_btn_sec">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          placeholder="No file choose"
                          {...register(`CoverImage1`, {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            // onChange: (e) => handleFile(e),
                          })}
                        />
                        <IconButton
                          component="label"
                          className="upload_btn m-0"
                        >
                          Choose File
                          <input
                            id="upload"
                            type="file"
                            accept="image/*"
                            className="form-control"
                            hidden
                            onChange={(e) => handleFile(e)}
                          />
                        </IconButton>
                      </div>
                      {/* crop code start */}
                      {src && (
                        <CropFix
                          src={src}
                          filename={filename}
                          setCroppedImageUrl={setCroppedImageUrlCallback}
                          openCropModal={openCropModal}
                          setCropModal={setCropModal}
                          fixSize={{ fixheight: 500, fixwidth: 700 }}
                        />
                      )}
                      {/* crop code end */}
                      {errors.CoverImage1 && (
                        <span role="alert" className="error_text">
                          {errors.CoverImage1.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        Type
                        <span className="red-color"> *</span>
                      </label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={TypeOption}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Duration"
                        filterOption={customFilter}
                        value={TypeOption?.filter(
                          (obj) => obj.value === typeValue
                        )}
                        onChange={(e) => handleTypeValue(e?.value)}
                      />
                      <select
                        type="text"
                        className="form-select d-none"
                        {...register("type", {
                          required: "This field is required",
                        })}
                      >
                        <option value="">Select Type</option>
                        <option value="App">App</option>
                        <option value="Web">Web</option>
                      </select>
                      {errors.type && (
                        <span role="alert" className="error_text">
                          {errors.type.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Description
                        {/* <span className="red-color"> *</span> */}
                      </label>
                      <textarea
                        rows={3}
                        className="form-control"
                        placeholder="Enter Description"
                        {...register("description", {
                          // required: "This field is required",
                          pattern: {
                            value: /^[^\s]+(\s+[^\s]+)*$/,
                            message: "Starting and Ending Space not allowed",
                          },
                          maxLength: {
                            value: 500,
                            message: "Max character 500 allowed",
                          },
                        })}
                      />
                      {errors.description && (
                        <span role="alert" className="error_text">
                          {errors.description.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end mt-3">
                    <div className="form-group">
                      <Button className="back_button" onClick={() => nav(-1)}>
                        Back
                      </Button>
                      <Button
                        className="detail_button ms-3"
                        type="submit"
                        disabled={openCircle}
                      >
                        {openCircle ? (
                          <CircularProgress
                            color="inherit"
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        ) : id ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EventPromotionCreate;
