import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Smallpop from "../../Modules/Components/Smallpop";
import { Link, useNavigate } from "react-router-dom";

function AssignHeadMenu() {
  const [options, setOptions] = useState();
  const [showMenu, setShowMenu] = useState([]);
  const [mobileShowMenu, setMobileShowMenu] = useState(false);
  const [mobileShowMenuList, setMobileShowMenuList] = useState([]);
  const [mobileShowMenuId, setMobileShowMenuId] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuList = useSelector((state) => state.headerMenu?.LoginHeadList);

  const MenuName = useSelector((state) => state.setting?.MenuName);
  let navigate = useNavigate();
  const showSub = (event, item) => {
    if (item?.subMenu?.length === 0) {
      navigate(item?.url, { replace: true });
      setAnchorEl();
      setOptions();
    } else if (item?.subMenu !== undefined) {
      setAnchorEl(event?.currentTarget);
      let subMenu = item?.subMenu;
      // subMenu = subMenu?.sort((a, b) => {
      //   const nameA = a.sequenceNumber;
      //   const nameB = b.sequenceNumber;
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }
      //   return 0;
      // });
      setOptions(subMenu);
    } else {
      setAnchorEl();
      setOptions();
    }
  };
  const mobileAShowSub = (event, item) => {
    if (item?.subMenu?.length === 0) {
      navigate(item?.url, { replace: true });
      setMobileShowMenuList();
    } else if (item?.subMenu !== undefined) {
      let subMenu = item?.subMenu;
      setMobileShowMenuId(item?.menuId);
      setMobileShowMenuList(subMenu);
    }
    setMobileShowMenu((prevState) => !prevState);
  };
  useEffect(() => {
    if (MenuList?.length > 0) {
      let sendM = [...MenuList];
      setTimeout(() => {
        sendM = sendM.sort((a, b) => {
          const nameA = a.sequenceNumber;
          const nameB = b.sequenceNumber;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setShowMenu(sendM);
      }, 1000);
    }
    // eslint-disable-next-line
  }, [MenuList]);
  const handleName = (name) => {
    navigate(name, { replace: true });
  };
  return (
    <>
      {showMenu?.map((item, i) => (
        <React.Fragment key={i}>
          {item?.subMenu?.length > 0 ? (
            <li
              className={`hover_link ${
                item.subMenu?.find((data) => data?.menuName === MenuName)
                  ? `linear ${MenuName} `
                  : ""
              }`}
              key={i}
              onClick={(e) =>
                window.innerWidth > 768
                  ? showSub(e, item)
                  : mobileAShowSub(e, item)
              }
            >
              <Link className={`hover dropdown`}>
                {item.menuName}
                {item?.subMenu?.length > 0 ? (
                  <ArrowDropDownRoundedIcon></ArrowDropDownRoundedIcon>
                ) : (
                  ""
                )}
              </Link>
              {mobileShowMenu && mobileShowMenuId === item?.menuId ? (
                <div className="mobileSubmenu">
                  {mobileShowMenuList?.map((submenu, i) => (
                    <div
                      className="submenu"
                      key={i}
                      onClick={() => handleName(submenu?.url)}
                    >
                      <div className="icon">
                        {submenu.menuIcon ? (
                          <i className={`fas fa-${submenu.menuIcon}`}></i>
                        ) : (
                          <i className="fas fa-bars"></i>
                        )}
                      </div>
                      <div className="bac">
                        {submenu?.menuName}
                        <span>{submenu?.menuDescription}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </li>
          ) : (
            <li
              className={`hover_link ${
                item.subMenu?.find((data) => data?.menuName === MenuName)
                  ? `linear ${MenuName} `
                  : ""
              }`}
              key={i}
              onClick={(e) => showSub(e, item)}
            >
              <Link className="hover">{item.menuName}</Link>
            </li>
          )}
        </React.Fragment>
      ))}
      <Smallpop
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        optionsMenu={options}
        megamenu={options?.length >= 4 ? "megamenu" : ""}
        className={`Menu_icon_settings`}
      />
    </>
  );
}

export default React.memo(AssignHeadMenu);
