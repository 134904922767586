import React, { lazy, Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GetCallApi } from "../../../Action/Action";
import Select from "react-select";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { customFilter } from "../../Components/CustomFilterForReactSelect";

const NewLIstTable = lazy(() => import("../../Components/NewLIstTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const headCellsUser = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "firstName",
    label: "First Name",
  },
  {
    id: "lastName",
    label: "Last Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "submittedDate",
    label: "Submitted Date",
  },
];

function ConferenceFeedbackReport() {
  const [openCircle, setOpenCircle] = useState(false);
  const [attendeeList, setAttendeeList] = useState([]);
  const [conList, setConList] = useState([]);
  const [selectConference, setSelectConference] = useState();
  const [filterPage, setFilterPage] = useState(false);

  // const [leaList, setLeaList] = useState([]);
  // const [selectLea, setSelectLea] = useState([]);
  // const [agencyList, setAgencyList] = useState([]);
  // const [selectAgency, setSelectAgency] = useState([]);
  // const [industryList, setIndustryList] = useState([]);
  // const [selectIndustry, setSelectIndustry] = useState([]);

  const logindetail = useSelector((state) => state.login.LoginDetails);

  useEffect(() => {
    document.title = "FIG Events | Conference Feedback Report";

    getConferenceList();
    // getLEAList();
    // getAgencyist();
    // getIndustryist();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    GetAttList();
    // eslint-disable-next-line
  }, [selectConference]);

  const GetAttList = async () => {
    setAttendeeList([]);
    // let member = [];
    // if (selectLea?.length > 0) {
    //   member = [...selectLea];
    // }
    // if (selectAgency?.length > 0) {
    //   member = [...member, ...selectAgency];
    // }
    // if (selectIndustry?.length > 0) {
    //   member = [...member, ...selectIndustry];
    // }
    if (selectConference) {
      setOpenCircle(true);

      let seData = {
        url: `/api/ConferenceFeedbackQuestion/ConferenceFeedbackReport?conferenceId=${
          selectConference ? selectConference?.value : null
        }&userId=${logindetail?.userId}`,
        // body: {
        //   userId: logindetail?.userId,
        //   conferenceId: selectConference ? selectConference?.value : null,
        //   // associationMemberId: member?.length > 0 ? member : null,
        //   // LeadStatus: selectLead ? selectLead?.value : null,
        // },
      };
      let res = await GetCallApi(seData);
      setFilterPage(!filterPage);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          let comList = res?.data?.payload?.map((c, i) => {
            return {
              id: i + 1,
              ...c,
              submittedDate: c?.submittedDate
                ? new Date(c?.submittedDate)?.toLocaleDateString("en-us", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "",
              groupUserList:
                c?.questionAnwserDetails?.length > 0
                  ? c?.questionAnwserDetails?.map((x, index) => {
                      return {
                        ...x,
                        id: index + 1,
                      };
                    })
                  : "",
              Action: "",
            };
          });
          setAttendeeList(comList);
        }
      }
    } else {
      setOpenCircle(false);
    }
  };

  const getConferenceList = async () => {
    let seData = {
      url: `/api/ConferenceFeedbackQuestion/GetFeedbackConferenceList?userId=${logindetail?.userId}`,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res.status === 200) {
      if (res.data.success) {
        let data = res.data.payload?.map((x) => {
          return {
            value: x?.conferenceId,
            label: x?.conferenceName,
          };
        });
        setConList(data);
      }
    }
  };

  // const getLEAList = async () => {
  //   let seData = {
  //     url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"LEA"}&userId=${
  //       logindetail?.userId
  //     }`,
  //   };
  //   setOpenCircle(true);
  //   let res = await GetCallApi(seData);
  //   setOpenCircle(false);
  //   if (res.status === 200) {
  //     if (res.data.success) {
  //       let data = res.data.payload?.map((x) => {
  //         return {
  //           ...x,
  //           value: x?.associationMemberId,
  //           label: x?.name,
  //         };
  //       });
  //       setLeaList(data);
  //     }
  //   }
  // };

  // const getAgencyist = async () => {
  //   let seData = {
  //     url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"State Agency"}&userId=${
  //       logindetail?.userId
  //     }`,
  //   };
  //   setOpenCircle(true);
  //   let res = await GetCallApi(seData);
  //   setOpenCircle(false);
  //   if (res.status === 200) {
  //     if (res.data.success) {
  //       let data = res.data.payload?.map((x) => {
  //         return {
  //           ...x,
  //           value: x?.associationMemberId,
  //           label: x?.name,
  //         };
  //       });
  //       setAgencyList(data);
  //     }
  //   }
  // };

  // const getIndustryist = async () => {
  //   let seData = {
  //     url: `/api/AssociationMembers/GetAssociationMembersListbyUserIdAndType?type=${"Industry"}&userId=${
  //       logindetail?.userId
  //     }`,
  //   };
  //   setOpenCircle(true);
  //   let res = await GetCallApi(seData);
  //   setOpenCircle(false);
  //   if (res.status === 200) {
  //     if (res.data.success) {
  //       let data = res.data.payload?.map((x) => {
  //         return {
  //           ...x,
  //           value: x?.associationMemberId,
  //           label: x?.name,
  //         };
  //       });
  //       setIndustryList(data);
  //     }
  //   }
  // };

  const handleChangeCon = (e) => {
    setSelectConference(e);
  };

  // const handleChangeLea = (e) => {
  //   setSelectLea(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  // };

  // const handleChangeAgency = (e) => {
  //   setSelectAgency(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  // };

  // const handleChangeIndustry = (e) => {
  //   setSelectIndustry(Array.isArray(e) ? e?.map((x) => x?.value) : []);
  // };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="conference_feedback_report">
          <div className="main_wrapper">
            <form className="form_box">
              <div className="row">
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography style={{ marginLeft: "20px" }}>
                      <img src={"/assets/icons/filterIcon.svg"} alt="filter" />
                      <span className="filter_by">Filter By</span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row ">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Conference</label>
                          <Select
                            isMulti={false}
                            name="colors"
                            options={conList}
                            isClearable={true}
                            className="dropdown-single-select"
                            classNamePrefix="select"
                            placeholder="Select Conference"
                            filterOption={customFilter}
                            value={conList?.filter(
                              (obj) => obj.value === selectConference?.value
                            )}
                            onChange={handleChangeCon}
                          />
                          {/* {derror?.err && (
                            <span role="alert" className="error_text">
                              {derror?.err}
                            </span>
                          )} */}
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">LEA Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={leaList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LEA Member"
                          filterOption={customFilter}
                            value={leaList?.filter((obj) =>
                              selectLea?.includes(obj.value)
                            )}
                            onChange={handleChangeLea}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">State Agencies</label>
                          <Select
                            isMulti
                            name="colors"
                            options={agencyList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select State Agencies"
                          filterOption={customFilter}
                            value={agencyList?.filter((obj) =>
                              selectAgency?.includes(obj.value)
                            )}
                            onChange={handleChangeAgency}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label className="form-label">Industry Member</label>
                          <Select
                            isMulti
                            name="colors"
                            options={industryList}
                            className="company-multi-select"
                            classNamePrefix="select"
                            placeholder="Select Industry Member"
                          filterOption={customFilter}
                            value={industryList?.filter((obj) =>
                              selectIndustry?.includes(obj.value)
                            )}
                            onChange={handleChangeIndustry}
                          />
                        </div>
                      </div> */}
                    </div>
                  </AccordionDetails>
                </Accordion>
                <div className="accordian_card">
                  <div className="">
                    <NewLIstTable
                      title="Conference Feedback Report"
                      headCells={headCellsUser}
                      ListData={attendeeList}
                      action={true}
                      fileName={"Conference Feedback Report"}
                      exportDataList={true}
                      showUserListInTable={true}
                      innerProduct={true}
                      filterPage={filterPage}
                      subHeadCells={[
                        {
                          id: "id",
                          label: "id",
                        },
                        {
                          id: "questionTitle",
                          label: "Question Title",
                        },
                        {
                          id: "questionType",
                          label: "Question Type",
                        },
                        {
                          id: "answer",
                          label: "answer",
                          widthFix: true,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ConferenceFeedbackReport;
