import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { Backdrop, Button, CircularProgress, IconButton } from "@mui/material";
import Axios from "../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CropModel from "../../Components/CropModel";
import { Box, Modal } from "@mui/material";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function ConferenceSponsor() {
  const [dataOfTable, setDataOfTable] = useState([]);
  const [openDeleteD, setOpenDeleteD] = useState();
  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e, index) => {
    setOpen(true);
    setOpenDeleteD({ ...e, index: index });
  };
  const handleClose = () => setOpen(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sponsor",
  });

  useEffect(() => {
    GetAllEventsList("Conference");
    // eslint-disable-next-line
  }, []);

  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const LogUserde = useSelector((state) => state.login.LoginDetails);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    data.sponsor?.map((sd, i) => {
      let SponsorData = {};
      if (sd?.eventsSponsorshipId) {
        SponsorData = {
          eventId: data?.eventId,
          eventsSponsorshipId: sd?.eventsSponsorshipId,
          EventsSponsorName: sd.name,
          SponsorLogo: sd.logo,
          SponsorhipDescriptions: sd.desc,
          SponsorshipCosts: sd.costs,
          CreatedBy: LogUserde?.userId,
        };
      } else {
        SponsorData = {
          eventId: data?.eventId,
          EventsSponsorName: sd.name,
          SponsorLogo: sd.logo,
          SponsorhipDescriptions: sd.desc,
          SponsorshipCosts: sd.costs,
          CreatedBy: LogUserde?.userId,
        };
      }
      Axios.post("/api/EventSponsorShip/CreateEventSponsorShip", SponsorData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              if (data.sponsor.length - 1 === i) {
                setValue("sponsor", []);
                setValue("eventId", "");

                toast.success(res.data.message, {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            }
          }
        })
        .catch((e) => console.log(e));
      return "";
    });
  };

  const GetAllEventsList = () => {
    Axios.get(
      `/api/Conference/GetApproveConferenceList?userId=${LogUserde?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success) {
            let comList = res?.data?.payload;
            setDataOfTable(comList);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getSponcerData = (e) => {
    setValue("sponsor", []);
    if (e) {
      Axios.get(
        `/api/EventSponsorShip/GetEventsSponsorByEventID?eventId=${e}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              let sd = res.data.payload?.map((sp) => {
                return {
                  eventsSponsorshipId: sp?.eventsSponsorshipId,
                  logo: sp?.sponsorLogo,
                  logo1: sp?.sponsorLogo,
                  name: sp?.eventsSponsorName,
                  desc: sp?.sponsorhipDescriptions,
                  costs: sp?.sponsorshipCosts?.split(" ")[0],
                };
              });

              if (sd?.length > 0) {
                setValue("sponsor", sd);
              } else {
                const data = [
                  {
                    logo: "",
                    logo1: "",
                    name: "",
                    desc: "",
                    costs: "",
                  },
                ];
                setValue("sponsor", data);
              }
            }
          }
        })
        .catch((e) => console.log(e));
    }
  };
  // crop code start
  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [spoInx, setSpoLInx] = useState(null);
  const [croppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url && spoInx !== null) {
        setValue(`sponsor.${spoInx}.logo1`, url?.name);
        setValue(`sponsor.${spoInx}.logo`, url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl, spoInx]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      // e.target.value = null;
    }
  };
  // crop code end
  const handledelete = () => {
    if (openDeleteD?.eventsSponsorshipId) {
      setOpenCircle(true);
      Axios.delete(
        `/api/EventSponsorShip/DeleteEventSponsorShip?eventsSponsorshipId=${openDeleteD?.eventsSponsorshipId}`,
        { headers }
      )
        .then((responce) => {
          if (responce.data.success) {
            setOpenCircle(false);
            remove(openDeleteD?.index);
            handleClose();
            toast.success(responce.data.message, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            getSponcerData(getValues("eventId"));
          }
        })
        .catch((error) => console.log(error));
    } else {
      handleClose();
      remove(openDeleteD?.index);
    }
  };
  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: () => 9999 }} open={openCircle}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="eventSetupProcss_dashboard py-4">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className="form_box">
                  <div className="row mb-3">
                    <div className="col-md-12 mb-4">
                      <div className="title">Conference Sponsorship</div>
                    </div>
                    <div className="col-md-12">
                      <select
                        className="form-select event_select"
                        style={{ maxWidth: "250px" }}
                        {...register(`eventId`, {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          onChange: (e) => getSponcerData(e.target.value),
                        })}
                      >
                        <option value="">Select Conference</option>
                        {dataOfTable?.map((e, i) => (
                          <option value={e.conferenceId} key={i}>
                            {e?.conferenceName}
                          </option>
                        ))}
                      </select>
                      {errors.eventId && (
                        <span role="alert" className="error_text">
                          {errors.eventId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* crop code start */}
                  {src && (
                    <CropModel
                      src={src}
                      filename={filename}
                      setCroppedImageUrl={setCroppedImageUrlCallback}
                      openCropModal={openCropModal}
                      setCropModal={setCropModal}
                    />
                  )}
                  {/* crop code end */}
                  {fields?.map((item, index) => {
                    return (
                      <div className="col-md-12 set-backcolor mb-3" key={index}>
                        <div className="row">
                          {item?.name !== "" && index === 0 ? (
                            <Button
                              className="theme_btn remove_btn"
                              onClick={() => handleOpen(item, index)}
                              // onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </Button>
                          ) : index === 0 ? (
                            ""
                          ) : (
                            <div style={{ cursor: "pointer" }}>
                              <Button
                                className="theme_btn remove_btn"
                                onClick={() => handleOpen(item, index)}
                                // onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </Button>
                            </div>
                          )}

                          <div className="col-xl-3">
                            <div className="form-group">
                              <label className="form-label">
                                Sponsorship Logo
                                <span className="red-color"> *</span>
                              </label>
                              <div className="d-flex upload_btn_sec">
                                <input
                                  type="text"
                                  className="form-control"
                                  readOnly
                                  placeholder="No file choose"
                                  {...register(`sponsor.${index}.logo1`, {
                                    required: {
                                      value: true,
                                      message: "This field is required",
                                    },
                                  })}
                                />
                                <IconButton
                                  component="label"
                                  className="upload_btn m-0"
                                >
                                  Choose File
                                  <input
                                    id="upload"
                                    type="file"
                                    accept="image/*"
                                    className="form-control"
                                    hidden
                                    onChange={(e) => {
                                      handleFile(e, index);
                                      setSpoLInx(index);
                                    }}
                                  />
                                </IconButton>
                              </div>

                              {errors.sponsor?.[index]?.logo1 && (
                                <span role="alert" className="error_text">
                                  {errors.sponsor?.[index]?.logo1.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="form-group">
                              <label className="form-label">
                                Sponsor Name
                                <span className="red-color"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter sponsorship name"
                                {...register(`sponsor.${index}.name`, {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                })}
                              />
                              {errors.sponsor?.[index]?.name && (
                                <span role="alert" className="error_text">
                                  {errors.sponsor?.[index]?.name.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="form-group">
                              <label className="form-label">
                                Sponsorship Descriptions
                                <span className="red-color"> *</span>
                              </label>
                              <textarea
                                rows="5"
                                type="text"
                                className="form-control"
                                placeholder="Enter sponsorship descriptions"
                                {...register(`sponsor.${index}.desc`, {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                })}
                              />
                              {errors.sponsor?.[index]?.desc && (
                                <span role="alert" className="error_text">
                                  {errors.sponsor?.[index]?.desc.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="form-group">
                              <label className="form-label">
                                Sponsorship Costs
                                <span className="red-color"> *</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter sponsorship costs"
                                {...register(`sponsor.${index}.costs`, {
                                  required: {
                                    value: true,
                                    message: "This field is required",
                                  },
                                  pattern: {
                                    value: /^\d+(\.\d{1,2})?$/,
                                    // value: /^\d+\.\d{0,2}$/,
                                    message:
                                      "Enter valid cost the value you have entered is not in correct format. please enter correct format  ex. 0.75",
                                  },
                                })}
                              />
                              {errors.sponsor?.[index]?.costs && (
                                <span role="alert" className="error_text">
                                  {errors.sponsor?.[index]?.costs.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12 my-auto">
                            <div className="text-end form-group m-0">
                              <Button
                                className="ms-3 detail_button"
                                type="button"
                                onClick={() => {
                                  append({
                                    logo: "",
                                    logo1: "",
                                    name: "",
                                    desc: "",
                                    costs: "",
                                  });
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                  <div className="col-md-12">
                    <div className="form-group m-0">
                      <Button type="submit" className="detail_button">
                        Submit
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Delete Sponsor</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to Delete
                    <span> {openDeleteD?.name} ?</span>
                  </p>
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handledelete}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default ConferenceSponsor;
